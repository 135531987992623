import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { State } from '../../../modules/reviewer/profile/general/general.model';
import { ReviewerService } from '../../services/reviewer.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { lastValueFrom } from 'rxjs';
import { Reviewer } from '../../../store/reviewer/reviewer.interface';
import { constants } from '../../constants/constants';
import { Router } from '@angular/router';

export class ProfileInformationClass {
  profileForm!: FormGroup;
  stateDropDown!: State[];
  isPhoneVerified: boolean = false;
  reviewerInfo!: Reviewer;
  userFirmId!: string;
  currentTenant: any = null; 
  citizenshipType = constants.citizenshipType;

  constructor(
    protected fb: FormBuilder,
    protected reviewerService: ReviewerService,
    protected toastService: ToastService,
    protected userService: UserService,
    protected router: Router,
  ) {
    this.stateDropDown = constants.states;
  }

  async init(): Promise<void> {
    this.initializeForm();
    await this.getReviewerInfo();
    this.getTenant();
    this.updateValidators();
    this.isPhoneVerified = this.reviewerInfo?.profile?.contact?.isPhoneVerified;
    this.populateData();
  }

  onSubmit(): void {
    if (this.isPhoneVerified) {
      if (this.profileForm.valid) {
        this.updateReviewer();
      }
    } else {
      this.toastService.showError('Phone number no verified');
    }
  }

  onDiscard(): void {
    this.profileForm.reset();
    this.populateData();
    this.router.navigate(['/reviewer/dashboard'])
  }

  updateReviewer(): void {
    this.reviewerInfo.profile.searchMarkets = this.reviewerInfo.profile.searchMarkets.map((e: any) => e._id.toString());
    const payload: Reviewer = {
      ...this.reviewerInfo,
      profile: {
        ...this.reviewerInfo.profile,
        contact: {
          ...this.profileForm.get('profile.contact')?.value,
          state: this.profileForm.get('profile.contact.state')?.value?.name,
          isPhoneVerified: this.isPhoneVerified,
          home: this.profileForm.get('profile.contact.home')?.value,
        },
        immigrationStatus: this.profileForm.get('profile.immigrationStatus')?.value?.value
      },
      firstName: this.profileForm.get('firstName')?.value,
      lastName: this.profileForm.get('lastName')?.value,
      email: this.profileForm.get('email')?.value,
    };
    this.reviewerService.updateReviewer(payload).subscribe({
      next: (res) => {
        this.toastService.showSuccess('Updated Successfully');
        this.getReviewerInfo();
        this.reviewerService.fetchReviewerAndUpdate();
      },
    });
  }

  initializeForm(): void {
    this.profileForm = this.fb.group({
      profile: this.fb.group({
        contact: this.fb.group({
          street: ['', Validators.required],
          city: ['', Validators.required],
          state: [''],
          zipCode: ['', [Validators.required,Validators.pattern(constants.zipCodeRegex)]],
          mobile: [''],
          home: ['', Validators.required],
          isPhoneVerified: [false],
        }),
        immigrationStatus:['']
      }),
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  async sendVerificationCode(): Promise<void> {
    this.reviewerService
      .sendVerificationPhoneSms(
        this.profileForm.get('profile.contact.home')?.value
      )
      .subscribe({
        next: async (res) => {
          if (res.message === 'Phone number successfully verified') {
            this.isPhoneVerified = true;
            this.disablePhoneField();
          } else {
            this.toastService.showSuccess(res.message);
          }
        },
      });
  }

  verify(code: string): void {
    const body = {
      code: code,
      _id: this.reviewerInfo._id,
      phoneNo: this.profileForm.get('profile.contact.home')?.value.replace(/-/g, ''),
    };
    this.userService.verifyPhone(body).subscribe({
      next: () => {
        this.isPhoneVerified = true;
        this.disablePhoneField();
        this.toastService.showSuccess('Phone no successfully verified.');
      },
      error: () => {
        this.toastService.showError(
          'An error occurred while verifying phone number.'
        );
      },
    });
  }

  disablePhoneField(): void {
    if (this.isPhoneVerified) {
      (this.profileForm.get('profile.contact.home') as FormControl).disable();
    }
  }

  async getReviewerInfo(): Promise<void> {
    this.reviewerInfo = await lastValueFrom(
      this.reviewerService.fetchReviewer()
    );
  }

  getTenant(): void {
    this.currentTenant = this.reviewerService.getSelectedTenant();
    this.userFirmId = this.currentTenant?.firm?._id || '';
  }

  updateValidators(): void{
    const immigrationStatusControl = this.profileForm.get('profile.immigrationStatus');
    if(!this.currentTenant?.firm.isBackgroundCheckRequired && this.currentTenant?.firm.isCitizenshipStatusRequired){
      immigrationStatusControl?.setValidators([Validators.required]);
    } else{
      immigrationStatusControl?.clearValidators();
    }
    this.profileForm.get('immigrationStatus')?.updateValueAndValidity({ emitEvent: false });
  }

  setFormValues(field: string, value: any): void {
    (this.profileForm.get(field) as FormControl).setValue(value);
  }

  populateData(): void {
    this.setFormValues('firstName', this.reviewerInfo?.firstName);
    this.setFormValues('lastName', this.reviewerInfo?.lastName);
    this.setFormValues('email', this.reviewerInfo?.email);
    this.setFormValues(
      'profile.contact.home',
      this.reviewerInfo?.profile?.contact?.home
    );
    this.setFormValues(
      'profile.contact.street',
      this.reviewerInfo?.profile?.contact?.street
    );
    this.setFormValues(
      'profile.contact.city',
      this.reviewerInfo?.profile?.contact?.city
    );
    this.setFormValues(
      'profile.contact.zipCode',
      this.reviewerInfo?.profile?.contact?.zipCode
    );
    this.setFormValues(
      'profile.contact.state',
      this.stateDropDown.find(
        (state) => state.name === this.reviewerInfo?.profile?.contact?.state
      )
    );
    this.setFormValues(
      'profile.immigrationStatus',
      this.citizenshipType.find(
        (status) => status.name === this.reviewerInfo.profile?.immigrationStatus
      )
    );
    (this.profileForm.get('email') as FormControl).disable();
    this.disablePhoneField();
  }
}
