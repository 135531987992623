import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox'
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { BaseReport } from '../base-class/base-report.class';
import moment from 'moment';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { InvoiceReportPayload, InvoiceReportsReponse } from '../interfaces/reports.interface';
import { UserService } from '../../../../../shared/services/user.service';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import { constants } from '../../../../../shared/constants/constants';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [CalendarModule,CheckboxModule,OverlayPanelModule,FloatLabelModule,MultiSelectModule,DropdownModule, ButtonModule, FormsModule, ChipModule, IconFieldModule,InputIconModule,InputTextModule,TableModule, CommonModule ],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceComponent extends BaseReport implements OnInit {
  invoiceReports: Array<InvoiceReportsReponse> = [];
  showProjectSelectionError = false;

  selectedMoreFilters: any[] = [];
  moreFilters = [
    { label: 'Daily hours', value: false },
    { label: 'Hourly rate', value: false },
    { label: 'Show only submitted invoice', value: false }
  ];
  summary = {
    totalProjectHours: 0.00
  };

  constructor(
    override reportService: ReportService,
    override storageService: StorageService,
    override dateTimeService: DateTimeService,
    override userService: UserService
  ){
    super(reportService, storageService, dateTimeService, userService);
  }

  ngOnInit() {
    this.initializeReport();
    this.generateWeekDateAsPerFirm();
  }

  override async onProjectSelectionChange(e: any): Promise<void>{
    await super.onProjectSelectionChange(e);
    this.showProjectSelectionError = false;
  }

  slider(isNext: boolean): void{
    let md;
    if (isNext) {
      md = moment(this.startDate).add(7, 'day');
    } else {
      md = moment(this.startDate).subtract(7, 'day');
    }

    this.startDate = md.toDate();
    this.endDate = md.add(6, 'day').toDate();
  }

  getFormattedDateRange(): string{
    return `${moment(this.startDate).format('MM/DD/YYYY')} - ${moment(this.endDate).format('MM/DD/YYYY')}`
  }

  generateReport(): void{
    if(!this.selectedProjects.length){
      this.showProjectSelectionError = true;
      return;
    }
    const filters: InvoiceReportPayload = {
      emails: "",
      firm: this.sessionUser.firm._id,
      fromDate: moment(this.startDate).format('MM/DD/YYYY'),
      includeDailyTotals: this.getSelectedFilterFlagByFilterName('Daily hours'),
      includeHourlyRate: this.getSelectedFilterFlagByFilterName('Hourly rate'),
      invoiceWeekId: moment(this.startDate).add(2, 'days').isoWeek(),
      isFetchAllRecords: false,
      isSentEmail: false,
      project: this.selectedProjects.map(project => project.id),
      reviewer: this.selectedReviewers.map(reviewer => reviewer._id),
      role: this.selectedRoles.map(role => role.id),
      selectedProj: {...this.selectedProjects},
      showOnlySubmitted: this.getSelectedFilterFlagByFilterName('Show only submitted invoice'),
      timezone: this.isDefaultTimeZone ? constants.timezones[0] : this.selectedTimezone?.val || '',
      toDate: moment(this.endDate).format('MM/DD/YYYY'),
      user: this.sessionUser
    };

    this.reportService.generatePayrollReport(filters).subscribe({
      next: (invoiceReports: Array<InvoiceReportsReponse>) => {
        this.invoiceReports = invoiceReports;
        let totalProjectHours = 0;
        this.invoiceReports.forEach(invoice => {
          totalProjectHours += Number(invoice.totalHours);
        });
        this.summary.totalProjectHours = parseFloat(totalProjectHours.toFixed(2));
      }
    });

  }

  onMoreFilterSelectionChange(selectedOptions: any[]): void{
    this.selectedMoreFilters = this.moreFilters.map(item => selectedOptions.includes(item));
  }

  getSelectedFilterFlagByFilterName(filterName: string): boolean {
    const selectedFilter = this.selectedMoreFilters.find(filter => filter.label === filterName);
    return !!selectedFilter;
  }

  loopOverToMake(groupedList: any, rows: any, roundParamTo: string) {
    groupedList.forEach((reviewer: any) => {
        let cols = [
            this.generateColumn(reviewer.firstName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.lastName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.roleName, { fillColor: '#bdbdbd' }),
            this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalHours), { fillColor: '#bdbdbd' }),
            this.generateColumn(this.getSelectedFilterFlagByFilterName('Hourly rate') ? reviewer.hourlyRate : "", { fillColor: '#bdbdbd' }),
        ];

        rows.push(cols);
        const keys = Object.keys(reviewer.details);
        if (this.getSelectedFilterFlagByFilterName('Daily hours') && keys.length) {
            cols = [
                this.generateColumn('Date', { fillColor: '#dedede', bold: true }),
                this.generateColumn('', { fillColor: '#dedede', bold: true }),
                this.generateColumn('', { fillColor: '#dedede', bold: true }),
                this.generateColumn('Daily Hours', { fillColor: '#dedede', bold: true }),
                this.generateColumn('', { fillColor: '#dedede', bold: true }),
            ];
            rows.push(cols);

            keys.forEach((x) => {
                let dailyTotal = 0;
                reviewer.details[x].forEach((r: any) => {
                    dailyTotal += Number(r.totalDurationInHours);
                });
                const md = moment(x);
                x = md.format("MM/DD/YYYY");
                cols = [
                    this.generateColumn(x, { fillColor: '#dedede', bold: true }),
                    this.generateColumn('', { fillColor: '#dedede' }),
                    this.generateColumn('', { fillColor: '#dedede' }),
                    this.generateColumn(this.convertDecimalTo(roundParamTo, dailyTotal), { fillColor: '#dedede' }),
                    this.generateColumn('', { fillColor: '#dedede' }),
                ];
                rows.push(cols);
            });
        }
    });
    return rows;

  }

  exportPDF(roundParamTo: string) {
    let rows = [];
    const headerOptions = {
        bold: true,
        alignment: 'justify',
        width: '25%'
    };
    const headers = [
        this.generateColumn('First Name', headerOptions),
        this.generateColumn('Last Name', headerOptions),
        this.generateColumn('Role', headerOptions),
        this.generateColumn('Total Hours', headerOptions),
        this.generateColumn('Hourly Rate', headerOptions),
    ];
    rows.push(headers);
    const projectGroups = this.groupByProject(this.invoiceReports,'projectName');
    
    for (let projectGroupKey in projectGroups) {
        let projectHeading = [
            this.generateProjectHeader(projectGroupKey, true),
            this.generateColumn(" "),
            this.generateColumn(" "),
            this.generateColumn(" "),
            this.generateColumn(" "),
        ];
        rows.push(projectHeading);
        const groupedList = projectGroups[projectGroupKey];
        rows = this.loopOverToMake(groupedList, rows, roundParamTo);

    }
    
    if (this.invoiceReports && this.invoiceReports.length) {
        const docDefinition = {
            pageOrientation: 'portrait',
            content: [
                {
                    text: this.sessionUser.firm.name,
                    style: 'header',
                    alignment: 'left',
                    fontSize: 22,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Invoice Report From: ' + moment(this.startDate).format('MM/DD/YYYY')  + ' To: ' + moment(this.endDate).format('MM/DD/YYYY') ,
                    style: 'header',
                    alignment: 'left',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Total Week Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectHours),
                    style: 'header',
                    alignment: 'right',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        body: rows,
                        widths: [90, 90, 90, 90, 90]
                    }
                }
            ]
        } as TDocumentDefinitions;
        pdfMake.createPdf(docDefinition).download(new Date().getTime() + '-invoice-report-from-' +
        moment(this.startDate).format('MM/DD/YYYY')   + '-to-' +  moment(this.endDate).format('MM/DD/YYYY')  );
    }
  }

  exportCSV(roundParamTo: string) {
    const fileData = [
        this.sessionUser.firm.name + '\r\n\r\n',
        'Invoice Report From: ' + moment(this.startDate).format('MM/DD/YYYY') + ' To: ' + moment(this.endDate).format('MM/DD/YYYY') + '\r\n\r\n',
        'Total Week Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectHours) + '\r\n\r\n',
        'First Name,Last Name,Reviewer Status,Role,Total Hours,Hourly Rate \r\n'
    ];
    if (this.invoiceReports && this.invoiceReports.length) {
        const projectGroups = this.groupByProject(this.invoiceReports, 'projectName');
        for (let projectGroupKey in projectGroups) {
            fileData.push('\r\n');
            fileData.push([projectGroupKey].join(',') + '\r\n');
            const groupedList = projectGroups[projectGroupKey];
            for (let i in groupedList) {
                const reviewer = groupedList[i];
                const d = [
                    reviewer.firstName,
                    reviewer.lastName,
                    reviewer.inoviceSubmissionStatus,
                    reviewer.roleName,
                    this.convertDecimalTo(roundParamTo, reviewer.totalHours),
                    this.getSelectedFilterFlagByFilterName('Hourly rate') ? reviewer.hourlyRate : ""
                ].join(',') + '\r\n';
                fileData.push(d);

                const keys = Object.keys(reviewer.details);
                if (this.getSelectedFilterFlagByFilterName('Daily hours') && keys.length) {
                    const chHeaders = ['Date', 'Daily Hours'].join(',') + '\r\n';
                    fileData.push(chHeaders);
                    for (let chInd in keys) {
                        let x = keys[chInd];
                        let dailyTotal = 0;
                        for (let chhInd in reviewer.details[x]) {
                            const r = reviewer.details[x][chhInd];
                            dailyTotal += Number(r.totalDurationInHours);
                        }
                        const md = moment(x);
                        x = md.format("MM/DD/YYYY");
                        const chDet = [
                            x,
                            this.convertDecimalTo(roundParamTo, dailyTotal)
                        ].join(',') + '\r\n';
                        fileData.push(chDet);
                    }
                }
            }
        }

        const blob = new Blob(fileData, { type: "text/plain;charset=utf-8" });
        const fileName = new Date().getTime() + '-invoice-report-from-' + moment(this.startDate).format('MM/DD/YYYY') + '-to-' + moment(this.endDate).format('MM/DD/YYYY') + '.csv';
        window.saveAs(blob, fileName);
    
      }
  }
}
