import { Component, OnInit, NO_ERRORS_SCHEMA, EventEmitter, Output, ViewEncapsulation, Input } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { HelperService } from '../../../shared/services/helper.service';
import { StorageService } from '../../../shared/services/storage.service';
import { SearchMarketService } from '../../../shared/services/search-market.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../shared/services/auth.service';
import { Store } from '@ngrx/store';
import { Location , CommonModule } from '@angular/common';
import { getSessionUser, getSessionFirmAddress, getSessionFirmName } from '../../../store/user/user.selector';
import { SessionUser, UserClientVm } from '../../../store/user/user.interface';
import { Observable } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BadgeModule } from 'primeng/badge';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { Tenant } from '../../../store/reviewer/reviewer.interface';
import { EmittersService } from '../../../shared/services/emitters.service';
import { constants, UserRoles } from '../../../shared/constants/constants';
import { DocumentService } from '../../../shared/services/document-service';
import { Router, RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectComponent } from './select-search-markets/multi-select.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [DividerModule, ImageModule, MultiSelectModule, ButtonModule, DropdownModule, AvatarModule, AvatarGroupModule, CommonModule, MenuModule, ScrollPanelModule, BadgeModule, RouterModule,TooltipModule, MultiSelectComponent],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  schemas: [NO_ERRORS_SCHEMA]
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter<void>();
  @Input() from: string = 'dashboard';
  items: MenuItem[] | undefined;

  searchFirm: any[] | undefined;
  searchMarkets: any;
  form1: any = {selectedSearchMarkets:[]};
  selectedSearchMarkets:any=[];
  selectedCity: any;
  userName: string = '';
  firmName$: Observable<string>;
  firmAddress$: Observable<string>;
  userRole: string = '';
  reviewerTenants: any[] = [];
  notifications: any = [];
  selectedTenant:any = null;
  firmLogo!: string;
  constructor(private helperService: HelperService, private userService: UserService,
    private searchMarketService: SearchMarketService, private storageService: StorageService,
    private authService: AuthService, private store: Store<SessionUser>,
    private cookieService: CookieService, private location: Location,
    private documentService: DocumentService,
    private emitterService: EmittersService, private reviewerService: ReviewerService,
    private router: Router) {
    
    this.firmName$ = this.store.select(getSessionFirmName);
    this.firmAddress$ = this.store.select(getSessionFirmAddress);
    this.userRole = this.userService.getUserRole();
    if (this.userRole === UserRoles.reviewer) {
      this.tenantSelected(this.storageService.getTenant())
      this.getReviewerTenants();
    }
  }
  
  isMobile = this.cookieService.get('isMobile')? true : false;
  isSideNavOpen: boolean = !this.isMobile;

  ngOnInit() {
    const user = this.userService.getSessionUser();
    this.firmLogo =  this.documentService.getDocumentPathById(user.firm.logo || '') || '';
    if (this.userRole === constants.userRoles.reviewer) {
      this.getNotifications();
    }
    else {
      this.searchMarketService.getFirmSearchMarkets(user.firm._id).subscribe({
        next: (searchMarkets) => {
          this.searchMarkets = searchMarkets.filter((e: any) => e.isActive);
          this.storageService.setSelectedSearchMarkets(this.searchMarkets.map((s:any)=> {return s._id;}));
          this.selectedSearchMarkets =  this.searchMarkets.map((s:any)=> {return s._id;});
          
        }
      })
    }

  }

  getNotifications(){
    this.reviewerService.getNotificationHistory().subscribe((resp) => {
      this.notifications = resp;
      this.notifications.forEach((element: any) => {
        element.readMore = true;
      });
    })
  }

  toggleNotification(menu: any) {
    console.log(menu);
  }

  sideNavToggle(): void {
    this.toggleSideNav.emit();
  }

  getReviewerTenants() {
    const tenants = this.reviewerService.getReviewerTenants();
    const selectedTenant = this.storageService.getTenant();
    tenants.map((tenant: any) => {
      let t = {
        ...tenant,
        logo: this.documentService.getDocumentPathById(tenant.firm.logo)
      };

      if(t._id === selectedTenant._id){
        this.selectedTenant = t;
      } else {
        this.reviewerTenants.push(t);
      }
    })
    this.reviewerTenants.unshift(this.selectedTenant);
  }

  onChangeTenant(event: any){
    this.tenantSelected(event);
    window.location.reload();
  }

  tenantSelected(event: any) {
    this.selectedTenant = event;
    this.storageService.setTenant(event);
    this.getNotifications();
    this.emitterService.tenantEmitter.emit(event); 
  }

  searchMarketsSelected(event: any) {
    this.storageService.setSelectedSearchMarkets(event);
    this.emitterService.searchMarketSelectorEmitter.emit(event);
  }

  getUnreadNotificationCount(){
    if(this.notifications.length){
      return this.notifications.filter((n: any) => !n.isRead).length;
    }
    return 0;
  }

  markRead($event: Event, item: any){
    this.reviewerService.updateNotificationHistory({id: item._id}, 'read').subscribe(() => {
      item.isRead = true;
    });
  }

  readMore($event: Event, item: any){
    $event.stopPropagation();
    item.readMore = !item.readMore;
    if(!item.isRead){
      this.reviewerService.updateNotificationHistory({id: item._id}, 'read').subscribe(() => {
        item.isRead = true;
      });
    }
  }

  mapLogoUrl(id: string) {
    return this.reviewerService.mapFirmLogoUrl(id);
  }

  logout(): void {
    this.authService.logout();
  }

  goToDashboard() {
    this.router.navigate(['/reviewer/dashboard']);
    if(this.location.path() === '/reviewer/dashboard'){
      this.emitterService.todoListEmitter.emit();
    }
  }

  tenantNameForDropdown(tenantName: any): string {
    return (tenantName.slice(0, 27) + (tenantName.length > 27 ? '...' : ''));
  }
 
  breakAddress(address: string, chunkSize: number = 50): string[] {
    if (!address) return [];
    // If the address is longer than 47 characters, truncate and add ellipsis
    const truncatedAddress = address.length > 47 ? address.slice(0, 47) + '...' : address;
    return Array.from({ length: Math.ceil(truncatedAddress.length / chunkSize) }, (_, i) => 
      truncatedAddress.slice(i * chunkSize, (i + 1) * chunkSize)
    );
  }
}

