import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, RendererFactory2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableModule, TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { EditTemplateService } from './edit-template.service';
import * as pdfjsLib from 'pdfjs-dist';
import interact from 'interactjs';
import { AccordionModule } from 'primeng/accordion';
import { DocumentService } from '../../../../../../../shared/services/document-service';
import { ToastService } from '../../../../../../../shared/services/toast.service';

@Component({
  selector: 'edit-template',
  standalone: true,
  imports: [
    DragDropModule,
    ChipModule,
    DialogModule,
    TableModule,
    TabViewModule,
    InputIconModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    IconFieldModule,
    InputTextareaModule,
    FormsModule,
    RadioButtonModule,
    FloatLabelModule,
    InputTextModule,
    InputSwitchModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CommonModule,
    AccordionModule
  ],
  templateUrl: './edit-template.component.html',
  styleUrl: './edit-template.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class EditTemplateComponent implements OnInit {


  @ViewChild('theCanvas', { static: true }) theCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('pageNum') pageNumElement!: ElementRef;
  @ViewChild('pageCount') pageCountElement!: ElementRef;
  @ViewChild('templateParent') templateParent!: ElementRef;
  @Output()
  openDocuSignEditor: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  updateProject: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  updateTemplateDocument: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  type: string = 'edit';
  @Input()
  isEditMode = false;
  @Input()
  isViewOnly = false;
  @Input()
  projectId: string | null = '';
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;

  private renderer: Renderer2;
  pageNum: number = 1;
  scale: number = 1.3;
  templateFields: any = {};
  defaultPositions: any = {
    signHere: {
      xPosition: 210,
      yPosition: 300,
      editable: true,
      required: true,
    },
    dateSigned: {
      xPosition: 310,
      yPosition: 300,
      editable: false,
      required: false,
    },
    textTab_fullName: {
      xPosition: 220,
      yPosition: 300,
      editable: false,
      required: false,
    },
    textTab_email: {
      xPosition: 230,
      yPosition: 300,
      editable: false,
      required: false,
    },
    textTab_custom: {
      xPosition: 250,
      yPosition: 300,
      editable: true,
      required: true,
    },
    checkBoxTab: {
      xPosition: 290,
      yPosition: 300,
      editable: true,
      required: true,
    },
    initialHere: {
      xPosition: 180,
      yPosition: 300,
      editable: true,
      required: true,
    },
    radioTab: {
      xPosition: 150,
      yPosition: 250,
      editable: true,
      required: true,
    },
    signerAttachment: {
      xPosition: 150,
      yPosition: 350,
      editable: true,
      required: true,
    },
  };

  activeOptions: any = {
    signHere: {},
    dateSigned: {},
    textTab_fullName: {},
    textTab_email: {},
    textTab_custom: {},
    checkBoxTab: {},
    initialHere: {},
    radioTab: {},
    signerAttachment: {},
  };
  pdfDoc: any = null;
  totalPages = 0;
  pageNumPending: any = null;
  pageRendering = false;
  pagenumber = 0;
  selectedObject: any = {};
  selectedObjectId: any = {};
  validationOptions = [
    { label: 'SelectAtLeast', },
    { label: 'SelectAtMost' },
    { label: 'SelectExactly' },
    { label: 'SelectARange' }
  ];
  saveConfirmationModalVisible = false;
  saveConfirmationModalContent = '';
  updateConfirmationModalVisible = false;
  updateConfirmationModalContent = '';

  constructor(
    private editTemplateService: EditTemplateService,
    private elRef: ElementRef,
    rendererFactory: RendererFactory2,
    private documentService: DocumentService,
    private toastService: ToastService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';
    this.initialization();
  }

  initialization(): void {
    if (this.theCanvas && this.theCanvas.nativeElement) {
      this.initializeCanvas(this.theCanvas.nativeElement);

      document.getElementById('prev')?.addEventListener('click', () => this.onPrevPage());
      document.getElementById('next')?.addEventListener('click', () => this.onNextPage());

      if (this.editTemplateService.getDocumentInfoByKey('editTemplate')) {
        const template = this.editTemplateService.getDocumentInfoByKey('editTemplate');
        this.editTemplateFields(template.documentUri, template.docuSignTemplate);
      } else {
        this.createTemplateFields();
      }
    }
  }

  getJsonStringify(object: any): string {
    return JSON.stringify(object);
  }

  initializeCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
  }

  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;

    this.queueRenderPage(this.pageNum);
  }

  onNextPage() {
    if (this.pageNum >= this.pdfDoc.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }

  queueRenderPage(num: any) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  async editTemplateFields(url: string, templateObject: any) {
    const pdfDoc = await pdfjsLib.getDocument({ data: url }).promise;
    this.pdfDoc = pdfDoc;
    const pageCountElement = document.getElementById("page_count");
    if (pageCountElement && pageCountElement.textContent) {
      pageCountElement.textContent = `${pdfDoc.numPages}`;
    }
    this.totalPages = pdfDoc.numPages;

    this.renderPage(this.pageNum).then(() => {
      this.addFieldsByTemplateConfig(templateObject.tabFieldsConfig);
    });

  }

  addFieldsByTemplateConfig(templateConfig: any) {
    let template = this.editTemplateService.getDocumentInfoByKey("editTemplate");
    for (let fieldType in templateConfig) {
      for (let x = 0; x < templateConfig[fieldType].length; x++) {
        if (templateConfig[fieldType][x].documentId === Number(template.documentId)) {
          try {
            this.insertElement(fieldType, templateConfig[fieldType][x]);
          } catch (err) {
          }
        }
      }
    }
    this.hidePagesExceptCurrent(this.pageNum);
  }

  renderPage(num: number) {
    this.pageRendering = true;
    this.templateFields[num] = this.templateFields[num] ? this.templateFields[num] : JSON.parse(JSON.stringify(this.activeOptions));
    this.pagenumber = num;
    this.hidePagesExceptCurrent(num);
    this.selectedObject = null;
    this.selectedObjectId = null;
    this.deselectAnyElement();
    this.renderer.setProperty(this.pageNumElement.nativeElement, 'textContent', num);
    return this.pdfDoc.getPage(num).then((page: any) => {
      const viewport = page.getViewport({ scale: this.scale });
      if (this.canvas) {
        this.canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.renderer.setStyle(this.templateParent.nativeElement, 'width', `${viewport.width}px`);
        this.renderer.setStyle(this.templateParent.nativeElement, 'height', `${viewport.height}px`);
      }
      const renderContext = {
        canvasContext: this.ctx,
        viewport: viewport,
      };
      const renderTask = page.render(renderContext);

      return renderTask.promise.then(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
        return Promise.resolve();
      });
    });
  }

  hidePagesExceptCurrent(pageNumber: number) {
    for (let p = 1; p <= this.totalPages; p++) {
      const pageElement = this.elRef.nativeElement.querySelector(`div[page="${p}"]`);
      if (pageElement) {
        this.renderer.setStyle(pageElement, 'display', 'none');
      }
    }

    const currentPageElement = this.elRef.nativeElement.querySelector(`div[page="${pageNumber}"]`);
    if (currentPageElement) {
      this.renderer.setStyle(currentPageElement, 'display', 'block');
    }
  }

  deselectAnyElement() {
    const selectedElements = this.elRef.nativeElement.querySelectorAll('.selected-field');
    selectedElements.forEach((element: HTMLElement) => {
      this.renderer.removeClass(element, 'selected-field');
    });
  }

  async createTemplateFields(): Promise<void> {
    const fileReader = new FileReader();
    fileReader.onload = async (ev) => {
        const typedarray = new Uint8Array(fileReader.result as ArrayBuffer);

        const pdfDoc = await pdfjsLib.getDocument(typedarray).promise;
        this.pdfDoc = pdfDoc;
        
        this.renderer.setProperty(this.pageCountElement.nativeElement, 'textContent', pdfDoc.numPages);
        
        
        this.renderPage(this.pageNum);
    };
    fileReader.readAsArrayBuffer(new Blob([this.editTemplateService.getDocumentInfoByKey('file')]));
  }

  addElement(elementName: string) {
    switch (elementName) {
      case 'signHere': {
        this.insertElement(elementName);
        break;
      }
      case 'textTab_fullName': {
        this.insertElement(elementName);
        break;
      }
      case 'textTab_email': {
        this.insertElement(elementName);
        break;
      }
      case 'textTab_custom': {
        this.insertElement(elementName);
        break;
      }
      case 'checkBoxTab': {
        this.insertElement(elementName);
        break;
      }
      case 'dateSigned': {
        this.insertElement(elementName);
        break;
      }
      case 'initialHere': {
        this.insertElement(elementName);
        break;
      }
      case 'radioTab': {
        this.insertElement(elementName);
        break;
      }
      case "signerAttachment": {
        this.insertElement(elementName);
        break;
      }
    }
  }

  insertElement(elementName: string, fieldConfig: any = null, groupConfig: any = null) {
    const domid = fieldConfig ? fieldConfig.tabLabel.split('-')[2] : this.randomId();
    const groupId = this.randomId();

    const props: any = {
      pageNum: fieldConfig ? Number(fieldConfig.pageNumber) : this.pageNum,
      elementName,
      domid,
      tabLabel: fieldConfig ? fieldConfig.tabLabel : `${elementName}${this.getElementIndex(elementName, this.pageNum)}-${this.randomId()}-${domid}`,
      groupId: fieldConfig && elementName === 'checkBoxTab' ? fieldConfig.groupId : groupId,
      tabName: fieldConfig ? fieldConfig.tabName : '',
      required: fieldConfig ? fieldConfig.required : this.defaultPositions[elementName].required,
      editable: fieldConfig ? fieldConfig.editable : this.defaultPositions[elementName].editable,
      left: fieldConfig ? fieldConfig.xPosition * this.scale : this.defaultPositions[elementName].xPosition,
      top: fieldConfig ? fieldConfig.yPosition * this.scale : this.defaultPositions[elementName].yPosition,
      conditionalFields: fieldConfig ? fieldConfig.conditionalFields : []
    };

    if (elementName.includes('text') && fieldConfig) {
      props.height = fieldConfig.height;
      props.width = fieldConfig.width;
    }

    if (elementName === 'radioTab') {
      this.handleRadioTab(fieldConfig, props);
    }

    if (elementName === 'checkBoxTab' && groupConfig) {
      props.groupId = groupConfig.groupId;
      props.left = groupConfig.left + 15;
      props.top = groupConfig.top + 0;
    }

    if (elementName === 'tabGroup' && fieldConfig) {
      Object.assign(props, {
        groupTooltip: fieldConfig.groupTooltip,
        validationType: fieldConfig.validationType,
        validationCount: fieldConfig.validationCount,
        validationCountMin: fieldConfig.validationCountMin,
        validationCountMax: fieldConfig.validationCountMax,
      });
    }

    this.initializeTemplateFields(this.pageNum, domid, elementName, groupId, props);

    const templateParent = this.elRef.nativeElement.querySelector('#template-parent');
    templateParent.appendChild(this.getFieldDomObject(props));

    const element = this.elRef.nativeElement.querySelector(`#${props.domid}`);
    this.initializeInteractions(element, elementName);
  }

  handleRadioTab(fieldConfig: any, props: any) {
    let radioGroup = this.createDefaultRadioGroup(props.pageNum);

    if (fieldConfig && fieldConfig.radioGroupTabs.length) {
      radioGroup = this.createRadioGroupFromConfig(fieldConfig, props.pageNum);
    }

    props.radioGroup = radioGroup;
    props.left = fieldConfig ? fieldConfig.left * this.scale : this.defaultPositions['radioTab'].xPosition;
    props.top = fieldConfig ? fieldConfig.top * this.scale : this.defaultPositions['radioTab'].yPosition;
    props.alignHorizontal = fieldConfig ? fieldConfig.alignHorizontal : false;
  }

  createDefaultRadioGroup(pageNum: number) {
    const elementId1 = this.randomId();
    const elementId2 = this.randomId();
    return {
      [elementId1]: { id: elementId1, pageNumber: pageNum, selected: false },
      [elementId2]: { id: elementId2, pageNumber: pageNum, selected: false },
    };
  }

  createRadioGroupFromConfig(fieldConfig: any, pageNum: number) {
    const radioGroup: any = {};
    fieldConfig.radioGroupTabs.forEach(() => {
      const id = this.randomId();
      radioGroup[id] = { id, pageNumber: pageNum, selected: false };
    });
    return radioGroup;
  }

  getFieldDomObject(props: any): any {
    let div = null;
    switch (props.elementName) {
      case "signHere":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute;`;
        this.renderer.addClass(div, 'sign-here');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `Sign <i id=${props.domid} page=${props.pageNum} type=${props.elementName} class='pi pi-arrow-circle-down'></i> <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;

      case "textTab_fullName":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'text-tab');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `full name <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;

      case "textTab_email":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'text-tab');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `email <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;

      case "textTab_custom":
        this.templateFields[props.pageNum][props.elementName][
          props.domid
        ].height = props.height ? props.height : 25;
        this.templateFields[props.pageNum][props.elementName][
          props.domid
        ].width = props.width ? props.width : 150;

        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'text-tab');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `text <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;

      case "checkBoxTab":
        div = this.renderer.createElement('div');
        div.id = `${props.domid}`;
        div['data-group'] = props.groupId;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        div.class = 'checkBoxTab template-field-object';
        this.renderer.addClass(div, 'checkBoxTab');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `<input class='checkBoxInput' type='checkbox'></input>\
          <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum}\
          data-remove=${props.domid} style='position: absolute;top: -15px; right:-15px;'></i>\
          <i class='pi pi-plus-circle add-check-box' type=${props.elementName} page=${props.pageNum}\
          data-element-add-checkbox=${props.domid} data-element-groupId=${props.groupId} style='position: absolute;bottom: -26px;right: 2px;color: #2e80f1;font-size: 22px;'></i>`;
        return div;

      case "dateSigned":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'text-tab');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `signed date <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum}\
          data-remove=${props.domid} style='position: absolute;top: -15px; right:-15px;'></i>`;
        return div;

      case "initialHere":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        div.class = 'sign-here template-field-object';
        this.renderer.addClass(div, 'sign-here');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `Initial <i id=${props.domid} page=${props.pageNum} type=${props.elementName} class='pi pi-arrow-circle-down'></i> <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;

      case "radioTab":
        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'radio-here')
        this.renderer.addClass(div, `${props.alignHorizontal ? "horizontally-align" : "vertically-align"}`);
        this.renderer.addClass(div, 'template-field-object');

        let innerHTML = '';
        for (let radioKey in props.radioGroup) {
          innerHTML += "<input id='" +
            props.radioGroup[radioKey].id +
            "' type='radio' data-group='" +
            props.groupId +
            "' name='" +
            props.groupId +
            "' />";
        }

        innerHTML += "<i id=" +
          props.domid +
          "  page='" +
          props.pageNum +
          "' data-element-groupId='" +
          props.groupId +
          "'  data-element-remove='" +
          props.domid +
          "'   type='" +
          props.elementName +
          "' class='pi pi-plus-circle'></i>" +
          "<i class='pi pi-times-circle' type='" +
          props.elementName +
          "' page='" +
          props.pageNum +
          "' data-remove='" +
          props.domid +
          "' style='position: absolute;top: -15px;right:-15px;'></i>" +
          "</div>";
        div.innerHTML = innerHTML;
        return div;

      case "signerAttachment":

        div = this.renderer.createElement('div');
        div.id = props.domid;
        div.page = props.pageNum;
        div.type = props.elementName
        div.style = `left: ${props.left}px; top: ${props.top}px; position: absolute`;
        this.renderer.addClass(div, 'attachment-here');
        this.renderer.addClass(div, 'template-field-object');
        div.innerHTML = `Attachment <i id=${props.domid} page=${props.pageNum} type=${props.elementName} class='pi pi-paperclip'></i> <i class='pi pi-times-circle' type=${props.elementName} page=${props.pageNum} data-remove=${props.domid} style='position: absolute;top: -15px;right:-15px;'></i>`;
        return div;
    }
    return div;
  }

  initializeInteractions(element: HTMLElement, elementName: string) {
    interact(element)
      .draggable({
        modifiers: [
          interact.modifiers.snap({
            targets: [interact.snappers.grid({ x: 1, y: 1 })],
            range: Infinity,
            relativePoints: [{ x: 0, y: 0 }],
          }),
          interact.modifiers.restrict({
            restriction: element.parentNode as HTMLElement,
            elementRect: {
              top: 0,
              left: 0,
              bottom: 1,
              right: 1,
            },
            endOnly: true,
          }),
        ],
        inertia: true,
      })
      .on('dragmove', (event: any) => {
        this.onDragField(event);
      })
      .on('dragend', (event: any) => {
        this.onDragField(event);
      })
      .on("mousedown", (event) => {
        let _id: string, _type: string, _page: number, _groupId: string;

        if (event.target.attributes['data-remove']) {
          _id = event.target.attributes['data-remove'].value;
          _type = event.target.attributes['type'].value;
          _page = this.pageNum;

          const element = this.elRef.nativeElement.querySelector(`#${_id}`);
          if (element) {
            this.renderer.removeChild(element.parentNode, element);
          }

          if (_type === 'radioTab') {
            const groupElementIds = Object.keys(
              this.templateFields[_page][_type][_id]['radioGroup']
            );
            this.deleteRadioGroupElements(groupElementIds, _page, _type);
          }

          delete this.templateFields[_page][_type][_id];
          this.selectedObject = this.selectedObject === _type ? null : this.selectedObject;
          this.selectedObjectId = this.selectedObjectId === _id ? null : this.selectedObjectId;

        } else if (event.target.attributes['data-element-remove']) {
          _groupId = event.target.attributes['data-element-groupId'].value;
          _id = event.target.attributes['data-element-remove'].value;
          _type = event.target.attributes['type'].value;
          _page = this.pageNum;

          this.addRadioButtonInput(_id, _type, _page, _groupId);

        } else if (event.target.attributes['data-element-add-checkbox']) {
          _groupId = event.target.attributes['data-element-groupId'].value;
          _id = event.target.attributes['data-element-add-checkbox'].value;
          _type = event.target.attributes['type'].value;
          _page = this.pageNum;

          this.addCheckboxButtonInput(_id, _type, _page, _groupId);

        } else {
          this.addSelection(elementName, event);
        }
      });
  }

  deleteRadioGroupElements(groupElementIds: any, _page: any, _type: any) {
    groupElementIds.forEach((grElementId: string) => {
      delete this.templateFields[_page][_type][grElementId];
    });
  }

  removeElement(event: any) {
    const elementId = event.target.attributes['data-remove'].value;
    const element = this.elRef.nativeElement.querySelector(`#${elementId}`);

    if (element) {
      this.renderer.removeChild(element.parentNode, element);
    }
  }

  randomId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  getDisabledStatus(): boolean {
    return !!!(this.selectedObject && this.selectedObjectId);
  }

  callMouseOver(selectedItem: any) {
    if (selectedItem) {
      const hoveredElement = document.getElementById(selectedItem.split('-')[2]);
      if (hoveredElement) {
        hoveredElement.className = hoveredElement.className + " elementPointer";
      }
    }
  }

  callMouseOut() {
    const element = this.elRef.nativeElement.querySelector('.elementPointer');
    if (element) {
      this.renderer.removeClass(element, 'elementPointer');
    }
  }

  removeConditionalField(conditionFields: any, index: number) {
    conditionFields.splice(index, 1);
  }

  addConditinalField(list: Array<any>) {
    list.push({});
  }

  checkRadioAlignement() {
    const value = this.templateFields[this.pagenumber][this.selectedObject][this.selectedObjectId].alignHorizontal;

    const selectedElement = this.elRef.nativeElement.querySelector(`#${this.selectedObjectId}`);

    if (value) {
      this.renderer.removeClass(selectedElement, 'vertically-align');
      this.renderer.addClass(selectedElement, 'horizontally-align');
    } else {
      this.renderer.removeClass(selectedElement, 'horizontally-align');
      this.renderer.addClass(selectedElement, 'vertically-align');
    }
  }

  onBlurTextBoxHeight() {
    const height = this.templateFields[this.pagenumber][this.selectedObject][this.selectedObjectId].height;
    const element = this.elRef.nativeElement.querySelector(`#${this.selectedObjectId}`);
    this.renderer.setStyle(element, 'height', height);
  }

  onBlurTextBoxWidth() {
    const width = this.templateFields[this.pagenumber][this.selectedObject][this.selectedObjectId].width;
    const element = this.elRef.nativeElement.querySelector(`#${this.selectedObjectId}`);
    this.renderer.setStyle(element, 'width', width);
  }

  getCheckBoxesByGroupId(element: any): any[] {
    if (!element) {
      return [];
    }

    const pageFields = this.templateFields[element.pageNum];
    if (!pageFields || !pageFields[element.elementName]) {
      return [];
    }

    return Object.values(pageFields[element.elementName]).filter((checkbox: any) => {
      return checkbox.groupId === element.groupId;
    });
  }

  getSelectableElements(elements: any): any[] {
    let selectableElementsList: any[] = [];

    for (const eletype in elements) {
      if (elements.hasOwnProperty(eletype) && eletype !== 'tabGroup') {
        selectableElementsList = selectableElementsList.concat(Object.values(elements[eletype]));
      }
    }

    return selectableElementsList;
  }

  getElementIndex(elementName: string, pageNum: number): any {
    return Object.keys(this.templateFields[pageNum][elementName]).length;
  }

  onDragField(event: any) {
    let x = 0;
    let y = 0;
    x = parseInt(event.target.style.left);
    y = parseInt(event.target.style.top);
    x += event.dx;
    y += event.dy;

    event.target.style.top = y + "px";
    event.target.style.left = x + "px";

    this.templateFields[this.pageNum][this.selectedObject][
      event.target.attributes.id.value
    ].top = y;

    this.templateFields[this.pageNum][this.selectedObject][
      event.target.attributes.id.value
    ].left = x;
  }

  addSelection(elementName: string, event: any) {
    this.selectedObject = elementName;
    this.selectedObjectId = event.target.attributes?.id?.value;

    this.deselectAnyElement();
    if (!this.selectedObjectId) {
      return;
    }
    const element = this.elRef.nativeElement.querySelector(`#${this.selectedObjectId}`);
    if (element) {
      this.renderer.addClass(element, 'selected-field');
    }
  }

  addRadioButtonInput(id: string, type: string, page: number, groupId: string) {
    const elementId = this.randomId();
    let radioGroup = this.templateFields[page][type][id]['radioGroup'];
    let lastElementId = "";

    if (radioGroup) {
      lastElementId = Object.keys(radioGroup)[Object.keys(radioGroup).length - 1];
    } else {
      radioGroup = {};
    }

    radioGroup[elementId] = { id: elementId, pageNumber: page };
    this.templateFields[page][type][id]['radioGroup'] = radioGroup;

    const inputRadioElement = this.renderer.createElement('input');
    this.renderer.setAttribute(inputRadioElement, 'id', elementId);
    this.renderer.setAttribute(inputRadioElement, 'type', 'radio');
    this.renderer.setAttribute(inputRadioElement, 'name', groupId);

    const lastElement = this.elRef.nativeElement.querySelector(`#${lastElementId}`);
    if (lastElement) {
      this.renderer.insertBefore(lastElement.parentNode, inputRadioElement, lastElement.nextSibling);
    }
  }

  addCheckboxButtonInput(id: string, type: string, page: number, groupId: string) {
    const parentElement = this.elRef.nativeElement.querySelector(`#${id}`);
    if (parentElement) {
      const top = Number(parentElement.style.top.replace("px", ""));
      const left = Number(parentElement.style.left.replace("px", ""));

      const groupConfig = {
        top,
        left,
        pageNum: page,
        groupId: groupId
      };

      this.insertElement(type, null, groupConfig);
    }
  }

  initializeTemplateFields(pageNum: number, domid: string, elementName: string, groupId: string, props: any) {
    this.templateFields[props.pageNum] = this.templateFields[
      props.pageNum
    ] ? this.templateFields[props.pageNum] : JSON.parse(JSON.stringify(this.activeOptions))
    this.templateFields[pageNum] = this.templateFields[pageNum] ? this.templateFields[pageNum] : {};
    let anyElementExist = !!Object.keys(this.templateFields[pageNum][elementName] || {}).length;
    this.templateFields[pageNum][elementName] = anyElementExist ? this.templateFields[pageNum][elementName] : {};
    this.templateFields[pageNum][elementName][domid] = props;

    if (elementName === 'radioTab') {
      this.templateFields[pageNum][elementName][domid]['groupId'] = groupId;
      this.templateFields[pageNum][elementName][domid]['radioGroup'] = props.radioGroup;
    }

    if (elementName === 'checkBoxTab') {
      if (props.conditionalFields && props.conditionalFields.length) {
        this.templateFields[pageNum][elementName][domid]['conditionalFields'] = props.conditionalFields;
      }
      if (!this.templateFields[pageNum]['tabGroup']) {
        this.templateFields[pageNum]['tabGroup'] = {};
      }
      if (!this.templateFields[pageNum]['tabGroup'][props.groupId]) {
        this.templateFields[pageNum]['tabGroup'][props.groupId] = { tabLabel: props.groupId };
      }
      if (!this.templateFields[pageNum][elementName][domid]['conditionalFields']) {
        this.templateFields[pageNum][elementName][domid]['conditionalFields'] = [{}];
      }
    }

    if (elementName === 'tabGroup') {
      this.templateFields[pageNum]['tabGroup'][props.tabLabel] = props;
    }

    this.selectedObject = elementName;
    this.selectedObjectId = domid;
  }

  backToDocList(): void {
    this.openDocuSignEditor.emit(false);
  }

  openSaveConfirmationModal(): void {
    this.saveConfirmationModalVisible = true;
    this.saveConfirmationModalContent = 'Are you sure you’ve added all necessary fields to this document?';
  }

  openUpdateConfirmationModal(): void {
    this.updateConfirmationModalVisible = true;
    this.updateConfirmationModalContent = 'Save the current document and template?';
  }

  saveTemplateConfig(isConfirm = false): void {
    this.saveConfirmationModalVisible = false;

    const tempDocs: any = this.editTemplateService.getDocumentInfoByKey("uploadedFiles");
    const currentDoc: any = this.editTemplateService.getDocumentInfoByKey("file");

    const docIndex = tempDocs.findIndex((doc: any) => {
        return doc.name === currentDoc.name;
    });
    if (docIndex > -1) {
        tempDocs[docIndex].templateFields = this.templateFields;
    }
    Object.keys(this.templateFields).map((page) => {
        for (let fieldTypeK in this.templateFields[page]) {
            for (let fieldItem in this.templateFields[page][
                fieldTypeK
            ]) {
                this.templateFields[page][fieldTypeK][
                    fieldItem
                ].top = Math.round(
                    this.templateFields[page][fieldTypeK][
                        fieldItem
                    ].top / this.scale
                );
                this.templateFields[page][fieldTypeK][
                    fieldItem
                ].left = Math.round(
                    this.templateFields[page][fieldTypeK][
                        fieldItem
                    ].left / this.scale
                );
            }
        }
    });
    this.editTemplateService.setDocumentInfoByKey(
        "uploadedFiles",
        tempDocs
    );
    if(this.updateTemplateDocument){
      this.updateTemplateDocument.emit();
    }
    this.backToDocList();
  }

  updateTemplateConfig(isConfirm = false): void {
    this.updateConfirmationModalVisible = false;

    let editTemplate = this.editTemplateService.getDocumentInfoByKey("editTemplate");
    if(!editTemplate){
      editTemplate = {
        docuSignTemplate: {
          tabFieldsConfig: {}
        }
      }
    }
    for (let fieldType in editTemplate.docuSignTemplate.tabFieldsConfig) {
      for (let x = 0; x < editTemplate.docuSignTemplate.tabFieldsConfig[fieldType].length; x++) {
        if (editTemplate.docuSignTemplate.tabFieldsConfig[fieldType][x].documentId === Number(editTemplate.documentId)) {
          editTemplate.docuSignTemplate.tabFieldsConfig[fieldType].splice(x, 1);
        }
      }
    }
    let fields: any;
    Object.keys(this.templateFields).map((page) => {
      for (let fieldTypeK in this.templateFields[page]) {
        fields = Object.values(this.templateFields[page][fieldTypeK]);
        for (let f = 0; f < fields.length; f++) {
          fields[f].documentId = Number(
            editTemplate.documentId
          );
          fields[f].pageNumber = String(page);
          fields[f].xPosition = Math.round(
            fields[f].left / this.scale
          );
          fields[f].yPosition = Math.round(
            fields[f].top / this.scale
          );
          fields[f].tooltip = fields[f].tabName;

          if (fieldTypeK === "radioTab") {
            fields[f].left = Math.round(
              fields[f].left / this.scale
            );
            fields[f].top = Math.round(
              fields[f].top / this.scale
            );
            fields[f].radioGroupTabs = this.getRadioButtons(
              fields[f]
            );
          }
          if (fieldTypeK !== "checkBoxTab" && fieldTypeK !== "radioTab") {
            delete fields[f].groupId;
          }
          if (fieldTypeK !== "radioTab") {

            delete fields[f].left;
            delete fields[f].top;
          }
          delete fields[f].pageNum;
          delete fields[f].domid;
          delete fields[f].elementName;
        }

        if (!editTemplate.docuSignTemplate.tabFieldsConfig[fieldTypeK]) {
          editTemplate.docuSignTemplate.tabFieldsConfig[fieldTypeK] = [];
        }
        editTemplate.docuSignTemplate.tabFieldsConfig[fieldTypeK] =
          editTemplate.docuSignTemplate.tabFieldsConfig[fieldTypeK].concat(fields);
      }
    });

    editTemplate.docuSignTemplate.tabFieldsConfig['checkBoxTab'] = this.getUniqueByProperty(editTemplate.docuSignTemplate.tabFieldsConfig['checkBoxTab'], "tabLabel");
    editTemplate.docuSignTemplate.tabFieldsConfig['tabGroup'] = this.getUniqueByProperty(editTemplate.docuSignTemplate.tabFieldsConfig['tabGroup'], "tabLabel");
    this.documentService.updateTemplateDocumentFieldsConfig(this.projectId || '', editTemplate.docuSignTemplate).subscribe({
      next: () => {
        this.toastService.showSuccess('succesfully updated template.');
        if(this.updateProject){
          this.updateProject.emit();
        }
        this.editTemplateService.setDocumentInfo({});
        this.backToDocList();
      }
    })
  }

  getUniqueByProperty(array: any[], property: string): any[] {
    const uniqueSet = new Set();
    return (array && array.length ? array: []).filter(item => {
      const isDuplicate = uniqueSet.has(item[property]);
      uniqueSet.add(item[property]);
      return !isDuplicate;
    });
  }

  getRadioButtons(radioTab: any) {
    const radioArray: Array<any> = [];
    Object.values(radioTab.radioGroup).forEach((
        radioElement,
        index
    ) => {
        let radio: any = {};
        radio.tabName = radioTab.tabName;
        radio.selected = !!radio.selected;
        radio.documentId = radioTab.documentId;
        radio.pageNumber = radioTab.pageNumber;
        if (radioTab.alignHorizontal) {
            radio.xPosition = radioTab.left + ((index + 1) * 28 - 11);
            radio.yPosition = radioTab.top + 15;
        } else {
            radio.xPosition = radioTab.left;
            radio.yPosition = radioTab.top + ((index + 1) * 28 - 11);
        }
        radioArray.push(radio);
    });
    return radioArray;
  }

}
