import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../../../../shared/services/confirmation-dialog.service';
import { DynamicCredentialService } from '../../../../../../shared/services/dynamic-credential.service';
import { DynamicCredential } from '../../../../../../shared/interfaces/settings.interface';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'dynamic-table',
  standalone: true,
  imports: [
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    TableModule,
    CommonModule,
    DynamicDialogModule,
    TagModule,
    TooltipModule
  ],
  templateUrl: './dynamic-table.component.html',
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicTableComponent {
  @Input() dynamicCredential!: DynamicCredential[];
  @Input() title: string = '';
  @Input() isEditingEnabled: boolean = true;
  @Output() refreshEvent = new EventEmitter<void>();

  ref: DynamicDialogRef | undefined;
  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    public config: DynamicDialogConfig,
    private dynamicCredentialService: DynamicCredentialService
  ) {}

  onArchive($event: Event, credential: DynamicCredential): void {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message:
        'Are you sure you want to ' + (!credential.isArchived ? 'deactivate? <br> Doing so will make this job type unavailable to your firm and its candidates.' : `activate ${credential.name}?`),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        if(credential.isArchived) {
          this.dynamicCredentialService
            .unarchiveDynamicCredential(credential._id)
            .subscribe({
              next: () => {
                this.refreshEvent.emit();
              },
            });
        }else {
          this.dynamicCredentialService
            .archiveDynamicCredential(credential._id)
            .subscribe({
              next: () => {
                this.refreshEvent.emit();
              },
            });
        }
      },
    });
  }

  onDelete($event: Event, credential: DynamicCredential): void {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete ' + credential?.name + '? <br> Doing so will permanently remove the job type from your firm.',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.dynamicCredentialService
          .deleteDynamicCredential(credential?._id)
          .subscribe({
            next: (credential) => {
              this.refreshEvent.emit();
            },
          });
      },
    });
  }

  goToEdit(credential?: DynamicCredential): void {
    this.router.navigateByUrl(
      `client/settings/dynamic-credential/edit/${credential?._id}`
    );
  }
}
