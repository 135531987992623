import { Component, OnInit } from '@angular/core';
import { HeadingComponent } from '../../../shared/components/heading/heading.component';
import { PastProjects,PastProjectVM } from './past-project.interface';
import { TableModule, TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { RatingModule } from 'primeng/rating';
import { CommonModule, DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { DocumentService } from '../../../shared/services/document-service';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { TooltipModule } from 'primeng/tooltip';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-past-projects',
  standalone: true,
  imports: [TableModule, TagModule, ToastModule, RatingModule, ButtonModule, CommonModule, TooltipModule, HeadingComponent],
  templateUrl: './past-projects.component.html',
  styleUrl: './past-projects.component.scss',
  providers: [DatePipe]
})

export class PastProjectsComponent implements OnInit{
  

  expandedRows = {};

  constructor(private reviewerService: ReviewerService, private datePipe: DatePipe, private documentService:DocumentService) {}

  pastProjects: PastProjects = [];

  ngOnInit(): void {
    this.reviewerService.getPastProjects().subscribe(
      res => {
        this.pastProjects = res.map(this.transformProject);
        console.log(this.pastProjects);
      }
    );
  }

  private transformProject = (res: any): any => {
    let pastProjects: PastProjectVM = {
      id: res._id,
      codeName: '',
      firmId: '',
      firm: '',
      ratings: '',
      totalHours: '',
      regularHours: '',
      yourRating: '',
      firmRated: 0,
      startDate: null,
      role: '',
      payType: '',
      overTime: 0,
      hourlyRate: 0,
      endDate: '',
      updatedAt: '',
      overTimeHours: 0,
      tsheetAttached: false,
      documents: [],
      roleId: '',
      isTimeKeepingEnabled: false,
      totalHoursOnProject: {
        hours: '',
        minutes: '',
        seconds: ''
      }
    };

    pastProjects.codeName = this.existorEmpty(res.project.codeName);
    pastProjects.firmId = this.existorEmpty(res.firm._id);
    pastProjects.firm = this.existorEmpty(res.firm.name);
    pastProjects.ratings = this.existorEmpty(res.firm.averageRating.average);
    pastProjects.totalHours = this.existorEmpty(res.firm.averageRating.average);
    pastProjects.regularHours = this.existorEmpty(res.project.workSchedule.workHours);
    pastProjects.yourRating = this.existorEmpty(res.firm.averageRating.average);
    pastProjects.firmRated = this.existorEmpty(res.reviewer.profile.averageRating);
    pastProjects.startDate = this.datePipe.transform(res.project.publishedAt, 'MM/yyyy', 'UTC') ;
    pastProjects.role = res.role ? this.existorEmpty(res.role.roleType) : 'role deleted';
    pastProjects.payType = res.role ? this.existorEmpty(res.role.payType) : '/hr';
    pastProjects.overTime = res.role ? this.existorEmpty(res.role.overTime) : '0';
    pastProjects.hourlyRate = res.role ? this.existorEmpty(res.role.hourlyRate) : '0';
    pastProjects.endDate = res.finishedAt ? res.finishedAt : res.updatedAt;
    pastProjects.updatedAt = res.updatedAt;
    pastProjects.overTimeHours = 0;
    pastProjects.tsheetAttached = !!(res.tsheets && res.tsheets.isEnabled);
    this.getDocuments(res.project.documents || []).then((res) => {
      pastProjects.documents = res;
    });
    pastProjects.roleId = res.role._id;
    pastProjects.isTimeKeepingEnabled = res.tsheets.isEnabled;
    pastProjects.totalHoursOnProject = res.totalHoursOnProject;

    return pastProjects;
  };

  downloadDocument(path: any){
    console.log(path);
  }

  async getDocuments(projectDocs: any) {
    let documents = [];
    if (projectDocs.length > 0) {
      documents = await lastValueFrom(this.documentService.getDocumentsByIds(projectDocs));
    }
    return documents;
  }

  private existorEmpty(right: any): any {
    return right ?? '';
  }

  onRowExpand(event: TableRowExpandEvent) { }

  onRowCollapse(event: TableRowCollapseEvent) { }

}


export interface Product {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus?: string;
  category?: string;
  image?: string;
  rating?: number;
}