import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/services/storage.service';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
import { Reviewer } from '../../../../store/reviewer/reviewer.interface';
import { EmittersService } from '../../../../shared/services/emitters.service';
import { SkillAndCredentialService } from '../../../../shared/services/skill-credential.service';

@Component({
  selector: 'app-education',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './edit-profile-main.component.html',
  styleUrl: './edit-profile-main.component.scss'
})
export class EditProfileMainComponent implements OnInit {

  credentials = [];
  skillsAndExperience = [];
  reviewer!: Reviewer;
  profileStrength: any = {};
  constructor(private router:Router, 
    private storageService: StorageService, 
    private reviewerService: ReviewerService, 
    private eventEmitterService: EmittersService, 
    private skillAndCredentialService: SkillAndCredentialService){

  }

  async ngOnInit(): Promise<void> {
    await this.reviewerService.fetchReviewerAndUpdateTenants();
    this.reviewer = this.reviewerService.getReviewer();
    this.getCredentials();
    this.getSkillAndExperience();
    this.subscribeEvents();
    this.getProfileStrength();
  }

  subscribeEvents(){
    this.eventEmitterService.tenantEmitter.subscribe(async () => {
      this.getCredentials();
      this.getSkillAndExperience();
    });
  }

  getCredentials(): void{
    const credExist = this.skillAndCredentialService.getCredentials();
    if(credExist){
      this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((credentials) => {
        this.credentials = credentials;
      })
    }
  }

  getSkillAndExperience(): void{
    const skillExist = this.skillAndCredentialService.getSkills();
    if(skillExist){
      this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewer._id).subscribe((skillsAndExperience) => {
        this.skillsAndExperience = skillsAndExperience;
      })
    }
  }

  getProfileStrength(){
    this.profileStrength = this.storageService.getTenant();
  }

  goto(url:string){
    this.router.navigateByUrl(url);
  }
}
