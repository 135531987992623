import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  providers: [MessageService],
  standalone: true,
  imports: [ButtonModule, DynamicDialogModule, FloatLabelModule, InputTextModule, FormsModule],
  templateUrl: './archive-confirmation.component.html',
})
export class ArchiveConfirmation implements OnInit {
  endMessage = '';
  visible: boolean = false;

  constructor(private ref: DynamicDialogRef) {}

  ngOnInit() {
    this.endMessage = '';
  }

  closeDialog() {
    this.ref.close();
  }

  confirm(data: any) {
    this.ref.close({ endMessage: data });
  }
}
