import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProjectService } from '../../../../../../../shared/services/project.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { Files } from '../../../project.interface';

@Component({
  selector: 'app-document-edit',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    FileUploadModule,
    ReactiveFormsModule,
    InputTextareaModule
  ],
  templateUrl: './document-edit.component.html',
})
export class DocumentEditComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;

  files!: Files[] ;
  showDetailsInput = false;
  fileDetails!: FormGroup;
  newFile!: File | null;
  projectId!: string;
  constructor(
    public config: DynamicDialogConfig,    
    private fb: FormBuilder,
    private projectService: ProjectService,
    public ref: DynamicDialogRef,
    private toastService: ToastService,

  ) {}

  ngOnInit(): void {
    this.files = this.config?.data?.files;
    this.projectId = this.config?.data?.projectId;
    this.initializeForm();
  }

  initializeForm(): void{
    this.fileDetails = this.fb.group({
      docTitle: ['', [Validators.required]],
      userInstructions: [''],
    });
  }

  onFileSelect(event: any): void {
    this.newFile = event?.target?.files[0];
    this.showDetailsInput = true;
  }

  onDelete(fileId: string): void {
    this.projectService.deleteDocument(this.projectId, fileId).subscribe({
      next: (res) => {
        this.toastService.showSuccess('Document deleted successfully');
        this.files = this.files.filter(file => file._id !== fileId);      }
    });
  }

  removeFile(): void {
    this.newFile = null;
    this.showDetailsInput = false;
    this.fileUpload.nativeElement.value = '';
  }

  uploadFile(){
    if(this.fileDetails.valid){
      const opts = {
          documentType: "BLANK_FORM",
          project: this.projectId,
          docTitle: this.fileDetails.get('docTitle')?.value,
          userTitle:  this.fileDetails.get('userInstructions')?.value,
      }
      this.projectService.uploadRequiredDocument(this.projectId,this.newFile,opts).subscribe({
        next: (res) => {
          if(this.newFile){
            this.files.push(res[this.newFile.name])
            this.removeFile();
          }        
        }
      })
    }    
  }

}
