import moment from 'moment';
import { ProjectPayload, ReviewersPayload, RolesPayload } from '../interfaces/reports.interface';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { TimezonePayload } from '../../../../../shared/interfaces/date-time.interface';
import { environment } from '../../../../../../environments/environment';
import { KeyValue } from '@angular/common';
import { UserService } from '../../../../../shared/services/user.service';
import { constants } from '../../../../../shared/constants/constants';

export class BaseReport {
    projects: Array<ProjectPayload> = [];
    selectedProjects: Array<ProjectPayload> = [];

    roles: Array<RolesPayload> = [];
    selectedRoles: Array<RolesPayload> = [];

    reviewers: Array<ReviewersPayload> = [];
    selectedReviewers: Array<ReviewersPayload> = [];

    timezones: Array<TimezonePayload> = [];
    selectedTimezone: TimezonePayload | null = null;

    startDate = moment().subtract(1, 'weeks').toDate();
    endDate = moment().toDate();

    sessionUser: any = null;
    isDefaultTimeZone = true;
    rowsPerPageOptions = [5, 10, 20];
    noOfRows = 10;
    reportOptions = constants.reportOptions;

    constructor(
        protected reportService: ReportService,
        protected storageService: StorageService,
        protected dateTimeService: DateTimeService,
        protected userService: UserService
    ) {

    }

    async initializeReport(): Promise<void> {
        this.sessionUser = this.userService.getSessionUser();
        this.getTimezone();
        this.getProjectsByFirmAndMarket();
        this.setDefaultTimezone(this.isDefaultTimeZone);
    }

    onChangeDefaultTimezoneCheck(e: any): void{
        this.setDefaultTimezone(this.isDefaultTimeZone);
    }

    async getProjectsByFirmAndMarket(): Promise<void> {
        this.projects = await this.reportService.getProjectsByFirmAndMarkets(this.sessionUser.firm._id,['STARTED']);
    }

    async onProjectSelectionChange(e: any): Promise<void> {
        this.roles = [];
        this.selectedRoles = [];
        await this.getRolesByProjectIds();
        await this.getReviewersByProjectAndRoleIds();
    }

    async getRolesByProjectIds(): Promise<void> {
        const projectIds = this.selectedProjects.map(project => project.id);
        this.roles = await this.reportService.getAllRolesByProjectIds(projectIds);
    }

    async onRolesSelectionChange(e: any): Promise<void> {
        await this.getReviewersByProjectAndRoleIds();
    }

    async getReviewersByProjectAndRoleIds(): Promise<void> {
        const projectIds = this.selectedProjects.map(project => project.id);
        const roleIds = this.selectedRoles.map(role => role.id);
        const firmId = this.sessionUser.firm.id;
        const startDate = moment(this.startDate).format('MM/DD/YYYY');
        const endDate = moment(this.endDate).format('MM/DD/YYYY');
        this.reviewers = await this.reportService.getSelectedProjectReviewers(projectIds, roleIds, firmId, startDate, endDate);
        this.reviewers.forEach(reviewer => {
            reviewer.fullName = `${reviewer.firstName} ${reviewer.lastName}`;
        });
        this.selectedReviewers = [];
    }

    getTimezone(): Array<TimezonePayload> {
        this.timezones = this.dateTimeService.getTimeZoneList(environment.usSpecificTimezoneOnly);
        return this.timezones;
    }

    setDefaultTimezone(isDefault: boolean): void{
        if(isDefault){
            this.setTimezone(null);
        } else {
            this.setTimezone(this.getTimezone().length ? this.getTimezone()[0] : null);
        }
    }

    setTimezone(timezone: TimezonePayload | null): void{
        this.selectedTimezone = timezone;
    }

    originalOrder = (a: KeyValue<number,any>, b: KeyValue<number,any>): number => {
        return 0;
    }

    generateWeekDateAsPerFirm(): void {
        const day = 'Monday';
        let md = moment();
        while (md.format('dddd').toLowerCase() !== day.toLowerCase()) {
            md = md.subtract(1, 'day');
        }
        this.startDate = md.toDate();
        this.endDate = md.add(6, 'day').toDate();
    }

    generateColumn(value: string, options?: any) {
        if (!options) {
            options = {};
        }
        return {
            width: options.width || '*',
            text: String(value),
            fontSize: options.fontSize || 11,
            bold: options.bold || false,
            alignment: 'center',
            fillColor: options.fillColor || 'white',
            color: options.color || 'black',
            margin: [0, 5, 0, 5]
        };
    }

    groupByProject(list: any[], key: string): { [key: string]: any[] } {
        const result: { [key: string]: any[] } = {};
      
        if (list && list.length) {
          list.forEach(item => {
            const groupKey = item[key];
            if (!Array.isArray(result[groupKey])) {
              result[groupKey] = [];
            }
            result[groupKey].push(item);
          });
        }
      
        return result;
    }

    generateProjectHeader(value: string, invoice:boolean=false) {
        return {
            colSpan: invoice? 5: 7,
            text: value,
            fontSize: 14,
            bold: true,
            alignment: 'left',
            fillColor: 'white',
            color: 'black',
            margin: [0, 5, 0, 5]
        };
    }

    convertDecimalTo(roundParam: string, number: number | null | undefined): string {
        if (!number && number !== 0) return '';
      
        let rounded: string | number;
      
        switch (roundParam) {
          case 'Tenth':
            rounded = Math.round(number * 10) / 10;
            break;
      
          case 'Quarter':
            rounded = this.getQuarterRound(number);
            break;
      
          case 'Whole':
            rounded = Math.round(number);
            break;
      
          default:
            rounded = number;
        }
      
        return rounded.toString();
    }
      
    getQuarterRound(value: number): string {
        return (Math.round(value * 4) / 4).toFixed(2);
    }
    
}
