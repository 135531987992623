import { Component, OnInit, Pipe } from '@angular/core';
import { FloatLabelModule } from "primeng/floatlabel"
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessagesService } from '../../../../../../shared/services/messages-service.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { constants } from '../../../../../../shared/constants/constants';
import { MessagePostData } from '../../message.interface';
import { ButtonModule } from 'primeng/button';
import { PipesModule } from '../../../../../../shared/pipes/pipes.module';
@Component({
  selector: 'app-create-discussion',
  templateUrl: './create-discussion.component.html',
  styleUrls: ['./create-discussion.component.scss'],
  standalone: true,
  imports:[
    PipesModule,
    FormsModule,
    FloatLabelModule,
    DropdownModule,
    ChipsModule,
    ButtonModule
  ]
})
export class CreateDiscussionComponent implements OnInit {
  sendToHeading: string;
  data: any;
  messageTemplates: any;
  templateMessageSubject: any;
  templateMessageContent: any;
  
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig,
     private messagesService:MessagesService, private toastService:ToastService) { 
    this.sendToHeading = `Send to ${(config.data.msgType).toLowerCase()}: ${config.data.title}`;
    this.data = config.data;
  }

  ngOnInit(): void {
    this.getAllTemplate();
  }
  getAllTemplate(): void {
    this.messagesService.getAllMessageTemplates().subscribe(
      (res) => {
        this.messageTemplates = res;
      }
    );
  }

sendNewMessage(){
  if(this.data && this.data.formerlyAssigned) {
    this.toastService.showError('This reviewer is no longer available');
    return;
  }

  if (this.templateMessageSubject && this.templateMessageContent) {
      let postData:MessagePostData = {
          type: this.data.msgType,
          id: this.data.projectId,
          subject: this.templateMessageSubject,
          message: this.templateMessageContent
      };
      if (this.data.status) {
          postData.status = this.data.status;
      }
      if (this.data.msgType === constants.messageType.role) {
          postData.id = this.data.roleId;
      }
      else if (this.data.msgType === constants.messageType.reviewer) {
          postData.id = this.data.reviewerId;
          postData.roleId = this.data.roleId;
          postData.projectId = this.data.projectId;
      }
      this.messagesService.sendMessage(postData).subscribe(()=> {
          this.ref.close('success');
      });
  }
}

  onTemplateSelect(event:any){
     this.templateMessageSubject = event.value.subject;
     this.templateMessageContent = event.value.content;
  }

}
