<div header="Archive Project" class="align-items-center">
    <h5 class="block mb-5">Are you sure you want to archive?</h5>
    <div class="flex align-items-center gap-3 mb-3">
        <textarea [(ngModel)]="endMessage" rows="3" cols="30" pInputText id="end-reason" class="flex-auto pt-3 pl-2" autocomplete="off" placeholder="Message to be shared with staff on notification of project completion.(optional)" ></textarea>
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" size="large"
        [rounded]="true"
        [outlined]="true" (click)="closeDialog()" />
        <p-button label="OK" [rounded]="true" size="large" (click)="confirm(endMessage)" />
    </div>
</div>