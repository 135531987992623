@if (projectPositionsFilled && projectDetails?.status == 'STARTED') {
    <div class="flex flex-row justify-content-end">
        <p-button [link]="true" (click)="openGroupChat()" [rounded]="true" class="flex primary-background primary white-space-nowrap send-message-color">
            <span class="material-symbols-outlined "> forum </span>
            Send Group Message
        </p-button>
        <p-button [link]="true" (click)="showMailDialog(projectDetails._id, emailType.project, projectDetails.firm, 'All Staff', '', '')" [rounded]="true" class="ml-3 flex primary-background primary white-space-nowrap send-message-color">
            <span class="material-symbols-outlined">
                mail
            </span>
            Send Group Email
        </p-button>
    </div>
    <div class="table-options align-items-end justify-content-end"></div>
}

@if (projectStaffList.length) {
    <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="projectStaffList" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
    (onRowCollapse)="onRowCollapse($event)">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="roleType">
                    Role
                    <p-sortIcon field="roleType" />
                </th>
                <th pSortableColumn="positions">
                    Position
                    <p-sortIcon field="positions" />
                </th>
                <th pSortableColumn="startAt">
                    Start Date
                    <p-sortIcon field="startAt" />
                </th>
                <th style="width: 8%">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" class="border-0" let-projectStaffList let-expanded="expanded">
            <tr>
                <td >
                    <div class="wrap-check-data flex align-items-center gap-3">
                        {{ projectStaffList.roleType }}
                    </div>
                </td>
                <td>{{ projectStaffList.positions || 0}} Positions
                    Listed/{{ projectStaffList.positionsFilled || 0 }} Filled</td>
                <td>{{ projectStaffList.startAt | date : 'MM/dd/yyyy' }}</td>
                <td>
                    <div class="wrap-actions flex align-items-center gap-2">
                        @if(projectStaffList.status === roleStatus.active){
                            <div  pTooltip="End Role" tooltipPosition="top">
                                <p-button (click)="openDeleteModal(projectStaffList)" [rounded]="true" [text]="true" icon="true" plain="true">
                                    <span class="red material-symbols-outlined">
                                        delete
                                    </span>
                                </p-button>
                            </div>
                        }
                        @if(projectStaffList.status !== roleStatus.archive){
                            <p-button [disabled]="!projectStaffList.positionsList || !projectStaffList.positionsList.length" pTooltip="Email Role" 
                            tooltipPosition="top" [rounded]="true" (click)="showMailDialog(projectDetails._id, emailType.role, projectDetails.firm, projectStaffList.roleType, projectStaffList._id, '')" [text]="true" icon="true">
                                <span class="material-symbols-outlined">
                                    mail
                                </span>
                            </p-button>
                            <p-button [disabled]="!projectStaffList.positionsList || !projectStaffList.positionsList.length" pTooltip="Message Role" 
                            tooltipPosition="top" (click)="openCreateChat(projectStaffList)" [rounded]="true" [text]="true" icon="true">
                                <span class="material-symbols-outlined">
                                    forum
                                </span>
                            </p-button>
                        }
                        @if(projectStaffList.status === roleStatus.archive){
                            <div>
                                <span class="tag-value {{ 'ARCHIVE' | lowercase }}">
                                    Ended
                                </span>
                            </div>
                        }
                        <p-button [ngStyle]="{'margin-left': 'auto'}" [pTooltip]="expanded? 'Close' : 'Expand'" 
                        tooltipPosition="top" type="button" pRipple [pRowToggler]="projectStaffList" [text]="true"
                            [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" 
                            [size]="'large'" class="row-toggler" />
                    </div>
                </td>
            </tr>
        </ng-template>
    
        <ng-template pTemplate="rowexpansion" let-projectStaffList>
            @if(projectStaffList.positionsList && projectStaffList.positionsList.length > 0){
                <tr>
                    <td colspan="7" class="p-0">
                        <div class="inner-wrap surface-100 p-0">
                            <p-table [value]="projectStaffList.positionsList" dataKey="_id">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="Reviewer" class="pl-6">
                                            Candidate <p-sortIcon field="Reviewer" />
                                        </th>
                                        <th pSortableColumn="Date">
                                            Staff Date <p-sortIcon field="Date" />
                                        </th>
                                        <th pSortableColumn="Rate">
                                            Hourly Rate <p-sortIcon field="Rate" />
                                        </th>
                                        <th pSortableColumn="Status">
                                            Status <p-sortIcon field="Status" />
                                        </th>
                                        <th style="width: 11%" class="pr-40">Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-position>
                                    <tr class="table-data">
                                        <td class="pl-6 cursor-pointer" (click)="openReviewerDialog(position.reviewer._id)">{{ position.reviewer.firstName }} {{ position.reviewer.lastName }}</td>
                                        <td>
                                            @if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                                                @if (projectStaffList.status === 'ARCHIVE') {
                                                    <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                                }
                                                @if (projectStaffList.status !=='ARCHIVE' && position.status === 'OCCUPIED') {
                                                    <p>Accepted {{position.filledAt | date : 'MM/dd/yyyy'}}</p>
                                                }
                                               
                                            }
                                            @if (position.status === 'QUIT' || position.status === 'FIRED' || position.status === 'COMPLETED') {
                                                @if (position.status === 'QUIT') {
                                                    <p>Quit {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                                }

                                                @if (position.status === 'COMPLETED') {
                                                    <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                                }

                                                @if (position.status === 'FIRED') {
                                                    @if(position.presumptive && position.presumptive.status === 'DENIED'){
                                                        <p>Denied {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                                    } @else{
                                                        <p>Fired {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                                    }
                                                }
                                                
   
                                            }
                                        </td>
                                        <td (click)="openReviewerDialog(position.reviewer._id)" class="cursor-pointer">
                                            Rate ${{projectStaffList.hourlyRate}}/{{projectStaffList.payType === 'salary' ? 'yr' : 'hr'}}
                                            @if (projectStaffList.overTime > 0) {
                                                <span>  (OT ${{projectStaffList.overTime}}/hr)</span>
                                            }
                                        </td>
                                        <td>
                                        @if(position.presumptive && ((position.presumptive.status === 'ASSIGNED' && position.status === 'OCCUPIED') || 
                                        (position.presumptive.status === 'DENIED' && position.status === 'FIRED')) ){
                                             <span class="tag-value {{ position.presumptive.status | lowercase }}">
                                                {{ 'Presumptive '+position.presumptive.status | lowercase }}
                                            </span>
                                        } @else {
                                            <span class="tag-value {{ position.status | lowercase }}">
                                                {{ position.status | lowercase }}
                                            </span>
                                        }
                                        </td>
                                        <td class="pr-40">
                                            
                                            <div class="wrap-actions wrap-buttons-expandable">
                                                @if(!user.firm.isDocusignEnabled){
                                                    <p-button class="action-icon" 
                                                        [pTooltip]="getMessageNoOfDocumentUploadedByUser(position.reviewer)" 
                                                        tooltipPosition="top" 
                                                        [rounded]="true"
                                                        [text]="true" 
                                                        icon="true"
                                                        (click)="openReviewerDocumentDetail(position.reviewer)"
                                                        [disabled]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'NOT_UPLOADED'">
                                                        <span class="material-symbols-outlined" 
                                                            [class.vibrant-pink]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'PARTIALLY_UPLOADED'" 
                                                            [class.dark-grey]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'NOT_UPLOADED'"
                                                            [class.freshgreen]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'UPLOADED'">
                                                            download
                                                        </span>
                                                    </p-button>
                                                }
                                                @if (projectStaffList.status !== 'ARCHIVE') {
                                                    @if(position.status === 'FIRED' || position.status === 'QUIT'){
                                                        <p-button pTooltip="Send Offer" class="action-icon" 
                                                        tooltipPosition="top" (click)="openImmediateSendOfferModal(position.role, position.reviewer)" [rounded]="true" [text]="true" icon="true">
                                                            <span class="material-symbols-outlined">
                                                                send
                                                            </span>
                                                        </p-button>
                                                    }
                                                    @if(position.status !== 'FIRED' && position.status !== 'QUIT' && position.status !== 'COMPLETED'){
                                                        @if(position.presumptive && position.presumptive.status === 'ASSIGNED'){
                                                        <p-button pTooltip="Presumptive" class="action-icon" 
                                                        tooltipPosition="top" (click)="presumptiveApproveDeny($event,position,projectStaffList.roleType)"  [rounded]="true" [text]="true" icon="true">
                                                            <span class="material-symbols-outlined">
                                                                check_box
                                                            </span>
                                                        </p-button>}
                                                        @if(!(position.presumptive && position.presumptive.status === 'ASSIGNED')){
                                                        <p-button pTooltip="Fire" class="action-icon" 
                                                        tooltipPosition="top" (click)="showFireReviewerDialog($event, position.reviewer._id, position._id, position.role, position.reviewer.firstName, position.reviewer.lastName)" [rounded]="true" [text]="true" icon="true">
                                                            <span class="material-symbols-outlined red">
                                                                person_off
                                                            </span>
                                                        </p-button> }   
                                                        <p-button class="action-icon" pTooltip="Messaging" (click)="openCreateChatReviewer(position)" 
                                                        tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                            <span class="material-symbols-outlined">
                                                                chat
                                                            </span>
                                                        </p-button>
                                                        <p-button pTooltip="Send Email" class="action-icon" 
                                                        tooltipPosition="top" [rounded]="true" [text]="true" icon="true" (click)="showMailDialog(projectDetails._id, emailType.individual, projectDetails.firm, position.reviewer.firstName +' ' + position.reviewer.lastName, projectStaffList._id, position.reviewer._id)">
                                                            <span class="material-symbols-outlined">
                                                                mail
                                                            </span>
                                                        </p-button>
                                                    }  
                                                }
                                            </div> 
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            }
            @else{
                <tr>
                    <td colspan="7">
                        <span class="text-sm font-medium">There are no candidates staffed on this role</span>
                    </td>
                </tr>
            }
        </ng-template>
    </p-table>
}

@else {
    <span class="text-sm font-medium">No Roles Created</span>
}

<p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="visibleDeleteModal" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px', height: selectedDeleteRole?.status === roleStatus.archive ? '50vh' : 'auto' }"  
    (onHide)="hideDeleteModal()">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
               Confirmation
            </span>
        </div>
    </ng-template>

    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center">
            <p>  You are about to
                @if(selectedDeleteRole?.status === roleStatus.active){
                    End
                }@else {
                    Restart
                }
                <span class="font-bold">{{selectedDeleteRole?.roleType}}</span>
                Role:
            </p>
          </div>
        @if(selectedDeleteRole?.status === roleStatus.active){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
                <textarea placeholder="Message to be shared with staff on notification of role completion. (optional)" rows="3" [(ngModel)]="deleteRoleComment" pInputText id="end-reason" class="flex-auto" autocomplete="off" cols="30" ></textarea>
            </div>
        }
        @if(isUnreadMessagesExist){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2" ng-if="vm.discussions.length">
                <p class="font-twntwo">
                    <b>Note: </b> There are unread messages from one or more reviewer whose role is about to end.
                    These messages will not be readable if you continue. To read messages click “Cancel” and
                    navigate to the messaging screen.
                </p>
            </div>
        }
        @if(selectedDeleteRole?.status === roleStatus.archive){
            <p>
                Please select a new start date and time for the role.
            </p>
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
            <input type="text" style="display: none;" />
            <p-floatLabel>
                <p-calendar 
                    [(ngModel)]="restartDate"
                    inputId="restart_date" 
                    [minDate]="roleMinDate"
                    [maxDate]="roleMaxDate"
                    placeholder="mm/dd/yy"/>
                <label for="restart_date">Start Date</label>
            </p-floatLabel>
        </div>
       
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
            <p-calendar 
            inputId="calendar-timeonly" 
            [(ngModel)]="restartTime"
            [timeOnly]="true" 
            hourFormat="12"
            styleClass="slider-style"
            />
        </div>
    }
    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" size="large" [rounded]="true" (onClick)="hideDeleteModal()"/>
        <p-button label="Ok" size="large" [rounded]="true" (onClick)="deleteRole()"/>
    </ng-template>
</p-dialog>
