<div class="w-full view-docusign-document-modal">
    <div class="flex flex-column gap-4">
        <div>
            <h4 class="h6 m-0">
                <b>Documents</b>
            </h4>
            @if(documentInfo?.status){
                <b>Status:  {{documentInfo?.status}}</b>
            }
        </div>
        @if(documentInfo?.isReviewerModal){
            <div class="col-12 grid">
                @if(documentInfo?.documents && documentInfo?.documents.length){
                    <div class="list-none m-0 contact-list completed-wrapper">
                        @for(document of documentInfo?.documents; track document) {
                            <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item" >
                                <div>
                                    {{document.name}}
                                </div>
                                <p-button
                                    [rounded]="true"
                                    [text]="true"
                                    icon="true"
                                    (onClick)="viewDocument(document.documentId,document.name)"
                                    pTooltip="Download"
                                    tooltipPosition="top" class="download-wrapper">
                                    <span class="material-symbols-outlined">download</span>
                                </p-button>
                            </div>
                            <p-divider />
                        }
                    </div>
                }
                @if(documentInfo.status && documentInfo.status !== 'completed'){
                    <div class="flex justify-content-between gap-4" style="width: 100%;">
                        <div class="flex gap-2 button-wrapper">
                            <p-button label="Continue Completing Document" size="large" [rounded]="true" (onClick)="completeDocument()" />
                        </div>
                        
                    </div>
                }
            </div>
        } @else {
            <div class="col-12 grid">
                @if(documentInfo?.templates && documentInfo?.templates.length){
                    <div class="list-none m-0 contact-list completed-wrapper">
                        @for(template of documentInfo?.templates; track template) {
                            <div class="px-4 hover:bg-primary-50 hover:text-primary-900 list-item" >
                                <div class="font-bold">
                                    {{template.name}}
                                </div>
                                @if(template.documents && template.documents.length){
                                    @for(document of template.documents; track document) {
                                        <p-divider />
                                        <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item " style="align-items: center;" >
                                            <div>
                                                {{document.name}}
                                            </div>
                                            <p-button
                                                [rounded]="true"
                                                [text]="true"
                                                icon="true"
                                                (onClick)="viewDocument(document.documentId,document.name)"
                                                pTooltip="Download"
                                                tooltipPosition="top" class="download-wrapper">
                                                <span class="material-symbols-outlined">download</span>
                                            </p-button>
                                        </div>
                                    }
                                }

                            </div>
                            <p-divider />
                        }
                    </div>
                }
            </div>
            @if(documentInfo.attachments && documentInfo.attachments.length){
                <h4 class="h6 m-0">
                    <b>Attachments</b>
                </h4>
                <div>
                    @for(attachment of documentInfo.attachments; track attachment) {
                        <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item " style="align-items: center;" >
                            <div>
                                {{attachment.name}}
                            </div>
                            <p-button
                                [rounded]="true"
                                [text]="true"
                                icon="true"
                                (onClick)="viewDocument(attachment.documentId,attachment.name)"
                                pTooltip="Download"
                                tooltipPosition="top" class="download-wrapper">
                                <span class="material-symbols-outlined">download</span>
                            </p-button>
                        </div>
                    }
                </div>
            }
        }

        @if(documentInfo?.additionalEnvelopes && documentInfo?.additionalEnvelopes.length){
            <div class="incomplete-wrapper">
                <h4 class="h6 m-0 mr-2">
                    <b>Additional Documents</b>
                </h4>
                <p-divider />
    
                
                @for(addDocEnvelop of documentInfo.additionalEnvelopes; track addDocEnvelop){
                    @if(documentInfo?.isReviewerModal){
                        @if(addDocEnvelop.status){
                            <span class="font-nintenn gray-color " ng-if="addDocEnvelop.status">
                                (<b>Status: <span class="dark-grey margin-left-5">{{addDocEnvelop.status}}</span></b>)
                                <div>
                                    <p-divider />
                                </div>
                            </span>
                        }
        
                        <div class="list-none m-0 contact-list">
                            @for(document of addDocEnvelop?.documents; track document) {
                                <div class="flex list-item">
                                    <div class="px-4 hover:bg-primary-50 hover:text-primary-900" >
                                        {{document.name}}
                                    </div>
                                    <p-button
                                        [rounded]="true"
                                        [text]="true"
                                        icon="true"
                                        (onClick)="viewDocument(document.documentId,document.name)"
                                        pTooltip="Download"
                                        tooltipPosition="top" class="download-wrapper">
                                        <span class="material-symbols-outlined">download</span>
                                    </p-button>
                                </div>
                                <p-divider />
                            }
                        </div>
                        @if(addDocEnvelop.status && addDocEnvelop.status!== 'completed'){
                            <div class="flex justify-content-between gap-4">
                                <div class="flex gap-2 button-wrapper">
                                    <p-button label="Complete Document" size="large" [rounded]="true" (onClick)="completeAdditionalDocument(addDocEnvelop)" />
                                </div>
                            </div>
                        }
                        <p-divider />
                    } @else {
                        @if(addDocEnvelop.templates && addDocEnvelop.templates.length){
                            @for(additionalTemplate of addDocEnvelop.templates; track additionalTemplate) {
                                <div class="px-4 hover:bg-primary-50 hover:text-primary-900 list-item" >
                                    <div class="font-bold">
                                        {{additionalTemplate.name}}
                                    </div>
                                    @if(additionalTemplate.documents && additionalTemplate.documents.length){
                                        @for(document of additionalTemplate.documents; track document) {
                                            <p-divider />
                                            <div class="px-4 hover:bg-primary-50 hover:text-primary-900 flex list-item " style="align-items: center;" >
                                                <div>
                                                    {{document.name}}
                                                </div>
                                                <p-button
                                                    [rounded]="true"
                                                    [text]="true"
                                                    icon="true"
                                                    (onClick)="viewDocument(document.documentId,document.name)"
                                                    pTooltip="Download"
                                                    tooltipPosition="top" class="download-wrapper">
                                                    <span class="material-symbols-outlined">download</span>
                                                </p-button>
                                            </div>
                                        }
                                    }
    
                                </div>
                                <p-divider />
                            }
                        }
                    }
                }
                
            </div>
        }

    </div>
</div>
