import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ReviewerService } from '../../services/reviewer.service';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-removed-skills-credentials',
  standalone: true,
  imports: [ButtonModule, FloatLabelModule, FormsModule, CheckboxModule],
  templateUrl: './removed-skills-credentials.component.html',
  styleUrl: './removed-skills-credentials.component.scss'
})
export class RemovedSkillsCredentialsComponent implements OnInit {

  firmId!: string;
  userId!: string;
  type!: string;
  addedIds: any[] = [];
  data: any[] = [];
  checked: any;
  userName = '';
  constructor(private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private reviewerService: ReviewerService
  ) {
    this.data = this.config.data.data;
    this.userName = this.config.data.userName;
  }

  ngOnInit(): void {
    this.firmId = this.config.data.firmId;
    this.userId = this.config.data.userId;
    this.type = this.config.data.type;
  }

  edit(id: string){
    if(this.addedIds.includes(id)){
      let index = this.addedIds.findIndex((res) => res == id);
      this.addedIds.splice(index, 1);
    }
    else{
      this.addedIds.push(id)
    }
    console.log(this.addedIds);
  }

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    let body = {
      userId: this.userId,
      idsToUpdate: this.addedIds,
      status: 'Active'
    };
    if(this.type === 'Skills'){
      this.reviewerService.updateTenantCriteria(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
    else if(this.type === 'Credentials'){
      this.reviewerService.updateTenantCredentials(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
  }
}
