import { Component } from '@angular/core';
import { HeadingComponent } from "../../../shared/components/heading/heading.component";
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';
import { ProjectService } from '../../../shared/services/project.service';
import { TimeKeepingService } from '../../../shared/services/time-keeping.service';
import { PositionResponse, Role } from '../reviewer-dashboard.interface';
import moment from 'moment';
import { ToastService } from '../../../shared/services/toast.service';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import { DocumentService } from '../../../shared/services/document-service'
import { catchError , Subscription } from 'rxjs';
import { StopwatchService } from '../../../shared/services/stopwatch.service';
import { Router } from '@angular/router';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { Reviewer } from '../../../shared/interfaces/reviewer.interface';
import { constants } from '../../../shared/constants/constants';
import { TimesheetEditorComponent } from '../../../shared/components/timesheet-editor/timesheet-editor.component';

@Component({
  selector: 'app-invoice-generator',
  standalone: true,
  templateUrl: './invoice-generator.component.html',
  styleUrl: './invoice-generator.component.scss',
  imports: [HeadingComponent, CommonModule, DropdownModule, FormsModule, CardModule, ButtonModule, FloatLabelModule, TableModule, DialogModule, TimesheetEditorComponent]
})
export class InvoiceGeneratorComponent {
  todayDate = new Date();
  visible: boolean = false;
  selectedPosition: any;
  lastPosition: any;
  roles: any = [];
  currentWeek = this.getCurrentWeek();
  navigatedWeek = { ...this.currentWeek};
  selectedRole = {
    name: '',
    value: ''
  };
  allowedDays: any = [];
  contractorTimekeepingEnabled: boolean = false;
  invoiceSubmittedOn: any;
  lastInvoice: any;
  tsheets: any
  roleIsStarted: boolean = false;
  timezone = moment().tz(moment.tz.guess()).format('z');
  dailyTsheets: any;
  weekSecs: number = 0;
  weekHours: number = 0;
  totalSecs: number = 0;
  clockedInTimeSheet: any;
  weekDuration: any;
  isClockedIn: boolean = false;
  dailySecs: any;
  dailyDuration: any;
  showTimeSheetEditor: boolean = false;
  invoices = [];
  isTimeSheetEditable: boolean = false;
  selectedTimeSheet = null;
  intervalId: any;
  subscription!: Subscription;
  navigationData!: any;
  displayApproveTime = false;
  endedPositions!: any;
  reviewer!: Reviewer;
  weekTsheets: any;
  totalDuration: any;

  constructor(private userService: UserService, private projectService: ProjectService, private timeKeepingService: TimeKeepingService,
    private toast: ToastService, private confirmationDialogService: ConfirmationDialogService, private documentService: DocumentService,
    private stopwatchService: StopwatchService, private router: Router, private reviewerService: ReviewerService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.navigationData = this.router.getCurrentNavigation()?.extras?.state;
    }
  }

  async ngOnInit() {
    this.getReviewer();
    this.getPositions();
    this.getStopwatchSubscription();
    if(this.navigationData){
      this.selectedRole.name = this.navigationData.data.name;
      this.selectedRole.value = this.navigationData.data.value;
      this.displayApproveTime = this.navigationData.data.displayApproveTime;
    }
  }

  getStopwatchSubscription() {
    this.subscription = this.stopwatchService.getStopwatchState().subscribe(state => {
      if (state === 'running') {
        this.updateTime();
      } else {
        this.stopTimer();
      }
    });
  }
  showDialog() {
    this.visible = true;
    const position = this.getPositionFromRole(this.selectedRole.value);
    const weekId = moment(this.navigatedWeek.startDate).add(2, 'days').isoWeek();
    const reviewerId = position.reviewer._id ? position.reviewer._id.toString() : position.reviewer;
    const data = {
      roleId: this.selectedRole.value,
      reviewerId: reviewerId,
      weekStartDate: this.navigatedWeek.startDate,
      weekEndDate: this.navigatedWeek.endDate,
      weekId: weekId,
      timezone: moment.tz.guess()
    };
    this.timeKeepingService.getInvoicesByWeek(data).subscribe((resp: any) => {
      this.invoices = resp;
    })
  }
  onRoleChange(event: any) {
    this.selectedRole = event.value;
    this.navigationData = null;
    this.getPositions();

  }
  openAddTimeModal() {
    if(this.selectedRole.value){
      this.isTimeSheetEditable = false;
      this.selectedTimeSheet = null;
      this.showTimeSheetEditor = true;
    }
    else{
      this.toast.showError('Role is not selected');
    }

  }
  closeModal(success: any) {
    if (success) {
      this.getTsheetList(this.navigatedWeek);
    }
    this.showTimeSheetEditor = false;
  }
  getPositions() {
    this.userService.getPositions().subscribe((resp: PositionResponse) => {
      let roles: any = [];
      const positions: Array<any> = [...resp.occupied, ...resp.ended];
      if (positions && positions.length) {
        positions.forEach(position => {
          roles.push({...position.role, projectName: position.project.codeName});
        });

        if(this.selectedRole && this.selectedRole.value){
          const selectedPosition = positions.find((pos: any) => pos.role._id === this.selectedRole.value);
          this.selectedPosition = selectedPosition;
        } else {
          this.selectedPosition = positions[0];
        }
      }

      this.roles = roles.filter((role: any) => 
        role.status !== constants.roleStatus.archive && !role.isDeleted
      )
      .map((role: Role) => {
        return {
          name: `${role.projectName}-${role.roleType}`,
          value: role._id
        }
      });
      this.roleIsStarted = !!(this.selectedPosition && this.selectedPosition.role && this.selectedPosition.role.isStarted);
      this.getPastProjectTimekeeping();
    })
  }
  async getPastProjectTimekeeping() {
    this.projectService.getPastProjectTimekeeping()
      .pipe(
        catchError(err => {
          this.checkPostion();
          return []
        })
      )
      .subscribe((resp: any) => {
        if (resp) {
          this.lastPosition = resp.pop();
          this.checkPostion();
        }
      });
  }
  checkPostion() {
    const position = this.getPositionFromRole(this.selectedRole.value ? this.selectedRole.value : '');
    if (position) {
      this.fetchAndSetRoles(position.project._id, true);
    }
  }
  getPositionFromRole(roleId: string) {
    if (this.selectedPosition && roleId === this.selectedPosition.role._id) {
      return this.selectedPosition;
    }
    if (this.lastPosition && roleId === this.lastPosition.role._id) {
      return this.lastPosition;
    }

    if (this.selectedPosition) {
      return this.selectedPosition;
    }
  }

  fetchTimeKeepings(callInvoiceSubmission: boolean, firstTime: boolean) {
    this.contractorTimekeepingEnabled = this.selectedPosition ? this.selectedPosition.project.contractorTimekeepingEnabled : this.lastPosition && this.lastPosition.project.contractorTimekeepingEnabled || false;
    if (this.contractorTimekeepingEnabled) {
      this.fetchWeeklistAgain(callInvoiceSubmission, firstTime);
    } else {
      this.getTsheetList(null, callInvoiceSubmission, firstTime);
    }
  }
  getCurrentWeek() {
    let startOfWeek = moment().isoWeekday(1);
    startOfWeek = startOfWeek.startOf('day');
    let endOfWeek = moment(startOfWeek).add(6, 'days');
    endOfWeek = endOfWeek.endOf('day');
    const week = { startDate: startOfWeek, endDate: endOfWeek };
    return week;
  }

  getReviewer(){
    this.reviewerService.fetchReviewer().subscribe((reviewer) => {
      this.reviewer = reviewer;
    });
  }

  fetchAndSetRoles(projectId: string, firstTime: boolean) {
    if (this.navigationData && this.selectedRole && this.selectedRole.name && this.selectedRole.value){
      this.selectedRole = this.roles.find((role: any) => role.value === this.navigationData?.data?.value);
    }
    else if(this.roles.length && (!this.selectedRole || !this.selectedRole.value)){
      this.selectedRole = this.roles[0];
    }
    if (firstTime) {
      this.fetchTimeKeepings(true, true);
    }
  }
  fetchWeeklistAgain(callInvoiceSubmission: any, firstTime: any) {
    this.getTsheetList(this.navigatedWeek, callInvoiceSubmission, firstTime);
  }

  getTsheetList(navigatedWeek: any, callInvoiceSubmission?: any, firstTime?: any) {
    const position = this.getPositionFromRole(this.selectedRole?.value);
    if (firstTime) { this.allowedDays = []; }
    let payload: any = { role_id: this.selectedRole?.value, project_id: position.project._id };
    if (navigatedWeek && navigatedWeek.startDate) {
      payload.dateRange = navigatedWeek;
      payload.inclusive = true;
      payload.position = this.selectedPosition ? this.selectedPosition._id.toString() : this.lastPosition._id.toString();
      payload.weekId = moment(navigatedWeek.startDate).add(2, 'days').isoWeek();
    }
    this.timeKeepingService.getTsheets(payload).subscribe((res: any) => {
      if (this.contractorTimekeepingEnabled) {
        this.invoiceSubmittedOn = res.invoiceSubmittedOn ? moment(res.invoiceSubmittedOn).format("MM/DD/YYYY hh:mm:ss a") : "";
        this.lastInvoice = res.lastInvoice;
        this.tsheets = res.list;
        if (this.lastInvoice && this.lastInvoice.timesheets && this.lastInvoice.timesheets.length) {
          this.tsheets = this.combineAndUnique(res.list,this.lastInvoice.timesheets,'_id');
        }
        this.weekTsheets = this.calcWeekDuration(this.tsheets);
        this.calcTotalDuration(this.tsheets);
        this.totalDuration = res.stats.totalHoursOnProject;
        if (firstTime) {
          res.positionsDate.map((position: any) => {
            const data = this.getDateRangeArray({ filledAt: position.filledAt, finishedAt: position.finishedAt || new Date() })
            this.allowedDays = this.allowedDays.concat(data);
            return true;
          });
          this.allowedDays = this.sortAllowedDays(this.allowedDays);
        }
        if (this.allowedDays.length) {
          if (!this.checkIfWeekValid(this.navigatedWeek)) {
            this.getPreviousWeek();
          }
        }
      }

      this.calculateTSheets(res);

      if (!this.findIfOnTheClock(res.list)) {
        this.disableTick();
      }
      if (callInvoiceSubmission) {
        this.showResubmissionInvoiceDialog('You made changes to your time records for the week that you already submitted an invoice. Would you like to submit a new invoice now?');
      }
    })

  }

  calculateTSheets(res: any){
    if (!this.contractorTimekeepingEnabled) {
      this.weekTsheets = this.calcWeekDuration(res.week);
      this.tsheets = this.calcTotalDuration(res.list);
      this.totalDuration = res.stats.totalHoursOnProject;
    }

    this.dailyTsheets = this.calcDailyDuration(res.daily);
  }

  combineAndUnique (array1:any, array2:any, specificKey:string){
    const combined = [...array1, ...array2];
  
    const uniqueSet = new Set();
    const result = [];
  
    for (const item of combined) {
      // Create a unique key excluding the specified property
      const uniqueKey = JSON.stringify(
        Object.keys(item).reduce((acc:any, key) => {
          if (key === specificKey) {
            acc[key] = item[key];
          }
          return acc;
        }, {})
      );
  
      if (!uniqueSet.has(uniqueKey)) {
        uniqueSet.add(uniqueKey);
        result.push(item);
      }
    }
  
    return result;
  };

  disableTick() {
    this.stopTimer();
  }
  findIfOnTheClock(checkIns: any) {
    return checkIns.find((checkin: any) => {
      return checkin.onTheClock;
    });
  }
  showResubmissionInvoiceDialog(msg: string) {
    if (this.lastInvoice && this.lastInvoice.totalWeekHours > 0 && !this.hoursUpToDate()) {
      this.showConfirmInvoiceSendDialog(msg);
    }
  }
  showConfirmInvoiceSendDialog(isMakingChange: string) {
    return isMakingChange;
  }
  hoursUpToDate() {
    if (this.lastInvoice && !this.lastInvoice.totalWeekHours) {
      return false;
    }

    if (this.lastInvoice && this.lastInvoice.totalWeekHours) {
      return (Number(this.timeKeepingService.hoursDecimal(this.weekSecs)) === this.lastInvoice.totalWeekHours);
    }
    return true;
  }

  calcDailyDuration(tsheets: any) {
    this.isClockedIn = false;
    this.clockedInTimeSheet = null;
    this.dailySecs = 0;
    tsheets = tsheets || [];
    tsheets.forEach((tsheet: any) => {
      if (tsheet.onTheClock) {
        this.isClockedIn = true;
        this.clockedInTimeSheet = tsheet.id;
        this.enableTick();
      }
      this.dailySecs += tsheet.duration;
    });
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
    return tsheets;
  }

  getPreviousWeek() {
    const startDateMoment = moment(this.navigatedWeek.startDate);
    const endDateMoment = moment(this.navigatedWeek.endDate);

    // Subtract 7 days
    const week = {
      startDate: startDateMoment.subtract(7, 'days'),
      endDate: endDateMoment.subtract(7, 'days')
    };
    setTimeout(() => {
      const limitReached = endDateMoment.isBefore(moment(this.allowedDays[0]));
      if (!this.checkIfWeekValid(week) && !limitReached) {
        this.getPreviousWeek();
      }
      else if (limitReached) {
        this.getNextWeek();
      }
      else {
        this.getTsheetList(week);
      }
      return week;
    }, 0);
  }


  getNextWeek() {
    const startDateMoment = moment(this.navigatedWeek.startDate);
    const endDateMoment = moment(this.navigatedWeek.endDate);

    // Add 7 days
    const week = {
      startDate: startDateMoment.add(7, 'days'),
      endDate: endDateMoment.add(7, 'days'),
    };
    setTimeout(() => {
      const limitReached = startDateMoment.isAfter(moment(this.allowedDays[this.allowedDays.length - 1]));

      if (!this.checkIfWeekValid(week) && !limitReached) {
        this.getNextWeek();
      }
      else if (limitReached) {
        this.getPreviousWeek();
      }
      else {
        this.getTsheetList(week);
      }
      return week;
    }, 0);
  }
  checkIfWeekValid(week: any) {
    let allowWeek = false;
    this.allowedDays.forEach((date: moment.MomentInput) => {
      if (moment(date).isSameOrAfter(week.startDate) && moment(date).isSameOrBefore(week.endDate)) {
        allowWeek = true;
      }
    });
    return allowWeek;
  }
  calcWeekDuration(tsheets: any) {
    this.weekSecs = 0;
    this.weekHours = 0;
    tsheets = tsheets || [];
    tsheets.map((tsheet: any) => {
      if (tsheet.onTheClock) {
        this.clockedInTimeSheet = tsheet.id;
      }
      this.weekSecs += tsheet.duration;
      this.weekHours += Number((tsheet.duration / 3600).toFixed(2));
    })
    this.weekDuration = this.timeKeepingService.secondsToHMS(this.weekSecs);
    return tsheets;
  }
  calcTotalDuration(tsheets: any) {
    this.totalSecs = 0;
    tsheets = tsheets || [];
    this.isClockedIn = false;
    this.clockedInTimeSheet = null;
    tsheets.map((tsheet: any) => {
      if (tsheet.onTheClock) {
        this.enableTick();
        this.isClockedIn = true;
        this.clockedInTimeSheet = tsheet.id;
      }
      this.totalSecs += tsheet.duration;
    })

    return tsheets;
  }
  enableTick() {
    this.startStopwatch();
    this.updateTime();

  }
  getDateRangeArray(range: any) {
    let dates = [];
    let start = moment(range.filledAt).startOf('day'), end = moment(range.finishedAt).startOf('day');
    do {
      dates.push(start.format());
      start.add(1, 'day');
    }
    while (!start.isSameOrAfter(end));

    dates.push(end.format());
    return dates;
  }
  updateTime() {
    this.dailySecs++;
    this.weekSecs++;
    this.totalSecs++;
    this.dailyDuration = this.timeKeepingService.secondsToHMS(this.dailySecs);
    this.weekDuration = this.timeKeepingService.secondsToHMS(this.weekSecs);
    this.totalDuration = this.timeKeepingService.secondsToHMS(this.totalSecs);
  }
  sortAllowedDays(days: any) {
    return days.sort((left: any, right: any) => {
      return moment(left).diff(moment(right));
    });
  }

  clockIn() {
    this.timeKeepingService.addTSheet({ role_id: this.selectedRole.value }).subscribe((res: any) => {
      this.tsheets.push(res);
      this.isClockedIn = true;
      this.clockedInTimeSheet = res.id;
      this.enableTick();
    })
  }

  clockOut() {
    this.timeKeepingService.updateTSheet({ id: this.clockedInTimeSheet, clock_out: true, role_id: this.selectedRole.value, timezone: moment().format('Z') })
      .subscribe((res: any) => {
        this.disableTick();
        this.isClockedIn = false;
        this.clockedInTimeSheet = null;
        this.getPositions();
        this.toast.showSuccess('You hours have been tracked successfully.');
      })
  }

  getPreviousWeekList() {
    this.slider(false);
    this.getTsheetList(this.navigatedWeek);
  }

  getNextWeekList() {
    if(this.ifWeekLimitNotReach()){
    this.slider(true);
    this.getTsheetList(this.navigatedWeek);
    }
  }

  ifWeekLimitNotReach() {
    let startDate = this.navigatedWeek.startDate.startOf('day');
    let currentStartDate = this.currentWeek.startDate.startOf('day');
    return !startDate.isSameOrAfter(currentStartDate); 
  }

  setCurrentWeek(){
    this.navigatedWeek = { ...this.currentWeek};
    this.getTsheetList(this.navigatedWeek);
  }

  slider(isNext: boolean) {
    const startDateMoment = moment(this.navigatedWeek.startDate);
    const endDateMoment = moment(this.navigatedWeek.endDate);
    let week;
    if (isNext) {
      // Add 7 days
      week = {
        startDate: startDateMoment.add(7, 'days'),
        endDate: endDateMoment.add(7, 'days'),
      };
    } else {
      week = {
        startDate: startDateMoment.subtract(7, 'days'),
        endDate: endDateMoment.subtract(7, 'days')
      };
    }

    this.navigatedWeek.startDate = week.startDate;
    this.navigatedWeek.endDate = week.endDate;
  }

  day(date: Date) {
    return moment(date).format('ddd');
  }

  dateTime(date: Date) {
    if (!date) {
      return 'Working';
    }
    return moment(date).format('MMM DD, h:mm a');
  }
  time(startDate: Date) {
    return moment(startDate).format('h:mm a');
  }
  date(date: any) {
    return moment(date).format('MMM DD');
  }
  slimNotes(notes: string) {
    if (notes && notes.length > 20) {
      return notes.substring(0, 20) + '...';
    }
    return notes;
  }
  secondsToDecimal(seconds: any) {
    if (seconds > 0) {
      return (seconds / 3600).toFixed(2);
    }
    return seconds;
  }
  submitInvoice($event: Event) {
    if (!this.weekTsheets.length) {
      this.toast.showError('No time recorded for invoice.');
    } else {
      const mesg = `Are you sure you want to send weekly invoice from ${this.date(this.navigatedWeek.startDate)} to ${this.date(this.navigatedWeek.endDate)}?`
      this.confirmationDialogService.confirm({
        target: $event.target as EventTarget,
        message: mesg,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          const payload = {
            weekStartDate: this.navigatedWeek.startDate,
            weekEndDate: this.navigatedWeek.endDate,
            positionId: this.getPositionFromRole(this.selectedRole.value)._id.toString(),
            roleId: this.selectedRole.value,
            projectId: this.getPositionFromRole(this.selectedRole.value).project._id,
            timezone: moment.tz.guess(),
            totalWeekHours: this.weekHours,
            timesheets: this.tsheets.map((tsheet: any) => { return tsheet._id; }),
            weekId: moment(this.navigatedWeek.startDate).add(2, 'days').isoWeek()
          };
          this.timeKeepingService.sendInvoice(payload).subscribe((resp: any) => {
            this.getTsheetList(this.navigatedWeek);
            this.toast.showSuccess('Invoice Submitted Successfully');
          })
        }
      });
    }
  }

  approveTime($event: Event) {
    if (!this.weekTsheets.length) {
      this.toast.showError('No time recorded for invoice.');
    } else {
      const mesg = `Clicking yes will lock your times and submit an invoice. Changes are not possible beyond this point. Are you sure all your time records in the current and previous weeks are accurate?`
      this.confirmationDialogService.confirm({
        target: $event.target as EventTarget,
        message: mesg,
        header: 'Are Your Times Accurate?',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          const payload = {
            weekStartDate: this.navigatedWeek.startDate,
            weekEndDate: this.navigatedWeek.endDate,
            positionId: this.getPositionFromRole(this.selectedRole.value)._id.toString(),
            roleId: this.selectedRole.value,
            projectId: this.getPositionFromRole(this.selectedRole.value).project._id,
            timezone: moment.tz.guess(),
            totalWeekHours: this.weekHours,
            timesheets: this.tsheets.map((tsheet: any) => { return tsheet._id; }),
            weekId: moment(this.navigatedWeek.startDate).add(2, 'days').isoWeek()
          };
          this.timeKeepingService.sendInvoice(payload).subscribe((resp: any) => {
            this.getTsheetList(this.navigatedWeek);
            this.toast.showSuccess('Time approved Successfully');
          })
        }
      });
    }
  }

  deleteInvoice($event: Event, data: any, index: number) {

    const mesg = `Are you sure you want to delete your time punch from ${this.date(data.start)} at ${this.time(data.start)}`

    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.timeKeepingService.deleteTimekeepingAdmin(data._id).subscribe((resp: any) => {
          this.tsheets.splice(index, 1);
          this.toast.showSuccess('Time punch deleted successfully');
        })
      }
    });
  }
  getInvoiceUrl(invoiceId: string) {
    const position = this.getPositionFromRole(this.selectedRole.value);
    const reviewerId = position.reviewer._id ? position.reviewer._id.toString() : position.reviewer;
    const url = this.documentService.downloadInvoiceUrl(invoiceId, reviewerId);
    window.open(url, '_self');
  }

  editTimeSheet(data: any) {
    this.isTimeSheetEditable = true;
    this.selectedTimeSheet = data;
    this.showTimeSheetEditor = true;
  }

  startStopwatch(): void {
    if(this.stopwatchService.intervalId){
      return
    }   
    this.stopwatchService.startTime();
  }


  startTimer(): void {
    if(this.stopwatchService.intervalId){
      return
    }
    this.updateTime();
  }

  stopTimer(): void {
    if(this.stopwatchService.intervalId === null){
      return
    }
    this.stopwatchService.stopTime();
  }
}
