@if(user && user.firstName){
@if(!showTalentPoolListing){
<div class="grid grid-nogutter">
  <div class="col-12 pb-3 border-bottom-1 border-gray-200 flex justify-content-between align-items-center">
    <div class="avatar flex gap-3">
      <p-avatar image="assets/images/white-logo.png" size="large" shape="circle"></p-avatar>
      <div class="details">
        <h6 class="m-0 text-deep-purple-900 mb-1">{{user.firstName}} {{user.lastName}}</h6>
        <p class="m-0 body1">{{user.profile.immigrationStatus}}</p>
        <p class="m-0 body1">Location: {{user.profile.contact.city}}, {{user.profile.contact.state}} {{user.profile.contact.zipCode}}</p>
      </div>
    </div>
    @if(!isAdminApprovalPending){
      <div class="rating">
        <p-rating [(ngModel)]="user.profile.averageRating" [readonly]="true" [cancel]="false" class="cursor-default" />
        <p-button class=" ml-2" [pTooltip]="user.isFavorite ? 'Remove from favorite': 'Add to favorite'" tooltipPosition="top" (click)="favouriteUser()" [rounded]="true" [text]="true" icon="true">
          <span class="material-symbols-outlined" [class.vibrant-pink]="user.isFavorite" [class.light-grey]="!user.isFavorite">
              bookmark_star
          </span>
        </p-button>
      </div>
    }
  </div>
  <div class="col-12 xl:col-8 pt-3 pr-0 xl:pr-7">
    <div class="grid grid-nogutter">
      <div class="col-12 lg:col-4 xl:col-4 pb-3 px-2">
        <div class="wrap-worked flex border-round-lg justify-content-center gap-2 py-3 align-items-center">
          <span class="h5">{{user.totalProjectsTogetherCount}}</span>
          <p class="m-0 body2">
            Projects Worked Together<br class="hidden xl:block lg:block">            
          </p>
        </div>
      </div>
      <div class="col-12 lg:col-4 xl:col-4 pb-3 pr-2">
        <div class="wrap-worked flex border-round-lg justify-content-center gap-2 py-3 align-items-center">
          <span class="h5">{{user.totalAcceptedOffersCount}}</span>
          <p class="m-0 body2">
            Offers Accepted<br class="hidden xl:block lg:block" />
          </p>
        </div>
      </div>
      <div class="col-12 lg:col-4 xl:col-4 pb-3 pl-2">
        <div class="wrap-worked flex border-round-lg justify-content-center gap-2 py-3 align-items-center">
          <span class="h5">{{user.totalSentOffersCount}}</span>
          <p class="m-0 body2">
            Offers Sent<br class="hidden xl:block lg:block">
          </p>
        </div>
      </div>
      <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Contact info </span>
      </div>
      <div class="col-12 pb-4">
        <div class="wrap-details border-1 p-3 border-gray-200 flex">
          <div class="basic max-w-20rem w-full email-container">
            <span class="head text-sm font-medium m-0">Email</span>
            <p class="m-0 text-base">{{user.email}}</p>
          </div>
          <div class="basic max-w-14rem w-full">
            <span class="head text-sm font-medium m-0">Phone</span>
            <p class="m-0 text-base">{{user.profile.contact.home}}</p>
          </div>
          <div class="basic max-w-14rem w-full">
            <span class="head text-sm font-medium m-0">Location</span>
            <p class="m-0 text-base">{{user.profile.contact.city}}, {{user.profile.contact.state}} {{user.profile.contact.zipCode}}</p>
          </div>
        </div>
      </div>  
      @if(user.profile.educations.length > 0){
        <div class="col-12 pb-3">
          <span class="text-xl font-medium m-0"> Education </span>
        </div>
        <div class="col-12 pb-4">
          @for (education of user.profile.educations; track education){
            <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
              <div class="basic">
                <span class="desc text-sm font-medium m-0">{{education.school.name}}</span>
                <p class="m-0 text-base head">
                  {{education.degree.type}}
                </p>
                <span class="text-sm m-0">{{education.school.startDate | date:'yyyy'}} - {{education.school.endDate |
                  date:'yyyy'}}</span>
              </div>
            </div>
          }
        </div>
      }
      <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Candidate Details </span>
      </div>
      @if(user.profile.searchMarkets.length > 0){
        <div class="col-6 pb-3">
          <span class="text-xl font-medium m-0"> Search Markets </span>
          @if(currentUserisFMOrPM()){
            <p-button class="vert-align-top" size="small" pTooltip="Edit" tooltipPosition="top" (click)="editUserDetails('Search Market')" [rounded]="true" [text]="true" icon="true">
              <span class="material-symbols-outlined">
                  edit
              </span>
            </p-button>
          }
        </div>
      }
      @if(user.profile.jobCategories.length > 0){
        <div class="pl-10 col-6 pb-3">
          <span class="text-xl font-medium m-0"> Job Types </span>
          @if(currentUserisFMOrPM()){
            <p-button class="vert-align-top" size="small" pTooltip="Edit" tooltipPosition="top" (click)="editUserDetails('Job Type')" [rounded]="true" [text]="true" icon="true">
              <span class="material-symbols-outlined">
                  edit
              </span>
            </p-button>  
          }
        </div>
      }
      @if(user.profile.searchMarkets.length > 0){
        <div class="pr-10 col-6 pb-4">
          <div class="data-table">
            <p-table styleClass=""
              [value]="user.profile.searchMarkets" 
              [rows]="5"
              [paginator]="true"
              currentPageReportTemplate=""
              alwaysShowPaginator="false"
              autoLayout="true">
              <ng-template pTemplate="body" let-searchMarket>
              <tr>
                <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
                  <div class="basic">
                    <p class="m-0 text-base head">{{searchMarket.name}}</p>
                  </div>
                </div>
              </tr>
              </ng-template>
            </p-table>
          </div>
        </div>  
      }
      @if(user.profile.jobCategories.length > 0){ 
        <div class="pl-10 col-6 pb-4">
          <div class="data-table">
            <p-table styleClass=""
              [value]="user.profile.jobCategories" 
              [rows]="5"
              [paginator]="true"
              currentPageReportTemplate=""
              alwaysShowPaginator="false"
              autoLayout="true">
              <ng-template pTemplate="body" let-jobCategory>
              <tr>
                <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
                  <div class="basic">
                    <p class="m-0 text-base head">{{jobCategory.name}}</p>
                  </div>
                </div>
              </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      }
      @if(user.profile.credentials.length > 0 || this.reviewerInactiveCredentials.length > 0){
        <div class="col-12 pb-3">
          <span class="text-xl font-medium m-0"> Credentials </span>
          @if(currentUserisFMOrPM()){
            @if(user.profile.credentials.length > 0){
              <p-button class="vert-align-top" size="small" pTooltip="Edit" tooltipPosition="top" (click)="showCredentialsEdit()" [rounded]="true" [text]="true" icon="true">
                <span class="material-symbols-outlined">
                    edit
                </span>
              </p-button>
            }
            <p-button (click)="showRemovedCredentials()" label="View Removed Credentials" [rounded]="true" size="small" class="fl-end" />
          }
        </div>
      }
      @if(user.profile.credentials.length > 0){
        <div class="col-12 pb-4">
          @for (cred of user.profile.credentials; track cred){
            <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
              <div class="basic">
                <p class="m-0 text-base head">{{cred.name}}</p>
                <p class="m-0 text-base desc">{{cred.secondaryOptions}}</p>
              </div>
              @if(showEditCredentials){
                <p-button size="small" pTooltip="Deactivate" tooltipPosition="top" (click)="editCredentials(cred.certificate)" [rounded]="true" [text]="true" icon="true">
                  <span class="material-symbols-outlined red">
                      close
                  </span>
                </p-button>  
              }
            </div>
          }
        </div>
      }

      @if(reviewerInactiveCriteria.length || user.profile.criterias.length){
        <div class="col-12 pb-3">
          <span class="text-xl font-medium m-0"> Skills And Experience </span>
          @if(currentUserisFMOrPM()){
            @if(user.profile.criterias.length > 0){
              <p-button class="vert-align-top" size="small" pTooltip="Edit" tooltipPosition="top" (click)="showSkillExperienceEdit()" [rounded]="true" [text]="true" icon="true">
                <span class="material-symbols-outlined">
                    edit
                </span>
              </p-button>
            }
            @if(reviewerInactiveCriteria.length){
              <p-button (click)="showRemovedSkills()" label="View Removed Skills" [rounded]="true" size="small" class="fl-end" />
            }
          }
        </div>
      }

      @if(user.profile.criterias.length > 0){
        
        <div class="col-12">
          @for (cred of user.profile.criterias; track cred){
            <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
              <div class="basic">
                <p class="m-0 text-base head">{{cred.criteriaName}}</p>
                <p class="m-0 text-base desc">{{cred.criteriaSelectedOptions}}</p>
              </div>
              @if(showEditSkillExperience){
                <p-button size="small" pTooltip="Deactivate" tooltipPosition="top" (click)="editSkillExperience(cred._id)" [rounded]="true" [text]="true" icon="true">
                  <span class="material-symbols-outlined red">
                      close
                  </span>
                </p-button>  
              }
            </div>
          }
        </div>
      }
      
      @if(user.profile.barAdmissions.length > 0){
        <div class="col-12 pb-3">
          <span class="text-xl font-medium m-0"> Bar Membership </span>
        </div>
        @for (bar of user.profile.barAdmissions; track bar){
          <div class="col-12 pb-4">
            <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
              <div class="basic">
                <span class="head text-sm font-medium m-0">State</span>
                <p class="m-0 text-base">{{bar.skill.name}}</p>
              </div>
              <div class="basic">
                <span class="head text-sm font-medium m-0">Membership</span>
                <p class="m-0 text-base">{{bar.code}}</p>
              </div>
              <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
                {{status(bar)}}
              </span>
            </div>
          </div>
        }
      }

      <!--  <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Training </span>
      </div>
      <div class="col-12 pb-4">
        <div class="wrap-details border-1 p-3 border-gray-200 flex gap-2 flex-wrap">
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            NCSP Foundation Certtificate Training
          </span>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            NIST Framework Awareness
          </span>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            NCSP Practitioner Certtificate Training
          </span>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            NICE Cybersecurity Workforce Specialist
          </span>
        </div>
      </div>
      <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Industries </span>
      </div>
      <div class="col-12 pb-4">
        <div class="wrap-details border-1 p-3 border-gray-200 flex justify-content-between align-items-center">
          <div class="basic">
            <p class="head text-base m-0">Law specialty</p>
            <span class="text-sm m-0 desc">Subtext</span>
          </div>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            Verified
          </span>
        </div>
        <div
          class="wrap-details border-1 border-top-none p-3 border-gray-200 flex justify-content-between align-items-center">
          <div class="basic">
            <p class="head text-base m-0">Law specialty</p>
            <span class="text-sm m-0 desc">Subtext</span>
          </div>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            Verified
          </span>
        </div>
      </div>
      <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Experience </span>
      </div>
      <div class="col-12 pb-4">
        <div class="wrap-details border-1 p-3 border-gray-200 flex gap-2 flex-wrap">
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            Audio Review
          </span>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            Audio Review
          </span>
          <span class="verified px-2 py-1 border-1 border-round-2xl h-fit">
            Confidentiality review
          </span>
        </div>
      </div> 
      <div class="col-12 pb-3">
        <span class="text-xl font-medium m-0"> Contact info </span>
      </div>
      <div class="col-12 pb-4">
        <div class="wrap-details border-1 p-3 border-gray-200">
          <p class="m-0 text-base">English</p>
          <span class="text-sm m-0 desc"> Native </span>
        </div>
      </div>-->
      <div class="col-12 pt-3 pb-3">
        <span class="text-xl font-medium m-0"> Past Projects</span>
      </div>
      <div class="col-12 pr-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="pastProjects" autoLayout="true">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="code">
                Project <p-sortIcon field="code" />
              </th>
              <th>
                Roles
              </th>
              <th>
                Acceptance Date
              </th>
              <th>
                Ended
              </th>
              
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-pastProject>
            <tr>
              <td>{{pastProject.project.codeName}}</td>
              <td>{{pastProject.role.roleType}}</td>
              <td>{{pastProject.updatedAt | date: "MM/dd/yyyy" : 'UTC'}}</td>
              <td>{{pastProject.status}} <span ng-if="proj.finishedAt">On
                  {{pastProject.finishedAt | date: "MM/dd/yyyy" : 'UTC' }}</span></td>
    
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-pastProject>
            <tr>
              <td [attr.colspan]="6" class="text-center">
                No Past Project Available
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12 mt-3 pb-3">
        <span class="text-xl font-medium m-0"> Project Documents</span>
      </div>
      <div class="col-12 pb-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="docuSignDocuments">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="code">
                Project <p-sortIcon field="code" />
              </th>
              <th>
                Roles
              </th>
              <th>
                Template
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-docuSignDocument>
            <tr>
              <td>{{docuSignDocument.project.codeName}}</td>
              <td>{{docuSignDocument.role.roleType}}</td>
              <td>
                <ul>
                  @for(template of docuSignDocument.templates; track template){
                  <li>
    
                    <span> {{template.name}} </span>
                    <ul>
                      @for(doc of template.documents; track doc){
                      <li>
                        <span> {{doc.name}} </span>
                        <span (click)="openDocuSignDocument(docuSignDocument.envelopeId, doc.documentId, doc.name)"
                          class="cursor-pointermb-2 material-symbols-outlined">
                          download
                        </span>
                      </li>
                      }
                    </ul>
                  </li>
                  }
    
                </ul>
    
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-docuSignDocument>
            <tr>
              <td [attr.colspan]="6" class="text-center">
                No Project Documents Available
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="col-12 xl:col-4 pt-3">
    @if(!isAdminApprovalPending){
      <div class="card border-round-sm py-3 px-5 shadow-3 w-full mb-4">
        <h6 class="mt-0 mb-3">Talent Pools Assigned</h6>
        @for (tp of talentPoolAssigned; track tp){
        <div class="data mb-3">
          <p class="m-0 desc">
            Name: {{tp.name}}
          </p>
          <p class="m-0 desc">
            Description: {{tp.description}}
          <p class="m-0 desc">
            Firm: {{tp.firm}}
          </p>
        </div>
        }
  
        <p-button (onClick)="showTalentPool()" label="ADD TO TALENT POOL" [rounded]="true" icon="pi pi-plus" size="large" styleClass="vibrant-pink-background" class="mt-3" />
      </div>
    }
    @if(user.documents && user.documents.length){
      <div class="card border-round-sm py-3 px-5 shadow-3 w-full mb-4">
        <h6 class="mt-0 mb-2">Documents</h6>
        <div class="data mt-1">
          <ul class="list-none my-3 flex flex-column row-gap-2">
            @for (document of user.documents; track document){
              <li><a class="desc break-word merge-link" target="_self" (click)="getDocumentPathById(document._id)" >{{document.title}}</a></li>
            }
          </ul>
        </div>
      </div>
    }
    @if(!isAdminApprovalPending){

      <div class="card border-round-sm py-3 px-5 shadow-3 w-full mb-4">
        <h6 class="mt-0 mb-2">Firm Uploaded Documents</h6>
        <div class="data mt-1">
          <ul class="list-none my-3 flex flex-column row-gap-2">
            @for (document of firmReviewerDocuments; track document){
            @if(document.url){
            <li>
              <a class="desc break-word" target="_self" [href]="document.url">{{document.title}} </a>
              <input type="file" #editDocu style="display: none"
                (change)="editDocument($event, 'FIRM_REVIEWER_DOCUMENT',document)">
              <span (click)="editDocu.click()" class="material-symbols-outlined cursor-pointer">
                edit
              </span>
              <span (click)="deleteDocment(document._id,false)" class="red material-symbols-outlined cursor-pointer">
                delete
              </span>
            </li>
            }
  
            }
          </ul>
          <input type="file" #documents style="display: none"
            (change)="onUploadDocument($event, 'FIRM_REVIEWER_DOCUMENT')">
          <p-button (click)="documents.click()" label="ADD ADDITIONAL DOCUMENT" [rounded]="true" icon="pi pi-plus"
            size="large" class="flex justify-content-center" />
        </div>
      </div>
      <div class="card border-round-sm py-3 px-5 shadow-3 w-full mb-4">
        <h6 class="mt-0 mb-2">Database Status</h6>
        <div class="data mt-1">
          <span>You can deactivate a candidate at any time. Inactive candidates do not count toward your total candidate allotment</span>
        </div>
        <p-inputSwitch  [(ngModel)]="user.status" (ngModelChange)="onStatusToggleSwitch($event,user)" class="mpl-switch right-align" inputId="stfProject" ariaLabel="My Projects" />
      </div>
    }
    @if(!isAdminApprovalPending){
      <div class="card border-round-sm py-3 px-5 shadow-3 w-full mb-4">
        <h6 class="mt-0 mb-2">Notes</h6>
        <textarea [(ngModel)]="newNote" [maxlength]="maxLength" class="h-auto" rows="5" cols="30"
          placeholder="Write Message..." pInputTextarea>
            </textarea>
        <p-button label="ADD NOTE" [disabled]="!newNote" (onClick)="addNote()" [rounded]="true" size="large"
          class="flex justify-content-center" />
  
        @for (note of user.notes; track note){
        <div class="notes p-3 mt-1 border-round-sm">
          <span class="head text-xs block font-bold">Posted by: </span>
          <span class="head text-xs block font-bold">{{note.createdBy.firstName}} {{note.createdBy.lastName}} on
            {{note.createdAt | date :'mediumDate' }}</span>
          <p class="m-0 text-base break-word">{{ note.message}}</p>
        </div>
        }
      </div>
    }
  </div>
 
  
 <!--  <div class="col-12 p-0">
    <p-button label="GENERATE RESUME" [rounded]="true" size="large" />
  </div> -->
</div>
}
@if(showTalentPoolListing){

<app-add-to-talent-pool-list (back)="showTalentPool()" [firmId]="currentUser.firm._id"
  [reviewerId]="config.data.reviewerId"></app-add-to-talent-pool-list>
}
}
