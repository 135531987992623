
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { constants, UserRoles } from '../constants/constants';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { ReviewerService } from './reviewer.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
 

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private userService: UserService,
    private reviewerService: ReviewerService,
 //    private _firebase: firebase,
    private storageService: StorageService
  ) {
    this.initFirebase();
  }

  initFirebase() {
    this.authenticateUser();
  }

  authenticateUser(token?: string | null): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged(user => {
        if (!user) {
          token = token || this.cookieService.get('firebaseToken');
          if (token) {
            this.afAuth.signInWithCustomToken(token)
              .then(() => {
                token = null;
                resolve();
              })
              .catch(error => {
                console.error(error.code, error.message);
                reject();
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    });
  }

  logoutFromFirebase(): Promise<void> {
    return this.afAuth.signOut();
  }

  createDisscussion(channelObj: any): string {
    const discussionId = `${channelObj.projectId}:${channelObj.roleId}:${channelObj.reviewerId}`;
    const discussionKey = this.db.createPushId();
    const discussionRef = this.db.object(`${constants.firebaseSchemas.discussions}/${discussionKey}`);
    discussionRef.set({
      discusstionId: discussionKey,
      Id: discussionId,
      firmFilterKey: `${discussionId}:${this.userService.getSessionUser()._id}`,
      firmUnReadMessageKey: `${channelObj.firm}:${this.userService.getSessionUser()._id}`,
      reviewerUnReadMessageKey: `${channelObj.firm}:${channelObj.reviewerId}`,
      subject: channelObj.subject,
      firmId: channelObj.firm,
      firmManagerId: this.userService.getSessionUser().firm.firmManagers[0],
      projectId: channelObj.projectId,
      roleId: channelObj.roleId,
      reviewerId: channelObj.reviewerId,
      pmUnReadMessageCount: channelObj.pmUnReadMessageCount,
      reviewerUnreadMessageCount: channelObj.reviewerUnreadMessageCount,
      createdBy: channelObj.createdBy,
      createdDate: channelObj.createdDate,
      isDeleted: false,
      lastMessage: channelObj.lastMessage,
      lastMessageDate: channelObj.lastMessageDate,
      lastMessageSender: channelObj.lastMessageSender,
      lastViewedByReviewer: null,
      lastViewedByFirm: channelObj.lastMessageDate
    });
    return discussionKey;
  }

  findDiscussionOrCreateMessages(messageObj: any): void {
    const messageRef = this.db.list(`${constants.firebaseSchemas.threads}/${messageObj.disscussionId}`);
    messageRef.push({
      content: messageObj.content,
      msgDate: messageObj.msgDate,
      sender: messageObj.sender,
      senderName: `${this.userService.getSessionUser().firstName} ${this.userService.getSessionUser().lastName}`,
      from: messageObj.from,
      to: messageObj.to,
      isRead: false,
      readAt: null,
      isDeleted: false,
      isEdit: false,
      firmId: messageObj.firm,
      projectId: messageObj.projectId,
      roleId: messageObj.roleId
    });
    const updateDiscussionObj:{
      lastMessage: string,
      lastMessageDate: string,
      lastMessageSender: string,
      firmManagerId?: string
    } = {
      lastMessage: messageObj.content,
      lastMessageDate: messageObj.msgDate,
      lastMessageSender: messageObj.sender
    };
    if (this.isNotReviewer()) {
      updateDiscussionObj['firmManagerId'] = this.userService.getSessionUser().firm.firmManagers[0];
    }
    this.db.object(`${constants.firebaseSchemas.discussions}/${messageObj.disscussionId}`).update(updateDiscussionObj);
  }

  updateDiscussionOrMessage(updateObj: any, disscussionId: string, msgId: string, isUpdateDiscussion: boolean): void {
    this.db.object(`${constants.firebaseSchemas.threads}/${disscussionId}/${msgId}`).update(updateObj);
    if (isUpdateDiscussion) {
      let updateDiscussionObj:{
        lastMessage?: string,
        lastMessageDate?: string,
        lastMessageSender?: string,
        firmManagerId?: string
      } = {
        lastMessage: updateObj.content
      };
      if (this.isNotReviewer()) {
        updateDiscussionObj['firmManagerId'] = this.userService.getSessionUser().firm.firmManagers[0];
      }
      this.db.object(`${constants.firebaseSchemas.discussions}/${disscussionId}`).update(updateDiscussionObj);
    }
  }

  findDiscussionOrUpdateMessages(msgId: string, messageObj: any, isUpdateDiscussion: boolean): void {
    this.updateDiscussionOrMessage({
      content: messageObj.content,
      isEdit: true,
      lastEditedOn: this.getFirebaseServerTimeStamp()
    }, messageObj.disscussionId, msgId, isUpdateDiscussion);
  }

  findDiscussionOrDeleteMessages(msgId: string, messageObj: any, isUpdateDiscussion: boolean): void {
    this.updateDiscussionOrMessage({
      oldContent: messageObj.content,
      content: constants.messages.MESSAGE_DELETED,
      isDeleted: true,
      messageDeletedOn: this.getFirebaseServerTimeStamp()
    }, messageObj.discussionId, msgId, isUpdateDiscussion);
  }

  updateReviewerLastReadTimeStampInDiscussion(discussionId: any): void {
    const updatedObj = {
      reviewerLastReadTimeStamp: this.getFirebaseServerTimeStamp()
    };
    this.db.object(`${constants.firebaseSchemas.discussions}/${discussionId}`).update(updatedObj);
  }

  updateLastViewFirm (discussionId: string): void {
    const viewKey = this.db.createPushId();
    const lastViewRef = this.db.object(`${constants.firebaseSchemas.userLastView}/${viewKey}`);
    lastViewRef.set({
      discussionId: discussionId,
      filterKey: `${discussionId}:${this.userService.getSessionUser()._id}`,
      viewer: 'FIRM',
      lastViewed: this.getFirebaseServerTimeStamp()
    });
  }

  updateLastViewReviewer(discussionId: string): void {
    const viewKey = this.db.createPushId();
    const lastViewRef = this.db.object(`${constants.firebaseSchemas.userLastView}/${viewKey}`);
    lastViewRef.set({
      discussionId: discussionId,
      filterKey: `${discussionId}:${this.userService.getSessionUser()._id}`,
      viewer: 'REVIEWER',
      lastViewed: this.getFirebaseServerTimeStamp()
    });
  }

  getFreelancerDiscussions(projectId: string, roleId: string, reviewerId: string): Observable<any> {
    const discussionKey = `${projectId}:${roleId}:${reviewerId}`;
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild('Id').equalTo(discussionKey)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val()))
    );
  }

  getDiscussionById(discussionId: string): Observable<any> {
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild('discusstionId').equalTo(discussionId)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val())),
      first()
    );
  }

  getFirmDiscussions(projectId: string, roleId: string, reviewerId: string): Observable<any> {
    const sessionUserId = this.userService.getSessionUser()._id;
    const userRole = this.userService.getUserRole();
  
    let value = `${projectId}:${roleId}:${reviewerId}:${sessionUserId}`;
    let key = 'firmFilterKey';
    
    if ([UserRoles.firmManager, UserRoles.projectOwner].includes(userRole)) {
      value = `${projectId}:${roleId}:${reviewerId}`;
      key = 'Id'; 
    }
  
    // First query
    const ref= this.db.list(constants.firebaseSchemas.discussions, ref => 
      ref.orderByChild(key).equalTo(value)
    ).snapshotChanges();

    return ref.pipe(
      map(actions => {
       return actions.map(a => a.payload.val())
      })
    );
  }
  getProjectBroadCastDiscussions(projectId: string, reviewerId: string): Observable<any> {
    const sessionUserId = this.userService.getSessionUser()._id;
    const userRole = this.userService.getUserRole();
    let key = 'firmFilterKey';
    let projectDiscussionKey = `${projectId}:${reviewerId}:${sessionUserId}`;
    
    if ([UserRoles.firmManager, UserRoles.projectOwner, UserRoles.reviewer].includes(userRole)) {
      key = 'Id';
      projectDiscussionKey = `${projectId}:${reviewerId}`;
    }
  
    // First query
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => 
      ref.orderByChild(key).equalTo(projectDiscussionKey)
    ).snapshotChanges();

    return ref.pipe(
      map(actions => {
       return actions.map(a => a.payload.val())
      })
    );
  }

  getProjectDiscussions(projectId: string): Observable<any> {
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild('projectId').equalTo(projectId)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val())),
      first()
    );
  }

  getFirmDiscussionsOnce(projectId: string, roleId: string, reviewerId: string): Observable<any> {
    const discussionKey = `${projectId}:${roleId}:${reviewerId}`;
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild('Id').equalTo(discussionKey)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val())),
      first()
    );
  }

  getThreadsByDiscussion(discussionId: string): Observable<any> {
    const ref = this.db.list(constants.firebaseSchemas.threads, ref => ref.orderByKey().equalTo(discussionId)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val()))
    );
  }

  getFirebaseServerTimeStamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }


  getFirmUnReadMessages(): Observable<any> {
    let key = 'firmUnReadMessageKey';
    let value = `${this.userService.getUserFirmId()}:${this.userService.getSessionUser()._id}`;
    if ([UserRoles.firmManager, UserRoles.projectOwner].includes(this.cookieService.get('role'))) {
      key = 'firmId';
      value = this.userService.getUserFirmId();
    }
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild(key).equalTo(value)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val()))
    );
  }


  
  getReviewerUnReadMessages(): Observable<any> {
    let key = 'reviewerUnReadMessageKey';
    let tenant = this.storageService.getTenant().firm._id;
    let userId = this.reviewerService.getReviewer()._id;
    let value =  tenant+ ':' + userId;
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild(key).equalTo(value)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val()))
    );
  }


  getFilteredDiscussions(filterParam: string, filterValue: string): Observable<any> {
    const ref = this.db.list(constants.firebaseSchemas.discussions, ref => ref.orderByChild(filterParam).equalTo(filterValue)).snapshotChanges();
    return ref.pipe(
      map(actions => actions.map(a => a.payload.val()))
    );
  }



  private isNotReviewer(): boolean {
    return this.cookieService.get('role') !== UserRoles.reviewer;
  }
}
