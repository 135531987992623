<div header="Show Removed Skills" class="align-items-center">
    <p>Check any records you’d like to reactive for {{ userName }}: </p>
    <div class="col-12 mb-3 p-0">
        @for (cred of data; track cred){
            <div class="wrap-details flex justify-content-between">
                @if (type === 'Skills') {
                    <div class="basic">
                        <p class="m-0 text-base head">{{cred.criteriaName}}</p>
                    </div>
                    <p-checkbox [binary]="true" (click)="edit(cred._id)"/>
                }
                @else if(type === 'Credentials'){
                    <div class="basic">
                        <p class="m-0 text-base head">{{cred.name}}</p>
                    </div>
                    <p-checkbox [binary]="true" (click)="edit(cred.certificate)"/>
                }
            </div>
          }
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!addedIds.length" label="OK" (click)="confirm()" />
    </div>
</div>