import { Injectable } from '@angular/core';
import {HttpBaseService} from '../../../../shared/services/http-base.service';
import { HttpParams } from '@angular/common/http';
import { InvoiceReportPayload, PayrollFilterPayload, ProjectPayload, ReviewersPayload, RolesPayload } from './interfaces/reports.interface';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpBaseService
  ) { }

  public getProjectsByFirmAndMarkets(firmIds: string, marketIds: Array<string> = [],status: Array<string> = []): Promise<Array<ProjectPayload>> {
    let params = new HttpParams()
      .set('firms', firmIds)
      .set('marketIds', marketIds.join(','))
      .set('status', status.join(','))
      .set('noCache', new Date().getTime());
      
    return lastValueFrom(this.http.query('get','projects/codeNamesByMarketAndFirm', null, {params}));
  }

  public getRolesByProjectIds(projectIds: Array<string>): Promise<Array<RolesPayload>> {
    let params = new HttpParams()
      .set('projectIds', projectIds.join(','))
      .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.query('get','projects/roles', null, {params}));
  }

  public getProjectReviewers(projectIds: Array<string>, roleIds: Array<string>, firmId: string, startDate: string, endDate: string): Promise<Array<ReviewersPayload>>{
    
    let params = new HttpParams()
    .set('projectIds', projectIds.join(','))
    .set('role', roleIds.length ? roleIds.join(',') : '')
    .set('firm', firmId)
    .set('startDate', startDate)
    .set('endDate', endDate)
    .set('noCache', new Date().getTime());
    return lastValueFrom(this.http.query('get','projects/getProjectReviewers', null, {params}));
  }

  generatePayrollReport(filters: PayrollFilterPayload | InvoiceReportPayload): Observable<Array<any>>{
    return this.http.post('projects/generatePayroll', filters);
  }
  
  generateReviewersReport(filters: any): Observable<any>{
    return this.http.post(`firms/${filters.firmId}/getAllActiveReviewersByFirm`, filters);
  }

  getSelectedProjectReviewers(projectIds: Array<string>, roleIds: Array<string>, firmId: string, startDate: string, endDate: string): Promise<Array<ReviewersPayload>>{
    let payload ={
      projectIds:  projectIds.join(','),
      roles: roleIds.length ? roleIds.join(',') : '',
      firm: firmId,
      startDate: startDate,
      endDate: endDate
    }
    return lastValueFrom(this.http.post('projects/getProjectReviewers', payload));
  }

  public getAllRolesByProjectIds(projectIds: Array<string>): Promise<Array<RolesPayload>> {
    const payload = {
      projectIds:  projectIds.join(','),
    }
    return lastValueFrom(this.http.post('projects/roles', payload ));
  }
}
