// message.component.ts
import { Component, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { FormsModule } from '@angular/forms';
import { TreeModule } from 'primeng/tree';
import { CommonModule, DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanel, ScrollPanelModule } from 'primeng/scrollpanel';
import { FloatLabelModule } from "primeng/floatlabel"
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { ChipsModule } from 'primeng/chips';
import { FirebaseMessagingService } from '../../../../shared/services/firebase-messaging.service';
import { AngularFireModule } from '@angular/fire/compat';
import { ProjectService } from '../../../../shared/services/project.service';
import { UserService } from '../../../../shared/services/user.service';
import { map, combineLatest ,BehaviorSubject, Subscription} from 'rxjs';
import { MessageCreateDialogData, MessagingCountHash, ProjectItem, ReviewerItem, RoleItem } from './message.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserClientVm } from '../../../../store/user/user.interface';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CreateDiscussionComponent } from './modals/create-discussion/create-discussion.component';
import { ToastService } from '../../../../shared/services/toast.service';
import { constants } from '../../../../shared/constants/constants';
import { distinctUntilChanged } from 'rxjs/operators';
import { MessagesService } from '../../../../shared/services/messages-service.service';
import { TooltipModule } from 'primeng/tooltip';
import { AddTemplateComponent } from './modals/add-template/add-template.component';
import { ReviewerDetailDialogService } from '../../../../shared/services/reviewer-detail-dialog.service';
import { MessageComponentService } from './message.component.service';
import { DocumentService } from '../../../../shared/services/document-service';
import { EmittersService } from '../../../../shared/services/emitters.service';
import { DividerModule } from 'primeng/divider';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    InputSwitchModule, 
    FormsModule, 
    IconFieldModule, 
    InputIconModule, 
    InputTextModule, 
    TreeModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextareaModule,
    ScrollPanelModule,
    FloatLabelModule,
    DialogModule,
    ChipsModule,
    AngularFireModule,
    ProgressSpinnerModule,
    BadgeModule,
    TooltipModule,
    DividerModule,
    PipesModule
  ],
  providers:[DatePipe],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit, OnDestroy {
  
  user!: UserClientVm;
  ref: DynamicDialogRef | undefined;
  messageObject: any = {};
  isFilterActive: boolean = true;
  originalTreeList = [];
  projectMessageList: any;
  projectMessageUnreadCopyList: any;
  messagesList  = false;
  messageProjects: any = []; 
  selectedDiscussionNodeData: any;
  selectedDiscussionNode: any;
  discussionSubscriber: any = [];
  discussionThreadSubscriber: any =[];
  selectedDiscussion: any;
  discussionThread: any;
  discussions!: any[];
  discussionsView: boolean = false;
  threadView: boolean = false;
  fetchingDiscussions: boolean = false;
  fetchingThread: boolean = false;
  hideProjectsTreeView: boolean = false;
  roleDiscussions: any = [];
  projectBroadcastDiscussions: any = [];
  firmLogo: string | undefined;
  msgSenderReviewer: string = constants.messageType.reviewer;
  messagingCountHash: MessagingCountHash | null = null;
  messageListSearch:string='';
  expandedKeysTree1: { [key: string]: boolean } = {};
  expandedKeysTree2: { [key: string]: boolean } = {};

  varA$ = new BehaviorSubject<number>(0); // Example observable
  varB$ = new BehaviorSubject<number>(0); // Another example observable
  selectedThreadName = '';
  hasReviewerThreadExist: {[id: string]: boolean} = {};
  reviewerLastMessageTimeStamp: {[id: string]: string} = {};
  hasAnyMessage: {[id: string]: boolean} = {};

  @ViewChild('threadDiscussionPanel') threadDiscussionPanel!: ScrollPanel;
  from = '';
  messagePollingExecutionInProgress = false;

  threadSubscription: Array<Subscription> = [];
  
  constructor(private firebaseMessagingService:FirebaseMessagingService,
      private projectService: ProjectService,
      private userService: UserService,
      private dialogService: DialogService,
      private toast: ToastService,
      private messagesService: MessagesService,
      private datePipe: DatePipe,
      private reviewerDetailDialogService: ReviewerDetailDialogService,
      private messageComponentService: MessageComponentService,
      private documentService: DocumentService,
      private emitterService: EmittersService,
      @Optional() private config: DynamicDialogConfig,
     ) { 
      this.from = this.config?.data?.from || '';
     }

  ngOnInit() {
    this.firebaseMessagingService.initFirebase();
    this.user = this.userService.getSessionUser();
    this.observeDiscussionsAndUpdateView();
    this.emitterService.messageCountEmitter.subscribe(async (messagingCount) => {
      this.messagingCountHash = messagingCount;
      this.sortMessagingByRead();
      if(this.messagePollingExecutionInProgress){
        return;
      }
      this.messagePollingExecutionInProgress = true;
      const allMessages = this.getAllMessages();
      await Promise.all(allMessages.map(async (project: any) => {
        await Promise.all(project.children.map(async (role: any) => {
          const reviewers = role.children;
          await this.checkIfReviewersDiscussionExists(project.data._id, role.data._id, reviewers);
        }))
      }));
      this.threadSubscription.forEach((threadSubscription: Subscription) => {
        threadSubscription.unsubscribe();
      });
      this.threadSubscription = [];
      this.messagePollingExecutionInProgress = false;
    });
    if(this.messageComponentService.hasPendingViewData()){
      this.hideProjectsTreeView = true;
      this.initiateOnlyDiscussionsView(this.messageComponentService.getPendingViewData());
    } else{
    this.projectService.getProjectsWithRolesReviewers(
      this.userService.getUserFirmId(),this.userService.getUserRole()).subscribe({
        next:(_projects)=>{
          this.projectMessageList = _projects;
          this.messageProjects = this.filterOccupiedProjects(this.generateProjectTree(this.projectMessageList));
          this.projectMessageUnreadCopyList = this.deepClone(this.messageProjects);
          this.originalTreeList = this.deepClone(this.messageProjects);
          this.sortMessagingByRead();
        }
      })
    }
    this.firmLogo =  this.documentService.getDocumentPathById(this.user.firm.logo || '') || '';
  }
  ngOnDestroy(): void {
    
      this.discussionSubscriber.forEach((s:any)=>s.unsubscribe());
      this.discussionThreadSubscriber.forEach((s:any)=>s.unsubscribe());
      if(this.messageComponentService.hasPendingViewData()){
        this.messageComponentService.clearPendingViewData();
      }
    
  }
  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  markAllMessagesRead(){
    this.messagesService.markMyMessagesRead( this.userService.getUserFirmId()).subscribe(()=>{
      this.toast.showSuccess("Marked Successfully");
    });
  }

  observeDiscussionsAndUpdateView(){
    combineLatest([this.varA$, this.varB$])
    .pipe(      
      distinctUntilChanged(([prevA, prevB], [currA, currB]) => currA === prevA && currB === prevB), 
      map(([a, b]) => a) 
    )
    .subscribe((a) => {
      // Do something when `a` changes
      let _discussions = this.roleDiscussions.concat(this.projectBroadcastDiscussions);
      _discussions = _discussions.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
      this.discussions = _discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
      // Perform any actions or update your view
  });
  }

  findReviewersByLabelInNodes(nodes:any, searchString:string) {
    if(!nodes || !nodes.length){
      return [];
    }
    if(!searchString){
      return nodes;
    }
    const searchLower = searchString.toLowerCase(); // For case-insensitive search
  
    // Recursive function to search within a single node and filter its children
    
    // Apply the search and filter function to each node in the array
    const filteredNodes = this.deepClone(this.sanitizeTreeData(nodes))
      .map((node:any) => this.searchAndFilter(node,searchLower)) // Apply filtering to each root node
      .filter(Boolean); // Remove undefined results
  
    // Return the array of filtered nodes
    return filteredNodes;
  }

  sanitizeTreeData(nodes: any[]): any[] {
    return nodes.map(node => {
        const sanitizedNode = { ...node }; // Create a shallow copy to avoid mutating the original data
        delete sanitizedNode.parent; // Remove the `parent` property
        if (sanitizedNode.children) {
            sanitizedNode.children = this.sanitizeTreeData(sanitizedNode.children);
        }
        return sanitizedNode;
    });
  }

  searchAndFilter(node:any,searchLower:string) {
    // If the node is a reviewer and its label matches, return the node
    if (
      node.node_type === "REVIEWER" &&
      node.label.toLowerCase().includes(searchLower)
    ) {
      return node;
    }

    // If the node has children, recursively filter them
    if (node.children && Array.isArray(node.children)) {
      const filteredChildren = node.children
        .map((child:any) => this.searchAndFilter(child, searchLower)) // Apply search and filter on each child
        .filter(Boolean); // Remove undefined results

      // If there are any matching children, include them in the returned node
      if (filteredChildren.length > 0) {
        node.children = filteredChildren;
        return node;
      }
    }

    // If the node does not match and has no matching children, return undefined
    return undefined;
  }
  getUnreadMessageCountByKey(key: string): number{
    if(!this.messagingCountHash){
      return 0;
    }
    return this.messagingCountHash[key] || 0;
  }

  onNodeClick(node: TreeNode) {
    this.selectedThreadName = '';
    this.discussions = [];
    this.ngOnDestroy();
    this.showDiscussionView();
    this.selectedDiscussionNodeData = node.data;
    this.selectedDiscussionNode = node;
    this.unsubAllDiscussions();
    this.unsubAllDiscussionThreads();
    this.getDiscussions(node.data.projectId,node.data.roleId,node.data._id);
  }

  async checkIfReviewersDiscussionExists(projectId: string, roleId: string, reviewers: Array<any>){
    const reviewersDiscussions = await Promise.all(reviewers.map(async (reviewer) => {
      return await (new Promise((resolve, reject) => {
        const threadSubscription = this.firebaseMessagingService.getFirmDiscussions(projectId, roleId, reviewer.data._id).subscribe(data => {
          const isThreadExist: boolean = !!(data && data.length);
          if(isThreadExist){
            const discussions = data.filter((discussion: any) => discussion.pmUnReadMessageCount > 0).sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate)
            this.hasAnyMessage[`${projectId}_${roleId}_${reviewer.data._id}`] = discussions && discussions.length ? discussions[0].lastMessage : '';
          }
          this.hasReviewerThreadExist[`${projectId}_${roleId}_${reviewer.data._id}`] = isThreadExist;
          const reviewerThread = data.find((res: any) => res.reviewerId === reviewer.data._id && res.projectId === projectId && res.roleId === roleId);
          if(reviewerThread){
            this.reviewerLastMessageTimeStamp[`${projectId}_${roleId}_${reviewer.data._id}`] = reviewerThread.reviewerLastReadTimeStamp || '';
          }
          resolve(isThreadExist);
        })

        this.threadSubscription.push(threadSubscription);
      }));
    }));
    return reviewersDiscussions;
  }

  initiateOnlyDiscussionsView(node:any){
    this.discussions = [];
    this.ngOnDestroy();
    this.showDiscussionView();
    this.selectedDiscussionNodeData = {
      ...node,
      firstName:node.reviewer.firstName,
      lastName:node.reviewer.lastName,
      _id:node.reviewer._id};
      
    this.selectedDiscussionNode = {
    key: node.reviewer._id,
    label: `${node.reviewer.firstName} ${node.reviewer.lastName}`,
    data: {...node.reviewer,...node},
    icon: 'person',
    status:node.reviewer.status,
    node_type:'REVIEWER'};  
    this.unsubAllDiscussions();
    this.unsubAllDiscussionThreads();
    this.getDiscussions(node.projectId,node.roleId,node.reviewerId);
  }

  onActiveFilterToggle(){
    if(this.isFilterActive){
      this.messageProjects = this.filterOccupiedProjects(this.generateProjectTree(this.projectMessageList));
      this.projectMessageUnreadCopyList = this.deepClone(this.messageProjects);
      this.originalTreeList = this.deepClone(this.messageProjects);
      
    }else{
      this.generateProjectTree(this.projectMessageList);
    }

    this.sortMessagingByRead();
    
  } 

  generateProjectTree(projectList:any){
    const projectItems:ProjectItem[] = Object.values(projectList); 
    this.messageProjects.splice(0,this.messageProjects.length);
    projectItems.forEach((project,index)=>{
      this.messageProjects.push(this.messageComponentService.generateProjectNode(project,index));
    })
    return this.messageProjects;
  }

  getAllMessages(): Array<any>{
    const list = this.findReviewersByLabelInNodes(this.messageProjects,this.messageListSearch).slice();
    return list;
  }

  getUnreadMessages(): Array<any>{
    const filteredNodes = this.findReviewersByLabelInNodes(this.projectMessageUnreadCopyList,this.messageListSearch).filter((node: any) => {
      const unreadMessageCount = this.getUnreadMessageCountByKey(node.key);
      return unreadMessageCount > 0; 
    }).map((node: any) => {
      node.children = node.children.map((role: any) => {
        role.children = role.children.filter((reviewer: any) =>  {
          const unreadMessageCount = this.getUnreadMessageCountByKey(reviewer.data.projectId+'_'+reviewer.data.roleId+'_'+reviewer.data._id);
          return unreadMessageCount > 0;
        }).slice();
        return role;
      }).filter((role: any) => !!(role.children && role.children.length));
      return node;
    });
    const list = filteredNodes;
    this.updateExpandedKeys(this.expandedKeysTree2, list);
    return list;
  }

  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  updateExpandedKeys(expandedKeys: { [key: string]: boolean }, nodes: any[]): void {
    nodes.forEach(node => {
        if (expandedKeys[node.key]) {
            expandedKeys[node.key] = true;
        }
        if (node.children) {
            this.updateExpandedKeys(expandedKeys, node.children);
        }
    });
  }

 filterOccupiedProjects(data:any) {
    return data
      .map((project:any) => {
        const filteredRoles = project.children
          .map((role:any) => {
            const filteredReviewers = role.children.filter(
              (reviewer:any) => reviewer.data.status === "OCCUPIED"
            );
  
            return filteredReviewers.length > 0
              ? { ...role, children: filteredReviewers, projectId: project.key }
              : null;
          })
          .filter((role:any) => role !== null);
  
        return filteredRoles.length > 0
          ? { ...project, children: filteredRoles }
          : null;
      })
      .filter((project:any) => project !== null);
  }

  sortMessagingByRead(): void{
    if(this.messagingCountHash && this.messageProjects && this.messageProjects.length){
      this.sortChildNodes(this.messageProjects)
      this.messageProjects.forEach((messageProject: any) => {
        if(messageProject.children && messageProject.children.length){
          this.sortChildNodes(messageProject.children, 'ROLE');
          messageProject.children.forEach((role: any) => {
            if(role && role.children.length){
              this.sortChildNodes(role.children, 'REVIEWER');
            }
          })
        }
      })
    }
  }

  sortChildNodes(list: Array<any>, nodeType = ''): void{

    list.sort((current: any, next: any) => {
      let currentKey = current.key || '';
      let nextKey = next.key || '';
      if(nodeType === 'ROLE'){
        currentKey = `${current.projectId}_${current.key}`;
        nextKey = `${next.projectId}_${next.key}`;
      } else if(nodeType === 'REVIEWER'){
        currentKey = `${current.data.projectId}_${current.data.roleId}_${current.data._id}`;
        nextKey = `${next.data.projectId}_${next.data.roleId}_${next.data._id}`;
      }

      if(this.getUnreadMessageCountByKey(currentKey) < this.getUnreadMessageCountByKey(nextKey)){
        return 1;
      }

      if(this.getUnreadMessageCountByKey(currentKey) > this.getUnreadMessageCountByKey(nextKey)){
        return -1;
      }

      return 0;
    });
  }


  goBackToDiscussionView(){
    this.discussionsView = true;
    this.threadView = false;
    this.selectedThreadName = '';
    this.unsubAllDiscussionThreads();
  }

  showDiscussionView(){
    this.discussionsView = true;
    this.threadView = false;
  }

  showThreadView(){
    this.discussionsView = false;
    this.threadView = true;
  }

  areDiscussionExists(node: any): void{
      const firmDiscussionSusbscription: Subscription = this.firebaseMessagingService.getFirmDiscussions(node.data.projectId,node.data.roleId,node.data._id).subscribe(data => {
        if(!data || !data.length){
          this.createNewThread(node);
        }
        this.onNodeClick(node);
        firmDiscussionSusbscription.unsubscribe();
      })
  }

  getDiscussions(projectId:string,roleId:string,reviewerId:string): void {
    this.fetchingDiscussions = true;
    this.discussionSubscriber.push(this.firebaseMessagingService.getFirmDiscussions(projectId, roleId, reviewerId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr = Object.values(snapshot);
          let discussions = arr.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
          this.roleDiscussions = discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
          this.escalateChangeOnA();
          this.fetchingDiscussions = false;
        }
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      }
    }));
    this.discussionSubscriber.push(this.firebaseMessagingService.getProjectBroadCastDiscussions(projectId, reviewerId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr = Object.values(snapshot);
          
          let discussions = arr.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
          this.projectBroadcastDiscussions = discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
          this.escalateChangeOnB();
        } 
       
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      }
    }));
  }
  unsubAllDiscussions(){
      this.discussionSubscriber.forEach((subscriber:any)=>{
          subscriber.unsubscribe();
      });
      this.discussionSubscriber = [];
  }
  unsubAllDiscussionThreads(){
     
    this.discussionThreadSubscriber.forEach((subscriber:any)=>{
        subscriber.unsubscribe();
    });
    this.discussionThreadSubscriber = [];
  }
  getDiscussionThread(discussion:any) {
    this.showThreadView();
    this.unsubAllDiscussionThreads();
    this.fetchingThread = true;
    this.discussionsView = false;
    this.selectedDiscussion = discussion;
    this.selectedThreadName = discussion.subject;
    this.discussionThreadSubscriber.push(this.firebaseMessagingService.getThreadsByDiscussion(discussion.discusstionId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr:any = Object.values(snapshot)[0];
          this.discussionThread = arr;
          this.firebaseMessagingService.updateLastViewFirm(discussion.discusstionId);
          this.messagesService.markFirmUsersMessagesRead(discussion.projectId, discussion.roleId, discussion.firmId, discussion.discusstionId);
          
        } 
        this.fetchingThread = false;
        this.scrollThreadDiscussionToBottom();
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      },
      complete: ()=>{
       
      }
    }));
  }

  checkIfAnotherFirmUser(message: any): boolean{
    return (message.sender !== constants.userRoles.reviewer && message.from !== message.discussionCreator);
  }

  getSenderName(message: any): string{
    return `Sent by: ${message.senderName}`;
  }

objectKeys(threadObject:any){
    return Object.keys(threadObject);
}


editMessage(threadMessage:any,messageId:string): void {
  this.messageObject.content = threadMessage.content;
  this.messageObject.editMessageId = messageId;
  this.messageObject.messageInput = threadMessage.content;
  if (this.selectedDiscussion) {
    this.messageObject.isUpdateDiscussion = (this.selectedDiscussion.lastMessage === threadMessage.content && this.selectedDiscussion.lastMessageSender === 'FIRM');
  }
}

deleteMessage(threadMessage:any,messageId:string): void {
  this.messageObject.content = threadMessage.content;
  this.messageObject.deleteMessageId = messageId;
  this.messageObject.discussionId = this.selectedDiscussion.discusstionId;
  if (this.selectedDiscussion) {
    this.messageObject.isUpdateDiscussion = (this.selectedDiscussion.lastMessage === threadMessage.content && this.selectedDiscussion.lastMessageSender === 'FIRM');
  }
  if (this.selectedDiscussion) {
    this.firebaseMessagingService.findDiscussionOrDeleteMessages(messageId, this.messageObject, this.messageObject.isUpdateDiscussion);
  }
}
  sendMessage(): void {
    const messageInput = this.messageObject.messageInput.trim();
    if (messageInput) {
      if (!this.messageObject.editMessageId) {
        this.messageObject.msgDate = this.firebaseMessagingService.getFirebaseServerTimeStamp();
        this.messageObject.senderName = `${this.user.firstName} ${this.user.lastName}`;
        this.firebaseMessagingService.findDiscussionOrCreateMessages({
          disscussionId: this.selectedDiscussion.discusstionId,
          content: messageInput,
          sender: 'FIRM',
          from: this.user._id,
          to: this.selectedDiscussionNodeData._id,
          projectId: this.selectedDiscussionNodeData.projectId,
          roleId: this.selectedDiscussionNodeData.roleId,
          firm: this.user.firm._id,
          msgDate: this.messageObject.msgDate
        });
      } else {
        this.firebaseMessagingService.findDiscussionOrUpdateMessages(this.messageObject.editMessageId, {
          disscussionId:  this.selectedDiscussion.discusstionId,
          content: messageInput
        }, this.messageObject.isUpdateDiscussion);
      }
      this.resetMessageInput();
    }
    setTimeout(() => {
      this.messageObject = {
        messageInput: ''
      } 
    }, 1);
  }
  
escalateChangeOnA() {
    this.varA$.next(this.varA$.getValue()+1);
}
escalateChangeOnB() {
  this.varB$.next(this.varB$.getValue()+1);
}  
resetMessageInput(){
  this.messageObject.messageInput = "";
}  

  
openCreateDiscussion(node:any): void {
    if(node.node_type !== 'REVIEWER'){  
      this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
    }else{
      this.firebaseMessagingService.getFirmDiscussionsOnce(node.data.projectId,node.data.roleId,node.data._id)
      .pipe(
        map(res => {
          let firmDiscussionSnapshots = res ? {...res} : null;
      
          if (firmDiscussionSnapshots && Object.keys(firmDiscussionSnapshots).length > 0) {
            this.discussions = [];
            this.unsubAllDiscussionThreads();
            this.unsubAllDiscussions();
            this.showDiscussionView();
            this.selectedDiscussionNodeData = node.data;
            this.getDiscussions(node.data.projectId,node.data.roleId,node.data._id);
            
          } else {
            this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
          }
        })
      )
      .subscribe({
        error: () => {
          this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
        }
      });
    }     
  }

  getReadAt(message:any){
    return message.isRead? "Read at :" + this.datePipe.transform(message.readAt, "hh:mm a MMM dd YYYY"):"";
  }

  getLastMessageTimeStamp(node: any){
    return this.datePipe.transform(this.reviewerLastMessageTimeStamp[node.data.projectId+'_'+node.data.roleId+'_'+node.data._id], "hh:mm a MMM dd YYYY");
  }

  createNewThread(node:any){
    this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node), 'New Message');
  }

  scrollThreadDiscussionToBottom() {
    setTimeout(() => {
      const element = this.threadDiscussionPanel?.contentViewChild?.nativeElement;
      if(element){
        element.scrollTop = element.scrollHeight;
      }
    }, 1000);
  }

  addNewTemplate($event: Event): void{
    $event.stopPropagation();
     this.ref = this.dialogService.open(AddTemplateComponent,
      {
        header: 'Add New Template',
        width: '60vw',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      }
     )
  }
}


