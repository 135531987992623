import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UserService } from '../../../../../shared/services/user.service';
import { SearchMarketService } from '../../../../../shared/services/search-market.service';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
 DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { CreateSearchMarketModalComponent } from './modals/create/create-search-market.component';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { getSessionFirm } from '../../../../../store/user/user.selector';
import { SessionUser } from '../../../../../store/user/user.interface';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-search-markets',
  standalone: true,
  imports: [
    CheckboxModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    HeadingComponent,
    InputTextModule,
    TableModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    CreateSearchMarketModalComponent,
    TooltipModule,
    TagModule
  ],
  templateUrl: './search-markets.component.html',
  styleUrl: './search-markets.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
})
export class SearchMarketComponent implements OnInit {
  searchMarkets: any;
  createForm!: FormGroup;
  ref: DynamicDialogRef | undefined;
  showEditCreate: boolean = false;
  user: any;
  firm: any;
  row: any;
  constructor(
    private userService: UserService,
    private searchMarketService: SearchMarketService,
    public dialogService: DialogService,
    private store: Store<SessionUser>,
    private confirmationDialogService: ConfirmationDialogService,
    private toastService: ToastService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.user = this.userService.getSessionUser();
    this.getFirm();
    this.getFirmSearchMarkets();
  }

  getFirm(){
    this.store.select(getSessionFirm).subscribe((res) => {
      this.firm = res;
      this.showEditCreate = this.firm.isDynamicFirmSettingsEnabled || this.firm.isSingleSearchMarketEnabled;
    });
  }

  getFirmSearchMarkets() {
    this.searchMarketService
      .getFirmSearchMarkets(this.user.firm._id)
      .subscribe({
        next: (searchMarkets) => {
          this.searchMarkets = searchMarkets;
        },
      });
  }

  onArchive($event: Event, searchMarket: any) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message:
        'You are about to ' + (searchMarket.isActive ? 'deactivate ' : 'activate ') + searchMarket.name + '.',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        searchMarket.isActive = !searchMarket.isActive;
        this.searchMarketService
          .updateSearchMarket(searchMarket._id, searchMarket)
          .subscribe({
            next: (searchMarkets) => {
              this.getFirmSearchMarkets();
            },
          });
      },
    });
  }

  onDelete($event: Event, searchMarket: any) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'Are you sure you want to delete ' + searchMarket?.name + '?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.searchMarketService
          .deleteSearchMarket(searchMarket?._id)
          .subscribe({
            next: (searchMarkets) => {
              this.getFirmSearchMarkets();
            },
          });
      },
    });
  }

  showCreateSearchMarketModal($event: Event, searchMarket?: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(CreateSearchMarketModalComponent, {
      header: 'Create',
      width: '30vw',
      contentStyle: { 'overflow': 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        buttonText: 'Create'
      }
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.searchMarketService
          .createSearchMarket({
            ...data,
            isApproved: true,
            firm: this.user.firm._id,
          })
          .subscribe({
            next: (res) => {
              this.getFirmSearchMarkets();
              this.toastService.showSuccess(' Created successfully.');
            },
          });
      }
    });
  }

  showEditSearchMarketModal($event: Event, searchMarket?: any) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(CreateSearchMarketModalComponent, {
      header: 'Edit',
      width: '30vw',
      contentStyle: { 'overflow': 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      
      data: {
        ...searchMarket,
        buttonText: 'Edit'
      }
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.searchMarketService
          .updateSearchMarket(searchMarket._id,{
            ...data,
            isApproved: true,
            firm: this.user.firm._id,
          })
          .subscribe({
            next: (res) => {
              this.getFirmSearchMarkets();
              this.toastService.showSuccess(' Updated successfully.');
            },
          });
      }
    });
  }
}
