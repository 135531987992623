<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h1 class="h4 m-0">Skills and Experience</h1>
  </div>
  <div
    class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center md:gap-4 gap-0"
  >
  @if(firm.isDynamicFirmSettingsEnabled){
    <p-button
      label="CREATE"
      [rounded]="true"
      icon="pi pi-plus"
      size="large"
      (onClick)="goToCreate($event)"
      [ngStyle]="{ 'margin-right': '12px' }"
    />
  }
  </div>
</div>
<dynamic-table
  [title]="dynamicCriteriaTitle"
  [dynamicCriteria]="dynamicCriteria"
  [isEditingEnabled]="firm.isDynamicFirmSettingsEnabled"
  (refreshEvent)="handleRefresh($event)"
></dynamic-table>
