<app-heading [title]="'Past Projects'" [button]="false" [isbreadcrumb]="false" [showToggle]="false"></app-heading>

<div class="card">
    <p-table [value]="pastProjects" dataKey="id" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
    (onRowCollapse)="onRowCollapse($event)" styleClass="p-datatable-sm p-datatable-sm--normal" [paginator]="true" [rows]="10">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">Project Name <p-sortIcon field="name" /></th>
                <th>Role</th>
                <th pSortableColumn="price">End Date <p-sortIcon field="price" /></th>
                <th style="width: 5rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" class="border-0" let-pastProjects let-expanded="expanded">
            <tr>
                <td>{{ pastProjects.codeName }}</td>
                <td>{{ pastProjects.role }}</td>
                <td>{{ pastProjects.endDate | date : 'MM/dd/YYYY' }}</td>
                <td>
                    <p-button [pTooltip]="expanded? 'Close' : 'Expand'" tooltipPosition="top" type="button" 
                    pRipple [pRowToggler]="pastProjects" [text]="true" [rounded]="true"
                        [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                        class="row-toggler" />
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-pastProject>
            <tr>
                <td colspan="7" class="surface-100">
                    <div class="py-3">
                        <div>
                            <div class="flex flex-column gap-3">
                                <div class="grid">
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Project Term</span>
                                        <span class="text-black-alpha-60">{{pastProject.startDate}}-{{pastProject.endDate | date: "MM/yyyy" : 'UTC'}}</span>
                                    </div>
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Rate</span>
                                        <span class="text-black-alpha-60">${{pastProject.hourlyRate}} / {{pastProject.payType === 'salary' ? 'yr' : 'hr'}} (OT
                                            ${{pastProject.overTime}} / hr)</span>
                                    </div>
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Firm</span>
                                        <span class="text-black-alpha-60">{{pastProject.firm}}</span>
                                    </div>
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Regular Hours</span>
                                        <span class="text-black-alpha-60">{{pastProject.regularHours}}</span>
                                    </div>
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Acceptance Date</span>
                                        <span class="text-black-alpha-60">{{pastProject.updatedAt | date: "MM/dd/yyyy" : 'UTC'}}</span>
                                    </div>
                                    <div class="col-6 lg:col-4 flex flex-column">
                                        <span class="text-xs text-primary-900 font-medium">Total Hours Worked On Project</span>
                                        <span class="text-black-alpha-60">{{pastProject.totalHoursOnProject.hours}}:{{pastProject.totalHoursOnProject.minutes}}:{{pastProject.totalHoursOnProject.seconds}}</span>
                                    </div>
                                </div>
                                @if (pastProject.documents.length) {
                                    <h6 class="m-0">Required Documents</h6>
                                    <div class="grid">
                                        @for(doc of pastProject.documents; track doc){
                                            <div class="col-6 lg:col-4 flex gap-2 align-items-center">
                                                <span class="text-primary font-medium">{{doc.docTitle}}</span>
                                                <div class="flex flex-column">
                                                    <span (onClick)="downloadDocument(doc.path)" class="text-sm text-primary-900 font-medium">{{doc.title}}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>    
                                }
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>