import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ReportService } from '../reports.service';
import { StorageService } from '../../../../../shared/services/storage.service';
import { Column, GridInterface, PayrollFilterPayload } from '../interfaces/reports.interface';
import { BaseReport } from '../base-class/base-report.class';
import { MultiSelectModule } from 'primeng/multiselect';
import moment from 'moment';
import { DateTimeService } from '../../../../../shared/services/date-time.service';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';
import { UserService } from '../../../../../shared/services/user.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { ToastService } from '../../../../../shared/services/toast.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { constants } from '../../../../../shared/constants/constants';
import { DialogModule } from 'primeng/dialog';
import { TimesheetEditorComponent } from '../../../../../shared/components/timesheet-editor/timesheet-editor.component';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { TimeKeepingService } from '../../../../../shared/services/time-keeping.service';

@Component({
  selector: 'app-payroll',
  standalone: true,
  imports: [DropdownModule, ButtonModule, CheckboxModule, FormsModule, CalendarModule, FloatLabelModule, ChipModule, IconFieldModule, InputIconModule,InputTextModule, TableModule, CommonModule, MultiSelectModule, OverlayPanelModule, TimesheetEditorComponent, DialogModule],
  providers: [DateTimeService],
  templateUrl: './payroll.component.html',
  styleUrl: './payroll.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PayrollComponent extends BaseReport implements OnInit {
    payrollReports: Array<GridInterface> = [];
    
    showProjectSelectionError = false;
    showTotalDT = false;
    headerOptions = {
        bold: true
    };
    summary = {
        totalProjectHours: 0.00,
        totalProjectOTHours: 0.00,
        totalProjectRegularHours: 0.00
    };
    showTimeSheetEditor: boolean = false;
    isTimeSheetEditable: boolean = false;
    selectedTimeSheet = null;
    selectedRole = {
        name: '',
        value: ''
    };
    role: any = [];
    reviewerName = '';
  constructor(
    override reportService: ReportService,
    override storageService: StorageService,
    override dateTimeService: DateTimeService,
    override userService: UserService,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService,
    private timeKeepingService: TimeKeepingService,
  ){
    super(reportService, storageService, dateTimeService, userService);
  }

  ngOnInit() {
    this.initializeReport();
    this.generateWeekDateAsPerFirm();

  }

  override async onProjectSelectionChange(e: any): Promise<void>{
    await super.onProjectSelectionChange(e);
    this.showProjectSelectionError = false;
  }

  generateReport(): void{
    if(!this.selectedProjects.length){
      this.showProjectSelectionError = true;
      return;
    }
    const filters: PayrollFilterPayload = {
      emails: '',
      firm: this.sessionUser.firm._id,
      fromDate: moment(this.startDate).format('MM/DD/YYYY'),
      toDate: moment(this.endDate).format('MM/DD/YYYY'),
      isFetchAllRecords: false,
      isSentEmail: false,
      project: this.selectedProjects.map(project => project.id),
      selectedProj: [...this.selectedProjects],
      reviewer: (this.selectedReviewers.length ? this.selectedReviewers : this.reviewers).map(reviewer => `${reviewer._id},${reviewer.defaultTimezone}`),
      role: (this.selectedRoles.length ? this.selectedRoles : this.roles).map(role => role.id),
      timezone: this.isDefaultTimeZone ? constants.timezones[0] : this.selectedTimezone?.val || '',
      useDefaultTimezone: this.isDefaultTimeZone
    };

    this.reportService.generatePayrollReport(filters).subscribe({
      next: (payrollReports) => {
        let totalDT = 0;
        let totalProjectHours = 0;
        let totalProjectOTHours = 0;
        let totalProjectRegularHours = 0;
        this.payrollReports = payrollReports;
        this.payrollReports.forEach(payroll => {
          payroll.showDetail = false;
          totalDT += Number(payroll.totalDT);
          totalProjectHours += Number(payroll.totalHours);
          totalProjectOTHours += Number(payroll.totalOT);
          totalProjectRegularHours += Number(payroll.totalRE);
        });
        this.showTotalDT = totalDT > 0;
        this.summary.totalProjectHours = parseFloat(totalProjectHours.toFixed(2));
        this.summary.totalProjectOTHours = parseFloat(totalProjectOTHours.toFixed(2));
        this.summary.totalProjectRegularHours = parseFloat(totalProjectRegularHours.toFixed(2));
      }
    })
  }

  showDetail(payroll: GridInterface): void{
    payroll.showDetail = !payroll.showDetail;
  }

  toHours(timestamp: number = 0): string {

    const hours = Math.floor(timestamp / 60 / 60);

    const minutes = Math.floor(timestamp / 60) - (hours * 60);

    const seconds = timestamp % 60;

    let timestring = '';
    timestring += hours ? hours + 'h ' : '';
    timestring += minutes ? minutes + 'min ' : '';
    timestring += seconds ? seconds + 's' : '';
    return timestring;

  }

  downloadDetailPayroll(payroll: any) {    
    const rows = [];
    const headers = this.getHeaders()
    if (this.showTotalDT) {
        headers.push(this.generateColumn('Total DT Hours', this.headerOptions));
    } else {
        headers.push(this.generateColumn(' ', this.headerOptions));
    }
    rows.push(headers);
    const projectHeading = [
        this.generateProjectHeader(payroll.projectName),
        this.generateColumn(" "),
        this.generateColumn(" "),
        this.generateColumn(" "),
        this.generateColumn(" "),
        this.generateColumn(" "),
        this.generateColumn(" ")
    ];
    rows.push(projectHeading);
    let cols = [
        this.generateColumn(payroll.firstName, { fillColor: '#bdbdbd' }),
        this.generateColumn(payroll.lastName, { fillColor: '#bdbdbd' }),
        this.generateColumn(payroll.roleName, { fillColor: '#bdbdbd' }),
        this.generateColumn(payroll.totalHours, { fillColor: '#bdbdbd' }),
        this.generateColumn(payroll.totalRE, { fillColor: '#bdbdbd' }),
        this.generateColumn(payroll.totalOT, { fillColor: '#bdbdbd' })
    ];
    if (this.showTotalDT) {
        cols.push(this.generateColumn(payroll.totalDT, { fillColor: '#bdbdbd' }));
    } else {
        cols.push(this.generateColumn(" ", { fillColor: '#bdbdbd' }));
    }
    rows.push(cols);

    cols = [
        this.generateColumn('Time In', { fillColor: '#dedede', bold: true }),
        this.generateColumn('Time Out', { fillColor: '#dedede', bold: true }),
        this.generateColumn('Duration', { fillColor: '#dedede', bold: true }),
        this.generateColumn('Notes', { fillColor: '#dedede', bold: true }),
        this.generateColumn('Default Timezone', { fillColor: '#dedede', bold: true }),
        this.generateColumn('', { fillColor: '#dedede', bold: true }),
        this.generateColumn('', { fillColor: '#dedede', bold: true })
    ];

    rows.push(cols);

    Object.keys(payroll.details).forEach((x) => {
        cols = [
            this.generateColumn(x, { fillColor: '#dedede', bold: true }),
            this.generateColumn('', { fillColor: '#dedede' }),
            this.generateColumn('', { fillColor: '#dedede' }),
            this.generateColumn('', { fillColor: '#dedede' }),
            this.generateColumn('', { fillColor: '#dedede' }),
            this.generateColumn('', { fillColor: '#dedede' }),
            this.generateColumn('', { fillColor: '#dedede' })
        ];
        rows.push(cols);

        payroll.details[x].forEach((r: any) => {
            const cols = [
                this.generateColumn(r.start),
                this.generateColumn(r.end),
                this.generateColumn(r.totalDurationInHours),
                this.generateColumn(r.notes || 'N/A'),
                this.generateColumn(this.isDefaultTimeZone? r.timezoneAbbrevation: payroll.payrollTimezone),
                this.generateColumn(''),
                this.generateColumn('')
            ];
            rows.push(cols);
        });
    });
    if (this.payrollReports && this.payrollReports.length) {
        const docDefinition = {
            pageOrientation: 'portrait',
            content: [
                {
                    text: this.sessionUser.firm.name,
                    style: 'header',
                    alignment: 'left',
                    fontSize: 22,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Payroll Report From: ' + moment(this.startDate).format('MM/DD/YYYY') + ' To: ' + moment(this.endDate).format('MM/DD/YYYY') ,
                    style: 'header',
                    alignment: 'left',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        body: rows
                    }
                }
            ]
        } as TDocumentDefinitions;
        pdfMake.createPdf(docDefinition).download(new Date().getTime() + '-payroll-report-from-' +
        moment(this.startDate).format('MM/DD/YYYY') + '-to-' +moment(this.endDate).format('MM/DD/YYYY'));
    }
    else {
        this.toastService.showInfo('No record found');
    }
  }

  getHeaders(): Column[]{
    return [
      this.generateColumn('First Name', this.headerOptions),
      this.generateColumn('Last Name', this.headerOptions),
      this.generateColumn('Role', this.headerOptions),
      this.generateColumn('Total Hours', this.headerOptions),
      this.generateColumn('Total Regular Hours', this.headerOptions),
      this.generateColumn('Total OT Hours', this.headerOptions)
    ];
  }  
  

  loopOverToMake(groupedList: any[], rows: any, roundParamTo: string) {
    groupedList.forEach((reviewer) => {
        let cols = [
            this.generateColumn(reviewer.firstName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.lastName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.roleName, { fillColor: '#bdbdbd' }),
            this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalHours), { fillColor: '#bdbdbd' }),
            this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalRE), { fillColor: '#bdbdbd' }),
            this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalOT), { fillColor: '#bdbdbd' })
        ];
        if (this.showTotalDT) {
            cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalDT), { fillColor: '#bdbdbd' }));
        } else {
            cols.push(this.generateColumn(" ", { fillColor: '#bdbdbd' }));
        }

        rows.push(cols);

        cols = [
            this.generateColumn('Time In', { fillColor: '#dedede', bold: true }),
            this.generateColumn('Time Out', { fillColor: '#dedede', bold: true }),
            this.generateColumn('Duration', { fillColor: '#dedede', bold: true }),
            this.generateColumn('Notes', { fillColor: '#dedede', bold: true }),
            this.generateColumn('Default Timezone', { fillColor: '#dedede', bold: true }),
            this.generateColumn('', { fillColor: '#dedede', bold: true }),
            this.generateColumn('', { fillColor: '#dedede', bold: true })

        ];
        rows.push(cols);

        Object.keys(reviewer.details).forEach((x) => {
            cols = [
                this.generateColumn(x, { fillColor: '#dedede', bold: true }),
                this.generateColumn('', { fillColor: '#dedede' }),
                this.generateColumn('', { fillColor: '#dedede' }),
                this.generateColumn('', { fillColor: '#dedede' }),
                this.generateColumn('', { fillColor: '#dedede' }),
                this.generateColumn('', { fillColor: '#dedede' }),
                this.generateColumn('', { fillColor: '#dedede' })
            ];
            rows.push(cols);

            reviewer.details[x].forEach((r: any) => {
                const cols = [
                    this.generateColumn(r.start),
                    this.generateColumn(r.end),
                    this.generateColumn(r.totalDurationInHours),
                    this.generateColumn(r.notes || 'N/A'),
                    this.generateColumn(this.isDefaultTimeZone? r.timezoneAbbrevation: reviewer.payrollTimezone),
                    this.generateColumn(''),
                    this.generateColumn('')
                ];
                rows.push(cols);
            });
        });
    });
    return rows;
  }

  exportPDF(roundParamTo: string, type: string){
    let rows = [];
    const headers = this.getHeaders();
    if (this.showTotalDT) {
        headers.push(this.generateColumn('Total DT Hours', this.headerOptions));
    } else {
        headers.push(this.generateColumn(' ', this.headerOptions));
    }
    rows.push(headers);
    const projectGroups = this.groupByProject(this.payrollReports,'projectName');
    for (let projectGroupKey in projectGroups) {
      let projectHeading = [
          this.generateProjectHeader(projectGroupKey),
          this.generateColumn(" "),
          this.generateColumn(" "),
          this.generateColumn(" "),
          this.generateColumn(" "),
          this.generateColumn(" ")
      ];
      rows.push(projectHeading);
      const groupedList = projectGroups[projectGroupKey];
      rows = this.loopOverToMake(groupedList, rows, roundParamTo);
  }
  if (this.payrollReports && this.payrollReports.length) {
      const docDefinition = {
          pageOrientation: 'portrait',
          content: [
              {
                  text: this.sessionUser.firm.name,
                  style: 'header',
                  alignment: 'left',
                  fontSize: 22,
                  bold: true
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: 'Payroll Report From: ' +  moment(this.startDate).format('MM/DD/YYYY')  + ' To: ' +  moment(this.endDate).format('MM/DD/YYYY') ,
                  style: 'header',
                  alignment: 'left',
                  fontSize: 16,
                  bold: true
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: 'Total Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectHours),
                  style: 'header',
                  alignment: 'right',
                  fontSize: 16,
                  bold: true
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: 'Total Regular Hours: ' + this.convertDecimalTo(roundParamTo,this.summary.totalProjectRegularHours),
                  style: 'header',
                  alignment: 'right',
                  fontSize: 16,
                  bold: true
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: 'Total Over Time Hours: ' + this.convertDecimalTo(roundParamTo,this.summary.totalProjectOTHours) ,
                  style: 'header',
                  alignment: 'right',
                  fontSize: 16,
                  bold: true
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  text: ' ',
                  style: 'header'
              },
              {
                  layout: 'lightHorizontalLines',
                  table: {
                      headerRows: 1,
                      body: rows
                  }
              }
          ]
      } as TDocumentDefinitions;

      const fileName = new Date().getTime() + '-payroll-report-from-' + moment(this.startDate).format('MM/DD/YYYY')  + '-to-' + moment(this.endDate).format('MM/DD/YYYY') ;
      pdfMake.createPdf(docDefinition).download(fileName);
    }
  }

  exportPDFSummary(roundParamTo: string, type:string) {
      let rows = [];
      let headers = this.getHeaders();
      if (this.showTotalDT) {
          headers.push(this.generateColumn('Total DT Hours', this.headerOptions));
      } else {
          headers.push(this.generateColumn(' '));
      }
      rows.push(headers);

      const projectGroups = this.groupByProject(this.payrollReports, 'projectName');
      for (let projectGroupKey in projectGroups) {
          const projectHeading = [
              this.generateProjectHeader(projectGroupKey),
              this.generateColumn(" "),
              this.generateColumn(" "),
              this.generateColumn(" "),
              this.generateColumn(" "),
              this.generateColumn(" "),
              this.generateColumn(" ")
          ];
          rows.push(projectHeading);
          const groupedList = projectGroups[projectGroupKey];
          groupedList.forEach((d) => {
            const cols = [
                this.generateColumn(d.firstName),
                this.generateColumn(d.lastName),
                this.generateColumn(d.roleName),
                this.generateColumn(this.convertDecimalTo(roundParamTo, d.totalHours)),
                this.generateColumn(this.convertDecimalTo(roundParamTo, d.totalRE)),
                this.generateColumn(this.convertDecimalTo(roundParamTo, d.totalOT))
            ];
            if (this.showTotalDT) {
                cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, d.totalDT)));
            } else {
                cols.push(this.generateColumn(" "));
            }
            rows.push(cols);
          });
    
      if (this.payrollReports && this.payrollReports.length) {
          const docDefinition = {
              pageOrientation: 'portrait',
              content: [
                  {
                      text: this.sessionUser.firm.name,
                      style: 'header',
                      alignment: 'left',
                      fontSize: 22,
                      bold: true
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: 'Payroll Report From: ' + moment(this.startDate).format('MM/DD/YYYY')  + ' To: ' + moment(this.endDate).format('MM/DD/YYYY') ,
                      style: 'header',
                      alignment: 'left',
                      fontSize: 16,
                      bold: true
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: 'Total Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectHours),
                      style: 'header',
                      alignment: 'right',
                      fontSize: 16,
                      bold: true
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: 'Total Regular Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectRegularHours),
                      style: 'header',
                      alignment: 'right',
                      fontSize: 16,
                      bold: true
                  },
                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      text: 'Total Over Time Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectOTHours),
                      style: 'header',
                      alignment: 'right',
                      fontSize: 16,
                      bold: true
                  },

                  {
                      text: ' ',
                      style: 'header'
                  },
                  {
                      layout: 'lightHorizontalLines',
                      table: {
                          headerRows: 1,
                          body: rows
                      }
                  }
              ]
          } as TDocumentDefinitions;

          const fileName = new Date().getTime() + '-payroll-summary-from-' + moment(this.startDate).format('MM/DD/YYYY')  + '-to-' + moment(this.endDate).format('MM/DD/YYYY') ;
          pdfMake.createPdf(docDefinition).download(fileName)

      }
    }
  }

  enumerateDaysBetweenDates(startDate: Date, endDate: Date) {
    let dates = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().format("MM-DD-YYYY"));
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format("MM-DD-YYYY"));
    }
    dates.push(lastDate.clone().format("MM-DD-YYYY"));
    return dates;
  }

  loopOverToMakeCSV(groupedList: any, rows: any, roundParamTo: string, enumerableDates: any) {
    groupedList.forEach((reviewer: any) => {
        const cols = [
            this.generateColumn(reviewer.firstName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.lastName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.roleName, { fillColor: '#bdbdbd' }),
            this.generateColumn(reviewer.projectName, { fillColor: '#bdbdbd' }),

        ];

        enumerableDates.forEach((date: any) => {
            let hours;
            
            if (reviewer.details[date] && Array.isArray(reviewer.details[date]) && reviewer.details[date].length) {
              hours = reviewer.details[date].reduce((sum: number, detail: any) => sum + (Number(detail.totalDurationInHours) || 0), 0);
            }

            cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo,hours), { fillColor: '#bdbdbd' }));
        });

        cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalHours), { fillColor: '#bdbdbd' }));
        cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalRE), { fillColor: '#bdbdbd' }));
        cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalOT), { fillColor: '#bdbdbd' }));
        if (this.showTotalDT) {
            cols.push(this.generateColumn(this.convertDecimalTo(roundParamTo, reviewer.totalDT), { fillColor: '#bdbdbd' }));
        }

        rows.push(cols);

    });
    return rows;

  }

  exportCSV(roundParamTo: string, type:string){
    let rows = [];
    let enumerableDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate);
    const headers = [
        this.generateColumn('First Name', this.headerOptions),
        this.generateColumn('Last Name', this.headerOptions),
        this.generateColumn('Role', this.headerOptions),
        this.generateColumn('Project', this.headerOptions)
    ];

    enumerableDates.forEach((date: any) => {
        headers.push(this.generateColumn(date, this.headerOptions));
    });

    headers.push(this.generateColumn('Total Hours', this.headerOptions));
    headers.push(this.generateColumn('Total Regular Hours', this.headerOptions));
    headers.push(this.generateColumn('Total OT Hours', this.headerOptions));

    if (this.showTotalDT) {
        headers.push(this.generateColumn('Total DT Hours', this.headerOptions));
    }
    rows.push(headers);
    const projectGroups = this.groupByProject(this.payrollReports, 'projectName');
    for (let projectGroupKey in projectGroups) {

        let groupedList = projectGroups[projectGroupKey];

        rows = this.loopOverToMakeCSV(groupedList, rows, roundParamTo, enumerableDates);
    }
    if (this.payrollReports && this.payrollReports.length) {
        const docDefinition = {
            pageOrientation: 'portrait',
            content: [
                {
                    text: this.sessionUser.firm.name,
                    style: 'header',
                    alignment: 'left',
                    fontSize: 22,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Payroll Report From: ' + moment(this.startDate).format('MM/DD/YYYY') + ' To: ' + moment(this.endDate).format('MM/DD/YYYY'),
                    style: 'header',
                    alignment: 'left',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Total Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectHours),
                    style: 'header',
                    alignment: 'right',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Total Regular Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectRegularHours),
                    style: 'header',
                    alignment: 'right',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: 'Total Over Time Hours: ' + this.convertDecimalTo(roundParamTo, this.summary.totalProjectOTHours),
                    style: 'header',
                    alignment: 'right',
                    fontSize: 16,
                    bold: true
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    text: ' ',
                    style: 'header'
                },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        body: rows
                    }
                }
            ]
        };

        const fileName = new Date().getTime() + '-payroll-report-from-' + moment(this.startDate).format('MM/DD/YYYY') + '-to-' + moment(this.endDate).format('MM/DD/YYYY');
        this.createCSV(docDefinition, fileName);
    }
  }

  createCSV(data: { content: any[] }, fileName: string): void {
    const rows: string[] = [];

    data.content.forEach((row) => {
      rows.push((row.text || " ") + "\r\n");
    });

    const lastContent = data.content[data.content.length - 1];
    if (lastContent?.table?.body) {
      lastContent.table.body.forEach((row: any[]) => {
        const rowStr = row
          .map((cell: any) => (typeof cell.text === 'string' ? cell.text : ''))
          .join(",") + "\r\n";

        rows.push(rowStr);
      });
    }

    if (rows.length) {
      const blob = new Blob(rows, { type: 'text/plain;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }

  editTimeSheet(timesheet: any, reviewerInfo: any) {
    this.selectedTimeSheet = timesheet; 
    this.showTimeSheetEditor = true;
    this.selectedRole.name = `${reviewerInfo.projectName}-${reviewerInfo.roleName}`;
    this.selectedRole.value = `${reviewerInfo.projectName}-${reviewerInfo.roleName}`;
    this.role = [this.selectedRole];
    this.reviewerName = reviewerInfo.reviewerName;   
  }

  closeModal(success: any) {
    if (success) {
      this.generateReport();
    }
    this.showTimeSheetEditor = false;
  }

  deleteInvoice($event: Event, data: any) {
    const mesg = `Are you sure you want to delete your time punch from ${moment(data.start).format('MMM DD')} at ${moment(data.start).format('h:mm a')}?`

    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.timeKeepingService.deleteTimekeepingAdmin(data._id).subscribe((resp: any) => {
          this.generateReport();
          this.toastService.showSuccess('Time punch deleted successfully');
        })
      }
    });
  }
}
