import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClientAuthRoleGuard } from './shared/guard/client.role.guard';
import { NonAuthGuard } from './shared/guard/non.auth.guard';
import { ReviewerAuthRoleGuard } from './shared/guard/reviewer.role.guard';
import { ClientModule } from './modules/client/client.module';
import { NgModule } from '@angular/core';
import { ReviewerModule } from './modules/reviewer/reviewer.module';

export const routes: Routes = [
    {
        path: 'client',
        canActivate: [ClientAuthRoleGuard],
        loadChildren: () => ClientModule
    },
    {
        canActivate: [ReviewerAuthRoleGuard],
        path: 'reviewer',
        loadChildren: () => ReviewerModule
    },
    {
        path: 'login',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('../app/modules/auth/login/login.component').then((c)=> c.LoginComponent)
    },
    {
        path: 'rp',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('./modules/reviewer/onboarding/reset-password/reset-pass.component').then((c)=> c.ResetPassComponent)
    },
    {
        path: 'unsubscribe',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('./modules/reviewer/onboarding/unsubscribe/unsubscribe.component').then((c)=> c.UnsubscribeComponent)
    },
    {
        path: 'reviewer-signup/firm/:firmId',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('./modules/reviewer/signup/signup.component').then((c)=> c.ReviewerSignupComponent)
    },
    {
        path: 'onboarding',
        loadComponent:()=> import('./modules/reviewer/onboarding/steps/steps.component').then((m)=> m.StepsComponent)
    },
    {
        path: 'al',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('./modules/auth/auto-login/auto-login.component').then((m)=> m.AutoLoginComponent)
    },
    {
        path: 'onboardingProcess',
        canActivate:[NonAuthGuard],
        loadComponent:()=> import('./modules/client/onboarding-process/onboarding-process.component').then((m)=> m.OnboardingProcessComponent)
    },
    { path: '', redirectTo:'login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
  })
export class AppRoutingModule { }