import { SessionUser, UserClientVm } from "./user.interface";
import { createFeatureSelector, createSelector} from '@ngrx/store';

export const getSessionUser = createSelector(
    createFeatureSelector('sessionUser'),
    (store:SessionUser)=>{
       let vm:UserClientVm = {
        _id: store?._id,
        email: store?.email,
        role: store?.role,
        firm: store?.profile?.firm,
        firstName: store?.firstName,
        lastName: store?.lastName
    };
        return vm;
    }
);

export const getSessionFirm = createSelector(
    createFeatureSelector('sessionUser'),
    (store:SessionUser) => {
        return store.profile.firm;
    }
);

export const getSessionFirmName = createSelector(
    createFeatureSelector('sessionUser'),
    (store:SessionUser)=>{
     
        return store.profile.firm.name;
    }
);

export const getSessionFirmAddress = createSelector(
    createFeatureSelector('sessionUser'),
    (store:SessionUser)=>{
    
        return `${store.profile.firm.address.street},
        ${store.profile.firm.address.city},
        ${store.profile.firm.address.state}`;
    }
);

export const getReviewerTenants = createSelector(
    createFeatureSelector('sessionUser'),
    (user:SessionUser)=>{
    
        return user.tenants;
    }
);
