<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h6 class="m-0">{{ title }}</h6>
  </div>
</div>
<div class="projects-data">
  <div class="grid grid-nogutter">
    <div class="col-12 p-0">
      <p-table
        styleClass="p-datatable-sm p-datatable-sm--normal"
        [value]="dynamicCredential"
        [paginator]="true"
        [rows]="10"
        [tableStyle]="{ 'min-width': '50rem' }"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        autoLayout="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:15%">Sr. No.</th>
            <th pSortableColumn="name" style="width:15%">
              Name <p-sortIcon fiesortMode="multiple" ld="name" />
            </th>
            <th pSortableColumn="description" style="width:15%">
              Description <p-sortIcon sortMode="multiple" field="description" />
            </th>
            <th pSortableColumn="docRequired" style="width:15%">
              Document Required
            </th>
            <th pSortableColumn="renewal" style="width:15%">
              Yearly Renewal
            </th>
            <th pSortableColumn="status" >
              Status
            </th>
            @if(isEditingEnabled){
              <th style="width: 8%">Actions</th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-credential let-rowIndex="rowIndex">
          <tr>
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ credential.name }}</td>
            <td>{{ credential.description }}</td>
            @if(credential.isDocumentationRequired){
             <td style="padding-left: 1rem;">
               <span  class="freshgreen material-symbols-outlined">
                  check
                </span>
            </td> 
            }
            @else {
              <td style="padding-left: 1rem;" >
                <span class="red material-symbols-outlined">
                   close
                 </span>
             </td> 
            }
            <td>{{ credential.isYearlyRenewal }}</td>

            <td>
              @if(!credential.isArchived){
                <p-tag value="Active" [rounded]="true" class="pillActive" />
              }
              @else {
                <p-tag value="Inactive" [rounded]="true" class="pillInactive"  />
              }
            </td>
            @if(isEditingEnabled){
              <td class="pl-0">
                <div class="wrap-actions flex align-items-center gap-2">
                  <p-button
                    pTooltip="Edit" 
                    tooltipPosition="top"
                    [rounded]="true"
                    [text]="true"
                    icon="true"
                    (click)="goToEdit(credential)"
                  >
                    <span class="material-symbols-outlined"> edit </span>
                  </p-button>
                  <p-button
                    [pTooltip]="credential.isArchived? 'Activate': 'Deactivate'"
                    tooltipPosition="top"  
                    (click)="onArchive($event, credential)"
                    [rounded]="true"
                    [text]="true"
                    icon="true"
                  >
                    <span class="material-symbols-outlined"> archive </span>
                  </p-button>
                  <p-button
                    pTooltip="Delete" 
                    tooltipPosition="top"
                    [rounded]="true"
                    [text]="true"
                    icon="true"
                    (click)="onDelete($event, credential)"
                  >
                    <span class="red material-symbols-outlined"> delete </span>
                  </p-button>
                </div>
              </td>
            }
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
