<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h1 class="h4 m-0">Search Markets</h1>
  </div>
  <div
    class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center md:gap-4 gap-0"
  >
  @if(showEditCreate){
    <p-button
      label="CREATE"
      [rounded]="true"
      icon="pi pi-plus"
      size="large"
      (onClick)="showCreateSearchMarketModal($event)"
    />
  }
  </div>
</div>
<div class="projects-data">
  <div class="grid grid-nogutter">
    <div class="col-12 p-0">
      <p-table
        styleClass="p-datatable-sm p-datatable-sm--normal"
        [value]="searchMarkets"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
        autoLayout="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Sr. No.</th>
            <th pSortableColumn="name">
              Name <p-sortIcon fiesortMode="multiple" ld="name" />
            </th>
            <th pSortableColumn="status">
              Status
            </th>
            @if(showEditCreate){
              <th style="width: 8%">Actions</th>
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-searchMarket let-rowIndex="rowIndex">
          <tr>
            <td>{{ rowIndex + 1 }}</td>
            <td>{{ searchMarket.name }}</td>
            <td>
              @if(searchMarket.isActive){
                <p-tag value="Active" [rounded]="true" class="pillActive" />
              }
              @else {
                <p-tag value="Inactive" [rounded]="true" class="pillInactive" />
              }
            </td>
            @if(showEditCreate){
              <td>
                <div class="wrap-actions flex align-items-center gap-2">
                  <p-button
                    pTooltip="Edit" 
                    tooltipPosition="top"
                    [rounded]="true"
                    [text]="true"
                    icon="true"
                    (click)="showEditSearchMarketModal($event, searchMarket)"
                  >
                    <span class="material-symbols-outlined"> edit </span>
                  </p-button>
                  <p-button
                    [pTooltip]="searchMarket.isActive? 'Deactivate': 'Activate'"
                    tooltipPosition="top"
                    (click)="onArchive($event, searchMarket)"
                    [rounded]="true"
                    [text]="true"
                    icon="true"
                  >
                    <span class="material-symbols-outlined"> archive </span>
                  </p-button>
                  <p-button
                  pTooltip="Delete" 
                  tooltipPosition="top"
                  [rounded]="true"
                  [text]="true"
                  icon="true"
                  (click)="onDelete($event, searchMarket)"
                >
                  <span class="red material-symbols-outlined"> delete </span>
                </p-button>
                </div>
              </td>
            }
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
