import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UserService } from '../../../../../shared/services/user.service';
import { TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
 DynamicDialogModule } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { DynamicRoleCriteriaService } from '../../../../../shared/services/dynamic-role-criteria.service';
import { constants } from '../../../../../shared/constants/constants';
import { getSessionFirm } from '../../../../../store/user/user.selector';
import { SessionUser } from '../../../../../store/user/user.interface';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-dynamic-criteria',
  standalone: true,
  imports: [
    CheckboxModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    IconFieldModule,
    InputIconModule,
    HeadingComponent,
    InputTextModule,
    TableModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DynamicTableComponent,
  ],
  templateUrl: './dynamic-criteria.component.html',
  styleUrl: './dynamic-criteria.component.scss',
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicCriteriaComponent implements OnInit {
  dynamicCriteria: any;
  globalDynamicCriteria: any;
  dynamicCriteriaTitle: string = constants.dynamicCriteria.title;

  ref: DynamicDialogRef | undefined;
  user: any;
  firm: any;
  row: any;
  constructor(
    private userService: UserService,
    public dialogService: DialogService,
    private router: Router,
    private store: Store<SessionUser>,
    public config: DynamicDialogConfig,
    public dynamicRoleCriteriaService: DynamicRoleCriteriaService
  ) {}

  ngOnInit() {
    this.user = this.userService.getUserFirmId();
    this.getFirm();
    this.getDynamicCriteria();
  }

  getFirm(){
    this.store.select(getSessionFirm).subscribe((res) => {
      this.firm = res;
    });
  }

  getDynamicCriteria() {
    this.dynamicRoleCriteriaService
      .getFirmDynamicRoleCriteria(this.user)
      .subscribe({
        next: (dynamicCriteria) => {
          this.dynamicCriteria = dynamicCriteria.list;
        },
      });
  }

  handleRefresh(event: any) {
    this.getDynamicCriteria();
  }

  goToCreate($event: Event) {
    this.router.navigateByUrl(
      'client/settings/dynamic-criteria/create'
    );
  }
}
