<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h1 class="h4 m-0">Documents</h1>
    <div class="breadcrumb">
      <p-breadcrumb class="max-w-full" [model]="items" />
    </div>
  </div>
  <div
    class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center md:gap-4 gap-0"
  >
    <p-button
      [routerLink]="'/client/docusign-template'"
      label="VIEW TEMPLATE"
      [rounded]="true"
      size="large"
    />
  </div>
</div>
<div class="grid grid-nogutter">
  <div class="col-12 p-0">
    <div class="grid grid-nogutter">
        <div class="col-12 md:col-6 xl:col-4 lg:col-6 sm:col-12 p-0">
            <div class="wrap-left">
               <div class="grid grid-nogutter">
                    <div class="md:pr-1 sm:pr-0 col-12 md:col-8 sm:col-12 ">
                        <p-iconField iconPosition="right">
                            <p-inputIcon styleClass="pi pi-search" />
                            <input [(ngModel)]="searchText" (ngModelChange)="onSearch()" type="text" pInputText placeholder="Search Keywords" />
                        </p-iconField>
                    </div>
                    <div class="p-0 col-12 md:col-12 sm:col-12">
                        <ul class="chips">
                            @if(searchText){
                                <li><p-chip [label]="searchText" (onRemove)="onRemoveChip('searchText')" [removable]="true" /></li>
                            }
                        </ul>
                    </div>
               </div>
            </div>
        </div>
    </div>
  </div>
  <div class="col-12 p-0">
    <p-table
      styleClass="p-datatable-sm p-datatable-sm--normal"
      [value]="projects"
      dataKey="_id"
      [tableStyle]="{ 'min-width': '60rem' }"
      [expandedRowKeys]="expandedRows"
      (onRowExpand)="onRowExpand($event)"
      (onRowCollapse)="onRowCollapse($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="width: 8%">Action</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        class="border-0"
        let-projects
        let-expanded="expanded"
      >
        <tr>
          <td>
            {{ projects.codeName }}
          </td>
          <td>
            <div class="wrap-actions flex align-items-center gap-2">
              <p-button
                [rounded]="true"
                [text]="true"
                icon="true"
                (onClick)="downloadDocument(projects._id)"
                pTooltip="Download"
                tooltipPosition="top"
              >
                <span class="material-symbols-outlined">download</span>
              </p-button>
              <p-button
                type="button"
                pRipple
                [pRowToggler]="projects"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                class="row-toggler"
              ></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="rowexpansion"
        let-projects
        let-expanded="expanded"
      >
        <tr>
          <td colspan="7" class="p-1 surface-100">
            <div class="inner-wrap surface-100 p-0">
              <p-table
                [value]="projects.roles"
                dataKey="_id"
                [expandedRowKeys]="expandedRoles"
                (onRowExpand)="onRoleExpand($event)"
                (onRowCollapse)="onRoleCollapse($event)"
              >
                <ng-template pTemplate="body" let-roles>
                  <tr class="table-data">
                    <td>{{ roles.roleType }}</td>
                    <td style="width: 8%">
                      <div class="wrap-actions flex align-items-center gap-2">
                        <p-button
                          [rounded]="true"
                          [text]="true"
                          icon="true"
                          (onClick)="downloadDocument(projects._id, roles._id)"
                          pTooltip="Download" 
                          tooltipPosition="top"
                        >
                          <span class="material-symbols-outlined"
                            >download</span
                          >
                        </p-button>
                        <p-button
                          type="button"
                          pRipple
                          [pRowToggler]="roles"
                          [text]="true"
                          [rounded]="true"
                          [plain]="true"
                          [icon]="
                            expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                          "
                          class="row-toggler"
                        ></p-button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="rowexpansion"
                  let-roles
                  let-expanded="expanded"
                >
                  <tr>
                    <td colspan="7" class="p-1 surface-100">
                      <div class="inner-wrap surface-100 p-0">
                        <p-table
                          [value]="roles.reviewers"
                          dataKey="_id"
                          [expandedRowKeys]="expandedReviewers"
                          (onRowExpand)="onReviewerExpand($event)"
                          (onRowCollapse)="onReviewerCollapse($event)"
                        >
                          <ng-template pTemplate="body" let-reviewers>
                            <tr class="table-data">
                              <td>
                                {{ reviewers.firstName }}
                                {{ reviewers.lastName }}
                              </td>
                              <td style="width: 8%">
                                <div
                                  class="wrap-actions flex align-items-center gap-2"
                                >
                                  <p-button
                                    [rounded]="true"
                                    [text]="true"
                                    icon="true"
                                    pTooltip="View Documents" 
                                    tooltipPosition="top"
                                    (click)="openDocumentDetails(projects._id, roles._id, reviewers._id, reviewers.positionId)"
                                  >
                                    <span class="material-symbols-outlined"
                                      >visibility</span
                                    >
                                  </p-button>
                                  <p-button
                                    [rounded]="true"
                                    [text]="true"
                                    icon="true"
                                    pTooltip="Download" 
                                    tooltipPosition="top"
                                    (click)="downloadDocuSignEnvelopeDocuments(projects._id, roles._id, reviewers._id, reviewers.positionId, reviewers.firstName+' '+reviewers.lastName)"
                                  >
                                    <span class="material-symbols-outlined"
                                      >download</span
                                    >
                                  </p-button>
                                </div>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
