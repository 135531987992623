<div class="w-full grid grid-nogutter p-0 h-page-full client-message-wrapper">

    <!-- List Start -->
     @if(!hideProjectsTreeView){
    <div class="col-12 lg:col-5 p-3 surface-50" >
        <div class="col-12 flex justify-content-between border-bottom-1 p-0 pb-2 mb-3 border-primary-200">
            <span class="text-xl font-medium">Message</span>
            <div class="switch-w-label pt-2  flex gap-2">
                <p-inputSwitch [(ngModel)]="isFilterActive" (onChange)="onActiveFilterToggle()"  class="mpl-switch" inputId="stfProject" ariaLabel="reviewers" />
                <label for="stfProject" class="line-height-2">Active Reviewers Only</label>
            </div>
        </div>
        <div class="flex flex-column">
            <p-iconField iconPosition="right">
                <p-inputIcon  styleClass="pi pi-search" />
                <input [(ngModel)]="messageListSearch"  type="text" pInputText placeholder="Search Candidate" />
            </p-iconField>
            <div class="flex flex-column mt-3">
                <p-scrollPanel [style]="{ width: '100%', height: '67vh' }">
                    
                    @if(getUnreadMessages().length){
                        <div class="text-xl font-medium message-read-heading flex">
                            <div>
                                Unread Messages
                            </div>

                            @if(from !== 'staff-tab'){
                                <div class="flex flex-column lg:flex-row justify-content-end gap-3 unread-button-container">
                                    <p-button label="Mark All as read" outlined="true" rounded="true" styleClass="white-space-nowrap"
                                        (onClick)="markAllMessagesRead()"></p-button>
                                </div>
                            }
                        </div>
                        <div class="wrap-tree">
                            <p-tree class="w-full message-tree" [value]="getUnreadMessages()" styleClass="message-layout">
                                <ng-template let-node pTemplate="default">
                                    <div>
                                        
                                        <div class="flex justify-content-between w-full"
                                            [ngClass]="{ 'clickable-node': !node.children }"
                                            >
                                            <div class="left flex align-items-center gap-2 mb-2 cursor-pointer" (click)="!node.children && onNodeClick(node)">
                                                <span class="material-symbols-outlined " [class.avatar-list]="node.node_type ==='REVIEWER' && node.status === 'OCCUPIED'"> {{node.icon}}</span>
                                                <div class="flex flex-column">
                                                    <span class="text-black-alpha-90">{{node.label}}</span>
                                                    @if(node.node_type ==='REVIEWER' && node.status === 'OCCUPIED' && hasAnyMessage && hasAnyMessage.hasOwnProperty(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)){
                                                        <span class="text-black-alpha-60 block truncate-text">{{hasAnyMessage[node.data.projectId+'_'+node.data.roleId+'_'+node.data._id]}}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div class="right flex align-items-center gap-2 mb-2">
                                                
    
                                                @if(node.node_type !=='REVIEWER'){
                                                <span pTooltip="New Message" (click)="openCreateDiscussion(node)" class="material-symbols-outlined text-primary-900 cursor-pointer message-icon-style" >
                                                    add_comment
                                                </span>
                                                } 
                                                @if(node.node_type ==='REVIEWER' && node.status === "OCCUPIED"){
                                                    @if(hasReviewerThreadExist && hasReviewerThreadExist.hasOwnProperty(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)){
                                                        @if(hasReviewerThreadExist[node.data.projectId+'_'+node.data.roleId+'_'+node.data._id]){
                                                            <span pTooltip="View Thread" (click)="!node.children && areDiscussionExists(node)" class=" cursor-pointer material-symbols-outlined text-primary-900 message-icon-style">
                                                                sms
                                                            </span>
                                                        } @else {
                                                            <span pTooltip="New Message" (click)="!node.children && areDiscussionExists(node)" class=" cursor-pointer material-symbols-outlined text-primary-900 message-icon-style">
                                                                add_comment
                                                            </span>
                                                        }
                                                    }
                                                    @if(node.node_type === "REVIEWER" && getUnreadMessageCountByKey(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)) {
                                                        <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)}}"  severity="primary"></p-badge>
                                                    } @else {
                                                        <p-badge class="flex right hidden-icon" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                    }
                                                }
                                                @if(node.node_type !== "REVIEWER" ){
                                                    @if(node.node_type === "PROJECT" && getUnreadMessageCountByKey(node.key) && !node.expanded){
                                                        <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.key)}}"  severity="primary"></p-badge>
                                                    } @else if(node.node_type === "ROLE" && getUnreadMessageCountByKey(node.projectId+'_'+node.key) && !node.expanded){
                                                        <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                    } @else {
                                                        <p-badge class="flex right hidden-icon" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                    }
                                                }
                                            </div>
                                        </div>
                                        @if(node.node_type === 'PROJECT'){
                                            <p-divider [style]="{'margin': 0}"/>
                                        }
                                        
                                    </div>
                                </ng-template>
                            </p-tree>
                        </div>
                        <div class="text-xl font-medium message-read-heading">
                            All Messages
                        </div>
                    }
                    <div class="wrap-tree">
                        <p-tree class="w-full message-tree" [value]="getAllMessages()" styleClass="message-layout">
                            <ng-template let-node pTemplate="default">
                                <div>
                                    
                                    <div class="flex justify-content-between w-full"
                                        [ngClass]="{ 'clickable-node': !node.children }"
                                        >
                                        <div class="left flex align-items-center gap-2 mb-2" (click)="!node.children && onNodeClick(node)">
                                            <span class="material-symbols-outlined cursor-pointer" [class.avatar-list]="node.node_type ==='REVIEWER' && node.status === 'OCCUPIED'"> {{node.icon}}</span>
                                            
                                            @if(node.node_type === "REVIEWER" && getUnreadMessageCountByKey(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)) {
                                                <div class="flex flex-column">
                                                    <span class="text-black-alpha-90">{{node.label}}</span>
                                                    @if(node.node_type ==='REVIEWER' && node.status === 'OCCUPIED' && hasAnyMessage && hasAnyMessage.hasOwnProperty(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)){
                                                        <span class="text-black-alpha-60 block truncate-text">{{hasAnyMessage[node.data.projectId+'_'+node.data.roleId+'_'+node.data._id]}}</span>
                                                    }
                                                </div>
                                            } @else {
                                                <span>{{node.label}} </span>
                                            }
                                        </div>
                                        <div class="right flex align-items-center gap-2 mb-2">
                                            

                                            @if(node.node_type !=='REVIEWER'){
                                            <span pTooltip="New Message" (click)="openCreateDiscussion(node)" class="material-symbols-outlined text-primary-900 cursor-pointer message-icon-style" >
                                                add_comment
                                            </span>
                                            } 
                                            @if(node.node_type ==='REVIEWER' && node.status === "OCCUPIED"){
                                                @if(hasReviewerThreadExist && hasReviewerThreadExist.hasOwnProperty(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)){
                                                    @if(hasReviewerThreadExist && hasReviewerThreadExist[node.data.projectId+'_'+node.data.roleId+'_'+node.data._id]){
                                                        <span pTooltip="View Thread" (click)="!node.children && areDiscussionExists(node)" class=" cursor-pointer material-symbols-outlined text-primary-900 message-icon-style">
                                                            sms
                                                        </span>
                                                        <span class="material-symbols-outlined cursor-pointer text-primary-700 font-bold" style="font-size: 20px; margin-right: 10px;" 
                                                            pTooltip={{getLastMessageTimeStamp(node)}}
                                                            tooltipPosition="top">
                                                            done_all
                                                        </span>
                                                    } @else {
                                                        <span pTooltip="New Message" (click)="!node.children && areDiscussionExists(node)" class=" cursor-pointer material-symbols-outlined text-primary-900 message-icon-style">
                                                            add_comment
                                                        </span>
                                                    }
                                                }
                                                @if(node.node_type === "REVIEWER" && getUnreadMessageCountByKey(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)) {
                                                    <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.data.projectId+'_'+node.data.roleId+'_'+node.data._id)}}"  severity="primary"></p-badge>
                                                } @else {
                                                    <p-badge class="flex right hidden-icon" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                }
                                            }
                                            @if(node.node_type !== "REVIEWER" ){
                                                @if(node.node_type === "PROJECT" && getUnreadMessageCountByKey(node.key) && !node.expanded){
                                                    <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.key)}}"  severity="primary"></p-badge>
                                                } @else if(node.node_type === "ROLE" && getUnreadMessageCountByKey(node.projectId+'_'+node.key) && !node.expanded){
                                                    <p-badge class="flex right" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                } @else {
                                                    <p-badge class="flex right hidden-icon" value="{{getUnreadMessageCountByKey(node.projectId+'_'+node.key)}}"  severity="primary"></p-badge>
                                                }
                                            }
                                        </div>
                                    </div>
                                    @if(node.node_type === 'PROJECT'){
                                        <p-divider [style]="{'margin': 0}"/>
                                    }
                                    
                                </div>
                            </ng-template>
                        </p-tree>
                    </div>
                </p-scrollPanel>
            </div>
        </div>
    </div>}
    <!-- List End -->

    <!-- Default Start -->
 
    <!-- Default End -->

    <!-- Profile Start -->
    <div class="col-12 lg:col bg-white hidden lg:flex flex-column" >
        @if(from !== 'staff-tab'){
            <div class="col-12 flex flex-column lg:flex-row justify-content-end gap-3">
                <p-button label="Message templates" rounded="true" (click)="addNewTemplate($event)" styleClass="white-space-nowrap" size="large"></p-button>
            </div>
        }

        
        <div class="py-2 pl-4 pr-1 surface-50 flex justify-content-between">
            <div class="flex flex-column">
                @if(selectedThreadName) {<span class="text-lg font-bold">{{selectedThreadName}}</span>}
                <span class="text-lg font-medium">{{selectedDiscussionNodeData?.firstName}} {{selectedDiscussionNodeData?.lastName}}</span>
            </div>
            @if (selectedDiscussionNodeData) {
            <div class="flex flex-row">
                @if(this.isFilterActive){
                    <p-button (click)="createNewThread(selectedDiscussionNode)"  label="New Message" rounded="true" styleClass="teal white-space-nowrap"></p-button>
                }
               
            <p-button (click)="openReviewerDialog(selectedDiscussionNodeData._id)" label="View Profile" rounded="true" styleClass="white-space-nowrap" class="ml-3"></p-button>
            </div>
            }
        </div>
        @if(discussionsView){
        <div >
            <p-scrollPanel [style]="{ width: '100%', height: '73vh' }">
              @if( discussions && discussions.length > 0 && !fetchingDiscussions ){ 
                <ul class="list-none m-0 contact-list">
                    @for(discussion of discussions; track discussion){
                    <li class=" cursor-pointer px-4 hover:bg-primary-50 hover:text-primary-900" (click)="getDiscussionThread(discussion)" >
                        <div class="wrap-contact-det flex gap-2 border-bottom-1 surface-border py-3 hover:text-primary-900">
                            <div>
                                <p-avatar icon="pi pi-user" styleClass="mr-2" size="large" [style]="{
                                'background-color': '#EFF3FF80',
                                color: '#2a1261'
                              }" shape="circle" />
                            </div>
                            <div class="flex flex-column gap-2 w-full">
                                <div class="flex flex-column font-bold">
                                    <span class="flex">{{discussion.subject}}</span>
                                    
                                   
                                </div>
                                <div class="flex flex-column text-black-alpha-80">
                                    <span
                                    class="text-sm text-overflow-ellipsis w-22rem white-space-nowrap overflow-hidden">{{discussion.lastMessage}}</span>
                                    
                                </div>
                                <div class="flex flex-column text-black-alpha-80">
                                    <span class="text-xs">{{discussion.createdDate | date:'hh:mm a MMM dd' }}</span>
                                </div>
                                </div>
                                <div class="flex flex-column ">
                                    @if(discussion.pmUnReadMessageCount > 0){
                                        <p-badge class="flex right thread-list-badge" value="{{discussion.pmUnReadMessageCount}}"  severity="primary"></p-badge>
                                    }
                                    <div class="flex flex-column text-black-alpha-80">
                                        <span class="text-xs view-thread">View Thread</span>
                                    </div>
                                
                                </div>
                        </div>
                    </li> }
                </ul>
            }
            
                @if(fetchingDiscussions){
                    <ul class="list-none m-0 contact-list">
                      
                        <li class="  px-4"  >
                            
                                <div class="text-center" >
                                    <p-progressSpinner [style]="{ width: '50px' }" ariaLabel="loading" />
                            
                            </div>
                        </li>
                    </ul>
                }
                @if(!fetchingDiscussions && discussions.length === 0){
                    <ul class="list-none m-0 contact-list">
                      
                        <li class="  px-4"  >
                            
                            <div class="col-12 lg:col" >
                                <!-- <div class="col-12 flex flex-column lg:flex-row justify-content-end gap-3">
                                    <p-button label="New template" rounded="true" styleClass="white-space-nowrap" size="large"></p-button>
                                </div> -->
                                <div class="col-12 bg-white flex align-items-center justify-content-center h-full">
                                    <span class="font-normal text-2xl text-center">No threads to view, <br>
                                        send a new message to view threads</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                }
            </p-scrollPanel>
        </div>
        }

        @if(threadView){
        <div class="flex align-items-center gap-2 py-2 pl-4 border-top-1 surface-border bg-primary-shade-4p" *ngIf="discussionThread" style="justify-content: space-between;">
            <p-button icon="material-symbols-outlined" (click)="goBackToDiscussionView()" label="View All Threads" [rounded]="true" [text]="true" styleClass="text-black-alpha-50">
                <span class="material-symbols-outlined"> arrow_back </span>
            </p-button>
            <!-- <div class="thread-name flex flex-column">
                <span class="text-base font-medium text-primary-900">SUBJECT:{{selectedDiscussion.subject}}</span>
               
            </div> -->
            <div class="thread-name flex flex-column" style="padding-right:10px" >
             
                <span class="text-xs text-primary-900">Last message sent at {{selectedDiscussion.lastMessageDate | date:'hh:mm a dd MMM yyyy'}} </span>
            </div>
            
        </div>

        <div class="px-4" *ngIf="discussionThread">
            <p-scrollPanel #threadDiscussionPanel [style]="{ width: '100%', height: '54vh' }">
                <ul class="list-none m-0">
                    @for(messageKey of objectKeys(discussionThread); track discussionThread[messageKey]){
                    @if(user._id !== discussionThread[messageKey].from){
                        @if(checkIfAnotherFirmUser(discussionThread[messageKey])){
                            <li class="flex gap-2 border-bottom-1 surface-border py-3">
                    
                                <div class="flex flex-column gap-2 w-full">
                                    <div class="flex flex-column text-black-alpha-80 align-items-end" style="text-align: right;">
                                        <div class="flex flex-column font-bold" >
                                            <span class="font-base">{{discussionThread[messageKey].senderName}}</span>
                                        </div>
                                        
                                        <div class="flex flex-row text-black-alpha-80" style="text-align: right; margin-bottom: 5px;">
                                            <span class="text-md" [innerHTML]="discussionThread[messageKey].content | lineBreak"></span>
                    
                                        </div>
                                        <div class="flex flex-row text-black-alpha-80" style="text-align: right;">
                                            <span class="material-symbols-outlined cursor-pointer text-primary-700 font-bold" style="font-size: 20px; margin-right: 10px;" [pTooltip]="getSenderName(discussionThread[messageKey])"
                                                tooltipPosition="top">
                                                location_city
                                            </span>
                                            <span class="material-symbols-outlined cursor-pointer" style="font-size: 20px; margin-right: 10px;" pTooltip={{getReadAt(discussionThread[messageKey])}}
                                                tooltipPosition="left"
                                                [ngClass]="discussionThread[messageKey].isRead ? 'text-primary-700 font-bold' : 'text-gray-400 font-bold'">
                                                done_all
                                            </span>
                                            <span class="text-xs">{{discussionThread[messageKey].msgDate | date:"hh:mm a MMM dd YYYY"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            
                        }@else {
                            <li class="flex gap-2 border-bottom-1 surface-border py-3">
                                <div>
                                    <p-avatar *ngIf="discussionThread[messageKey].sender !== msgSenderReviewer" image="{{firmLogo}}"
                                        styleClass="mr-2" size="large" [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                                    <p-avatar *ngIf="discussionThread[messageKey].sender === msgSenderReviewer" icon="pi pi-user"
                                        styleClass="mr-2" size="large" [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                                </div>
                                <div class="flex flex-column gap-2 w-full">
                                    <div class="flex flex-column font-bold" >
                                        <span class="font-base">{{discussionThread[messageKey].senderName}}</span>
                                        
                                    </div>
                                    <div class="flex flex-column text-black-alpha-80">
                                        <!-- <span class="text-md">{{discussionThread[messageKey].content}}</span> -->
                                        <span class="text-md" [innerHTML]="discussionThread[messageKey].content | lineBreak"></span>
                                        <span class="text-xs">{{discussionThread[messageKey].msgDate | date:"hh:mm a MMM dd YYYY"}}</span>
                                        
                                    </div>
                                </div>
                            </li>
                        }
                    }

                    @if(user._id === discussionThread[messageKey].from){
                    <li class="flex gap-2 border-bottom-1 surface-border py-3">
                    
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column text-black-alpha-80 align-items-end" style="text-align: right;">
                                
                                <div class="flex flex-row text-black-alpha-80" style="text-align: right; margin-bottom: 5px;">
                               
                                    <span class="text-md" [innerHTML]="discussionThread[messageKey].content | lineBreak"></span>
                                <!-- <span class="text-md">{{discussionThread[messageKey].content}}</span> -->
            
                                </div>
                                <div class="flex flex-row text-black-alpha-80" style="text-align: right;">
                                    <span class="material-symbols-outlined cursor-pointer" style="font-size: 20px; margin-right: 10px;" pTooltip={{getReadAt(discussionThread[messageKey])}}
                                    tooltipPosition="left"
                                    [ngClass]="discussionThread[messageKey].isRead ? 'text-primary-700 font-bold' : 'text-gray-400 font-bold'">
                                    done_all
                                    </span>
                                    @if (!discussionThread[messageKey].isDeleted) {
                                        <span pTooltip="Delete" class="material-symbols-outlined text-primary-900 cursor-pointer"  style="font-size: 18px; margin-right: 10px;"   (click)="deleteMessage(discussionThread[messageKey],messageKey)"> delete</span>
                                        <span pTooltip="Edit" class="material-symbols-outlined text-primary-900 cursor-pointer"  style="font-size: 18px; margin-right: 10px;" (click)="editMessage(discussionThread[messageKey],messageKey)" > edit</span>
                                    }
                                    <span class="text-xs">{{discussionThread[messageKey].msgDate | date:"hh:mm a MMM dd YYYY"}}</span>
                               
                                </div>
                            </div>
                        </div>
                    </li>}
                    <!-- <li class="flex gap-2 border-bottom-1 surface-border py-3">
                        <div>
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column font-bold">
                                <span class="font-base">Individual Name</span>
                                <span class="text-xs">12:15 PM NOV 21</span>
                            </div>
                            <div class="flex flex-column text-black-alpha-80">
                                <span class="text-sm">Lorem ipsum Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Ut sed turpis tortor. Vestibulum ante ipsum
                                    primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Vivamus dapibus augue nec erat condimentum, nec
                                    consequat risus aliquet. Integer bibendum pellentesque felis,
                                    in consectetur ex blandit ac. Cras lectus mi, blandit quis
                                    varius quis, aliquam vel dui. Morbi sagittis ex sed porta
                                    egestas. Curabitur vehicula at sem sit amet consectetur. In
                                    consectetur, turpis ut auctor aliquam, turpis velit viverra
                                    lectus, nec porta velit tortor et magna. Nullam enim elit,
                                    ultrices et arcu non, mollis lobortis est.</span>
                            </div>
                        </div>
                    </li>
                    <li class="flex gap-2 border-bottom-1 surface-border py-3">
                        <div>
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column font-bold">
                                <span class="font-base">Individual Name</span>
                                <span class="text-xs">12:15 PM NOV 21</span>
                            </div>
                            <div class="flex flex-column text-black-alpha-80">
                                <span class="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                                    sed turpis tortor</span>
                            </div>
                        </div>
                    </li>
                    <li class="flex gap-2 border-bottom-1 surface-border py-3">
                        <div>
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column font-bold">
                                <span class="font-base">Individual Name</span>
                                <span class="text-xs">12:15 PM NOV 21</span>
                            </div>
                            <div class="flex flex-column text-black-alpha-80">
                                <span class="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                                    sed turpis tortor</span>
                            </div>
                        </div>
                    </li>
                    <li class="flex gap-2 border-bottom-1 surface-border py-3">
                        <div>
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column font-bold">
                                <span class="font-base">Individual Name</span>
                                <span class="text-xs">12:15 PM NOV 21</span>
                            </div>
                            <div class="flex flex-column text-black-alpha-80">
                                <span class="text-sm">Lorem ipsum Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Ut sed turpis tortor. Vestibulum ante ipsum
                                    primis in faucibus orci luctus et ultrices posuere cubilia
                                    curae; Vivamus dapibus augue nec erat condimentum, nec
                                    consequat risus aliquet. Integer bibendum pellentesque felis,
                                    in consectetur ex blandit ac. Cras lectus mi, blandit quis
                                    varius quis, aliquam vel dui. Morbi sagittis ex sed porta
                                    egestas. Curabitur vehicula at sem sit amet consectetur. In
                                    consectetur, turpis ut auctor aliquam, turpis velit viverra
                                    lectus, nec porta velit tortor et magna. Nullam enim elit,
                                    ultrices et arcu non, mollis lobortis est.</span>
                            </div>
                        </div>
                    </li>
                    <li class="flex gap-2 border-bottom-none surface-border py-3">
                        <div>
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2 w-full">
                            <div class="flex flex-column font-bold">
                                <span class="font-base">Individual Name</span>
                                <span class="text-xs">12:15 PM NOV 21</span>
                            </div>
                            <div class="flex flex-column text-black-alpha-80">
                                <span class="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                                    sed turpis tortor</span>
                            </div>
                        </div>
                    </li> -->
                    }
                </ul>
                @if(fetchingThread){ 
                    <ul class="list-none m-0 contact-list">
                      
                        <li class="  px-4"  >
                            
                                <div class="text-center" >
                                    <p-progressSpinner [style]="{ width: '50px' }" ariaLabel="loading" />
                            
                            </div>
                        </li>
                    </ul>
                }
                @if(!fetchingThread && discussionThread?.length === 0){
                    <ul class="list-none m-0">
                        
                        <li class="flex gap-2 border-bottom-none surface-border py-3">
                           <p>No Messages</p>
                        </li>
                    </ul>
                }

            </p-scrollPanel>
        </div>
        
            <div  class="pl-4 pr-4 flex flex-column align-items-end" *ngIf="threadView">
                
                <textarea rows="2" [(ngModel)]="messageObject.messageInput" cols="30" pInputTextarea [autoResize]="true" placeholder="Write Message..." (keydown.enter)="sendMessage()"></textarea>
                <div class="wrap-action flex align-items-center justify-content-between w-full mt-1">
                    <!-- <p-floatLabel>
                        <p-dropdown [options]="dummy" optionLabel="name" inputId="selectRole"
                            placeholder="Test Template Message" styleClass="w-full lg:w-29rem" />
                        <label for="float-label">Add Template</label>
                    </p-floatLabel> -->
                    <p-button label="Send"  rounded="true" styleClass="teal white-space-nowrap" (click)="sendMessage()" [disabled]="!messageObject.messageInput"></p-button>
                </div>
            </div>
        
         }   
    </div>
    <!-- Profile End -->
</div>
