import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component';
import { FirmService } from '../../../../shared/services/firm.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { HelperService } from '../../../../shared/services/helper.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProjectService } from '../../../../shared/services/project.service';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { UserService } from '../../../../shared/services/user.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationDialogService } from '../../../../shared/services/confirmation-dialog.service';
import { TagModule } from 'primeng/tag';
import { AuthService } from '../../../../shared/services/auth.service';
import { TooltipModule } from 'primeng/tooltip';
import { constants } from '../../../../shared/constants/constants';

@Component({
  selector: 'app-firm-users',
  standalone: true,
  imports: [RadioButtonModule,FloatLabelModule, DialogModule,DropdownModule, ButtonModule, BreadcrumbModule, FormsModule, ChipModule, IconFieldModule, InputIconModule, HeadingComponent, InputTextModule, TableModule, CommonModule, ReactiveFormsModule, ConfirmPopupModule, TagModule, TooltipModule],
  templateUrl: './firm-users.component.html',
  styleUrl: './firm-users.component.scss',
  encapsulation: ViewEncapsulation.None,
})

export class FirmUsersComponent implements OnInit {
  visible: boolean = false;
  firmId = '';
  allFirmUsers: Array<any> = [];
  firmUsers: any = [];
  createForm!: FormGroup;
  items: any;
  modalTitle: string = 'Create';
  buttonlabel: string = 'CREATE';
  cancelButtonlabel: string = 'CANCEL';
  isEdit: boolean = false;
  ref: DynamicDialogRef | undefined;
  searchText = '';
  pagination = {
      numPerPage: 10,
      currentPage: 1,
      maxSize: 10,
      offset: function () {
        return (this.currentPage - 1) * this.numPerPage;
      },
      limit: function () {
        return this.numPerPage;
      }
  };
  isActive!: boolean;
  statusButtonLabel!: string;
  constructor(private firmService: FirmService, private projectService: ProjectService, private userService: UserService,
    private confirmationDialogService: ConfirmationDialogService,
    private fb: FormBuilder, private toast: ToastService, private helperService: HelperService, private authService: AuthService) {}

  ngOnInit() {

    const user = this.userService.getSessionUser();

    this.items = [
      { label: 'Firm' }, 
      { label: 'Users' }, 
    ];

    this.createForm = this.fb.group({
      id: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(constants.emailRegex)]],
      phone: [''],
      role: ['', [Validators.required]]
    });

    this.firmId = user.firm._id;

    this.getFirmUsers();
  }

  getFirmUsers() {
    this.firmService.getListOfFirmUsers(this.firmId, this.pagination.offset(), this.pagination.limit()).subscribe({
      next: (res) => {
        console.log(res);
        this.allFirmUsers = res.users;
        if (!this.allFirmUsers.length) {
          this.toast.showSuccess('No Firm Users found');
        } else {
          this.firmUsers = this.allFirmUsers.slice();
        }
      }
    })
  }

  showCreateFirmUserModal() {
    this.createForm.reset();
    this.modalTitle = this.buttonlabel = 'CREATE';
    this.showDialog();
  }

  editFirmUser(firmUser: any) {

    this.createForm.controls['id'].setValue(firmUser._id);
    this.createForm.controls['firstName'].setValue(firmUser.firstName);
    this.createForm.controls['lastName'].setValue(firmUser.lastName);
    this.createForm.controls['email'].setValue(firmUser.email);
    this.createForm.controls['phone'].setValue(firmUser.profile.contact.mobile);
    this.createForm.controls['role'].setValue(firmUser.role);
    this.isActive = firmUser.isActive;
    this.statusButtonLabel =  this.isActive? 'Deactivate': 'Activate'
    this.modalTitle = `Edit ${this.helperService.getRole(firmUser.role)}`;
    this.buttonlabel = 'UPDATE';
    this.isEdit = true;
    this.showDialog();
  }

  onSubmit() {
    if (this.createForm.valid) {

      let firmUser = this.createForm.value;
      const {id, firstName, lastName, phone, role} = firmUser;
      const user = this.firmUsers.find((e: any) => e._id == id);

      if(user) {
        user.firstName = firstName;
        user.lastName = lastName;
        user.role = role;
        firmUser = user;
      }

      firmUser.profile = {
        contact: {
          mobile: phone.replace(/-/g, '')
        }
      };

      // update
      if(user) {
        this.updateFMUser(firmUser, role);
      } // create
      else{
        this.createFMUser(firmUser, role);
      }
    }
  }

  createFMUser(user: any, role: string){
    this.firmService.createFirmUser(this.firmId, user).subscribe({
      next: (res) => {
        this.hideDialog();
        this.toast.showSuccess(this.helperService.getRole(role) + ' created successfully.');
        this.getFirmUsers();
      }
    })
  }

  updateFMUser(user: any, role: string){
    this.firmService.updateFirmUser(user).subscribe({
      next: (res) => {
        this.hideDialog();
        this.toast.showSuccess(this.helperService.getRole(role) + ' updated successfully.');
        this.getFirmUsers();
      }
    })
  }

  resendReinviteEmail($event:Event, user: any) {
    $event.stopPropagation();
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: 'You are about to send re-invite email to the '+user.firstName + ' ' + user.lastName+'. Are you sure?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.firmService.resendConfirmationEmailToFirmManager(this.firmId, user._id).subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.toast.showSuccess('Re-invite email has been send');
            }
          }
        });
      }
    });
  }

  deleteFirmUser($event:Event, user: any) {
    this.projectService.getProjectsByOwner(user._id).subscribe({
      next: (res) => {
        if(res.data.count > 0){
          this.toast.showError("He has assigned a Project as Owner, can't delete");
          return;
        }
        $event.stopPropagation();
        this.confirmationDialogService.confirm({
          target: $event.target as EventTarget,
          message: 'You are about to delete the '+user.firstName + ' ' + user.lastName+'. Are you sure?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon:"none",
          rejectIcon:"none",
          rejectButtonStyleClass:"p-button-text",
          accept: () => {
            
          }
        });
      }
    });
  }

  onSearch(): void{
    this.firmUsers.length = 0;
    if (this.searchText) {
      this.firmUsers = this.allFirmUsers.filter(user => {
        const searchText = this.searchText.toLowerCase();
        return (
          user.firstName.toLowerCase().includes(searchText) ||
          user.lastName.toLowerCase().includes(searchText) ||
          user.email.toLowerCase().includes(searchText)
        )
      });

    } else {
      this.firmUsers = this.allFirmUsers.slice();
    }
  }

  onRemoveChip(type: string) {
    this.searchText = '';
    this.firmUsers = this.allFirmUsers.slice();
  }

  showDialog() {
    this.visible = true;
  }

  hideDialog(){
    this.visible = false;
  }

  getRole(str: string){
    return this.helperService.getRole(str);
  }

  onStatusChange(): void{
    this.firmService.updateUserStatus(this.createForm.get('id')?.value, !this.isActive).subscribe({
      next: (res) => {
        const msg = this.isActive? 'deactivated' : 'activated';
        const role =  this.getRole(this.createForm.get('role')?.value);
        this.toast.showSuccess(`${role} ${msg} successfully`);
        this.hideDialog();
        this.getFirmUsers();
      }
    })
  }

  resetPassword(email: string): void{
    const payload = {email: email};
      this.authService.forgotPassword(payload).subscribe({
        next: (res) => {
          this.toast.showSuccess("An email has been sent to you to reset your password.")
        }
      });
  }

  formatPhone(event: any){
   this.helperService.formatPhone(event); 
  }
}